import blueTray from '@assets/images/icons/blueTray.svg';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import { TalkToAdvisorRequestResponse } from '@interfaces/index';
import { Skeleton } from '@mui/material';
import { formatDateToDayMonthYear } from '@utils/date.util';
import React from 'react';
import styles from './index.module.scss';

const TalkToAdvisorRequestSection: React.FunctionComponent<SectionProps> = (props) => {
  const { loading, data } = props;

  return (
    <Card className={styles.mainContainer}>
      <h4 className={styles.cardTitle}>Talk to advisor requests</h4>

      {data?.length === 0 && !loading && (
        <div className={styles.noDataSection}>
          <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
          <p>No advisor requests found.</p>
        </div>
      )}

      {loading ? (
        <div className={styles.loadingContainer}>
          <Skeleton variant="rounded" height={140} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={140} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={140} width="100%" className={styles.loadingItem} />
        </div>
      ) : (
        <div className={styles.requestContainer}>
          {data.map((item: TalkToAdvisorRequestResponse, index: number) => (
            <div key={index} className={styles.requestItem}>
              <div className={styles.requestItemHeader}>
                {item.createdAt && (
                  <div className={styles.date}>
                    <p>{formatDateToDayMonthYear(item.createdAt)}</p>
                  </div>
                )}
                <p className={styles.name}>{item.questionnaire}</p>
              </div>

              <div className={styles.requestItemBody}>
                {item.questions.map((question, i: number) => (
                  <div className={styles.item} key={i}>
                    <div className={styles.circle} style={{ borderColor: question?.color }}>
                      {question?.score || 0}
                    </div>
                    <span className={styles.text}>{question?.question}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

TalkToAdvisorRequestSection.defaultProps = {
  loading: false,
};

interface SectionProps {
  loading?: boolean;
  data: TalkToAdvisorRequestResponse[] | [];
}

export default TalkToAdvisorRequestSection;
