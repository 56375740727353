import { getIpAddress } from '@api/auth/login';
import {
  expertsPageLog,
  getAllExperts,
  getExpertBookingHistoryRequestDetails,
  getExpertsTopics,
} from '@api/experts';
import DefaultButton from '@components/Atoms/DefaultButton';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import {
  ExpertBookingRequestDetailsInterface,
  ExpertTopicInterface,
  ExpertsResultsResponse,
} from '@interfaces/index';
import { Autocomplete, Grid, Skeleton, TextField } from '@mui/material';
import { getHourBufferCategory } from '@utils/date.util';
import { CustomAutocompletePopper, CustomPopper, FLIP_POPPER_MODS } from '@utils/mui.util';
import {
  AvailabilityTimeNames,
  EXPERTS_TIME_FILTERS,
  ExpertLogTypes,
  USER_NOT_FOUND_IMAGE,
  castResponse,
} from 'custom.d';
import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import BookingExpertCard from '../BookingExpertCard';
import RequestedUserInfo from '../RequestedUserInfo';
import styles from './index.module.scss';

const MuiStyles = {
  autocomplete: {
    width: '100%',
    minWidth: '280px',
    marginTop: '-4px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '40px',
      height: '42px',
      border: '3px solid #019df2',
      '& .MuiInputBase-input': {
        color: '#019df2',
        fontWeight: 600,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#019df2',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#019df2',
      },
      '& .MuiAutocomplete-popupIndicator': {
        color: '#019df2',
      },
    },
    '@media (max-width: 600px)': {
      width: '100%',
      minWidth: '200px',
    },
  },
};

const BookingAllExperts: FunctionComponent<BookingAllExpertsProps> = (props) => {
  const { requestId } = props;
  const { isDesktop, isTablet } = useWindowDimensions();
  const [experts, setExperts] = useState<ExpertsResultsResponse>({ results: [], total: 0 });
  const [expertsLoading, setExpertsLoading] = useState(false);
  const [expertPage, setExpertPage] = useState<number>(0);
  const [expertPerPage] = useState<number>(40);
  const isMobileApp = useGoNative();
  const [starExpertTopicsDataLoading, setExpertTopicsDataLoading] = useState(true);
  const [expertTopics, setExpertTopics] = useState<{ topicId: number; topic: string }[] | []>([
    { topicId: -999, topic: 'Expertise' },
  ]);
  const [selectedExpertTopic, setSelectedExpertTopic] = useState<{
    topicId: number;
    topic: string;
  }>({ topicId: -999, topic: 'Expertise' });

  const [availabilityTimes, setAvailabilityTimes] = useState<any>(EXPERTS_TIME_FILTERS);
  const [selectedAvailabilityTime, setSelectedAvailabilityTime] = useState<{
    id: number;
    name: string;
    value: {
      start: string;
      end: string;
    } | null;
  }>({
    id: -9999,
    name: AvailabilityTimeNames.AVAILABILITY,
    value: null,
  });
  const [historyRequestDetails, setHistoryRequestDetails] =
    useState<ExpertBookingRequestDetailsInterface | null>(null);
  const [historyRequestDetailsLoading, setHistoryRequestDetailsLoading] = useState<boolean>(false);

  useEffect(() => {
    getStarQuestions();
    logExperts();
  }, []);

  useEffect(() => {
    getExperts();
  }, [
    selectedExpertTopic,
    expertPage,
    selectedAvailabilityTime,
    historyRequestDetails?.userTimezone,
  ]);

  useEffect(() => {
    if (requestId) {
      getHistoryRequestInfo();
    }
  }, [requestId]);

  const getHistoryRequestInfo = async () => {
    try {
      setHistoryRequestDetailsLoading(true);
      const response = await getExpertBookingHistoryRequestDetails(requestId);
      if (response.success) {
        const data = castResponse<ExpertBookingRequestDetailsInterface>(response);
        setHistoryRequestDetails(data);
      } else {
        setHistoryRequestDetails(null);
      }
    } catch {
      setHistoryRequestDetails(null);
    } finally {
      setHistoryRequestDetailsLoading(false);
    }
  };

  const getStarQuestions = async () => {
    try {
      setExpertTopicsDataLoading(true);
      const response = await getExpertsTopics();
      if (response?.success) {
        const data: ExpertTopicInterface[] = response?.responseObject || [];
        const updatedResponse = data.map((dt) => ({
          topicId: dt.id,
          topic: dt.topic,
        }));
        updatedResponse.unshift({ topicId: -999, topic: 'Expertise' });
        setExpertTopics(updatedResponse);
      } else {
        setExpertTopics([]);
      }
    } catch (error) {
      setExpertTopics([]);
    } finally {
      setExpertTopicsDataLoading(false);
    }
  };

  const getExperts = async () => {
    try {
      setExpertsLoading(true);

      const response = await getAllExperts(
        selectedExpertTopic.topicId === -999 ? null : selectedExpertTopic.topicId,
        null,
        expertPage,
        expertPerPage,
        selectedAvailabilityTime.value,
      );

      if (response?.success) {
        const data = castResponse<ExpertsResultsResponse>(response);
        const userTimezone = historyRequestDetails?.userTimezone
          ? historyRequestDetails?.userTimezone
          : Intl.DateTimeFormat().resolvedOptions().timeZone;
        const userTimeZoneTime = moment.tz(userTimezone).format('YYYY-MM-DD HH:mm:ss');

        const sortedExperts = (data?.results || [])
          .map((expert) => {
            const zoneName = expert?.expertDetail?.zoneName;
            const expertTimeZoneTime = moment.tz(zoneName).format('YYYY-MM-DD HH:mm:ss');

            const differenceInMinutes = moment(expertTimeZoneTime).diff(
              moment(userTimeZoneTime),
              'minutes',
            );

            return {
              ...expert,
              hourBufferCategory: getHourBufferCategory(differenceInMinutes),
            };
          })
          .sort((a, b) => {
            if (a.hourBufferCategory !== b.hourBufferCategory) {
              return a.hourBufferCategory - b.hourBufferCategory;
            }

            return b.orderId - a.orderId;
          });

        if (expertPage > 0) {
          setExperts((prevExperts) => ({
            results: [...prevExperts.results, ...sortedExperts],
            total: data?.total,
          }));
        } else {
          setExperts({ results: sortedExperts, total: data?.total });
        }
      } else {
        setExperts({ results: [], total: 0 });
      }
    } catch {
      setExperts({ results: [], total: 0 });
    } finally {
      setExpertsLoading(false);
    }
  };

  const logExperts = async () => {
    const ip = await getIpAddress();
    const req = await expertsPageLog(
      ExpertLogTypes.EXPERTS_PAGE_VISITED,
      ip || null,
      navigator.userAgentData?.platform || '',
      isMobileApp,
    );
  };

  const handleNextPage = () => {
    if (experts.results.length < experts.total) {
      setExpertPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (expertPage > 0) {
      setExpertPage((prev) => prev - 1);
    }
  };

  const handleChange = (event: any, newValue: any) => {
    setSelectedExpertTopic(newValue || { id: -999, name: 'Expertise' });
    setExpertTopicsDataLoading(false);
  };

  const handleChangeAvailability = (event: any, newValue: any) => {
    if (newValue.value) {
      const userTimezone = moment.tz.guess();

      const startUtc = moment
        .tz(newValue.value.start, 'HH:mm:ss', userTimezone)
        .utc()
        .format('HH:mm:ss');

      const endUtc = moment
        .tz(newValue.value.end, 'HH:mm:ss', userTimezone)
        .utc()
        .format('HH:mm:ss');

      const convertedAvailabilityTime = {
        ...newValue,
        value: {
          start: startUtc,
          end: endUtc,
        },
      };
      setSelectedAvailabilityTime(convertedAvailabilityTime);
    } else {
      setSelectedAvailabilityTime({
        id: -999,
        name: AvailabilityTimeNames.AVAILABILITY,
        value: null,
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleSection}>
        <h1>Book an Expert</h1>
      </div>
      <RequestedUserInfo requestDetails={historyRequestDetails} />

      <div className={styles.expertsContainer}>
        <div className={styles.filters}>
          <div className={styles.filterBox}>
            <p>Filter by</p>

            <div>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.topicId === value.topicId}
                loading={starExpertTopicsDataLoading}
                includeInputInList
                filterSelectedOptions
                autoHighlight
                onOpen={() => {
                  setExpertTopicsDataLoading(true);
                  setTimeout(() => {
                    setExpertTopicsDataLoading(false);
                  }, 1000);
                }}
                disableClearable
                options={expertTopics}
                getOptionLabel={(option) => option.topic}
                renderInput={(params) => (
                  <TextField {...params} label="" sx={MuiStyles.autocomplete} />
                )}
                value={selectedExpertTopic}
                onChange={handleChange}
                sx={MuiStyles.autocomplete}
                className={styles.autoComplete}
                disablePortal
                PopperComponent={isDesktop ? CustomPopper : CustomAutocompletePopper}
                componentsProps={FLIP_POPPER_MODS}
              />
            </div>

            <div>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.value === value.value}
                includeInputInList
                filterSelectedOptions
                autoHighlight
                disableClearable
                options={EXPERTS_TIME_FILTERS}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    sx={MuiStyles.autocomplete}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                      readOnly: true,
                    }}
                  />
                )}
                value={selectedAvailabilityTime}
                onChange={handleChangeAvailability}
                sx={MuiStyles.autocomplete}
                className={styles.autoComplete2}
                disablePortal
                PopperComponent={isDesktop ? CustomPopper : CustomAutocompletePopper}
                componentsProps={FLIP_POPPER_MODS}
              />
            </div>
          </div>
        </div>

        {expertsLoading ? (
          <Grid container spacing={4} className={styles.expertGrid}>
            {Array.from({ length: isDesktop ? 12 : isTablet ? 2 : 1 }).map((_, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={300}
                  key={index}
                  className={styles.itemLoading}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={4} className={styles.expertGrid}>
            {historyRequestDetails &&
              experts.results.map((data, index) => (
                <Grid item xs={12} md={3} key={index}>
                  <BookingExpertCard
                    id={data.id}
                    image={
                      data?.expertDetail?.profileImage
                        ? data.expertDetail.profileImage
                        : USER_NOT_FOUND_IMAGE
                    }
                    name={`${data.firstName} ${data.lastName}`}
                    description={data?.expertDetail?.headline || ''}
                    experience={`${data?.expertDetail?.experience || 0} years experience`}
                    requestDetails={historyRequestDetails}
                  />
                </Grid>
              ))}
          </Grid>
        )}

        {experts.total === 0 && !expertsLoading && (
          <div className={styles.noExperts}>
            <p>
              No experts found with these filters. Try adjusting availability or specialty filters
              to see more options.
            </p>
            <span
              onClick={() => {
                setSelectedExpertTopic({ topicId: -999, topic: 'Expertise' });
                setSelectedAvailabilityTime({
                  id: -9999,
                  name: AvailabilityTimeNames.AVAILABILITY,
                  value: null,
                });
              }}
            >
              clear filters
            </span>
          </div>
        )}

        {experts?.results?.length < experts?.total && (
          <div className={styles.pagination}>
            <DefaultButton
              variant="darken"
              disabled={experts.results.length < expertPerPage}
              onClick={handleNextPage}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Load more&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </DefaultButton>
          </div>
        )}
      </div>
    </div>
  );
};

interface BookingAllExpertsProps {
  requestId: number;
}

export default BookingAllExperts;
