import { getUserAiInsights } from '@api/participantReporting';
import blueTray from '@assets/images/icons/blueTray.svg';
import HelpIcon from '@assets/images/icons/help-circle.svg';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import PopUpModal from '@components/PopUpModal';
import { smoothScrollTo } from '@helpers/general.helpers';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { AiInsightsInterface } from '@interfaces/index';
import { Skeleton, Tooltip } from '@mui/material';
import { ADVISOR_RECOMMENDATIONS_INFO, InitInfoDialog, castResponse } from 'custom.d';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const AdvisorRecommendationsSection: React.FunctionComponent<SectionProps> = (props) => {
  const { participantId } = props;
  const { isDesktop } = useWindowDimensions();
  const [insights, setInsights] = useState<AiInsightsInterface | null>(null);
  const [insightsLoading, setInsightsLoading] = useState<boolean>(true);
  const [insightsError, setInsightsError] = useState<string>('');
  const [infoDialog, setInfoDialog] = useState<{
    open: boolean;
    top: string;
    right: string;
    firstDescription: string;
    description: string;
    title: string;
    htmlDescription: any;
  }>(InitInfoDialog);

  useEffect(() => {
    if (participantId && participantId !== 0) {
      fetchUserAiInsights();
    } else {
      setInsightsLoading(false);
    }
  }, [participantId]);

  const fetchUserAiInsights = async () => {
    try {
      setInsightsLoading(true);
      const response = await getUserAiInsights(participantId);
      if (response.success) {
        const data = castResponse<AiInsightsInterface>(response);
        setInsights(data);
      } else {
        setInsights(null);
        setInsightsError(
          'The user has not taken a deep dive or completed check-ins within the last 90 days.',
        );
      }
    } catch {
      setInsights(null);
      setInsightsError(
        'The user has not taken a deep dive or completed check-ins within the last 90 days.',
      );
    } finally {
      setInsightsLoading(false);
    }
  };

  return (
    <Card className={styles.recommendationsCard}>
      <div className={styles.topHeader}>
        <h4 className={styles.cardTitle}>Advisor Recommendations</h4>
        <Tooltip
          title="Advisor Recommendations - Click for more details"
          followCursor
          onClick={() => {
            if (!isDesktop) {
              smoothScrollTo(0, 0);
            }

            setInfoDialog({
              open: true,
              top: '500px',
              right: '35%',
              firstDescription: '',
              description: '',
              htmlDescription: ADVISOR_RECOMMENDATIONS_INFO,
              title: 'Advisor Recommendations',
            });
          }}
        >
          <img src={HelpIcon} alt="help" className={styles.helpIcon} />
        </Tooltip>
      </div>
      {insightsLoading ? (
        <div className={styles.skeletonContainer}>
          <Skeleton variant="text" width="70%" height={30} />
          <Skeleton variant="text" width="90%" height={20} />
          <Skeleton variant="text" width="60%" height={20} />
          <Skeleton variant="rectangular" width="100%" height={150} />
        </div>
      ) : insights ? (
        <div className={styles.insightsContent}>
          <p className={styles.summary}>{insights.summary}</p>
          <ul className={styles.highlights}>
            {insights.highlights.map((highlight, index) => (
              <li key={index}>{highlight}</li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={styles.noDataSection}>
          <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
          <p>
            {insightsError && insightsError?.length > 0 ? insightsError : 'No insights available.'}
          </p>
        </div>
      )}
      <PopUpModal
        open={infoDialog.open}
        setOpen={() => setInfoDialog(InitInfoDialog)}
        top={infoDialog.top}
        right={infoDialog.right}
        firstDescription={infoDialog.firstDescription}
        description={infoDialog.description}
        title={infoDialog.title}
        overlay
        htmlDescription={infoDialog.htmlDescription}
      />
    </Card>
  );
};

AdvisorRecommendationsSection.defaultProps = {};

interface SectionProps {
  participantId: number;
}

export default AdvisorRecommendationsSection;
