import HsStar from '@assets/images/icons/home-active.svg';
import DissapointedFace from '@assets/images/starSteps/disappointed-face-em.svg';
import FrowningFace from '@assets/images/starSteps/frowning-face-em.svg';
import GrinningFace from '@assets/images/starSteps/grinning-face-em.svg';
import NeutralFace from '@assets/images/starSteps/neutral-face-em.svg';
import SlightlyFace from '@assets/images/starSteps/slightly-smiling-face-em.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { sanitizeHtml } from '@utils/string.util';
import { ShortStarQuestionsInterface, ShortStatContentTypes } from 'custom.d';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { RootState } from 'store';
import { shortStarWizardActions } from 'store/Slices/ShortStarWizardSlice';
import styles from './index.module.scss';

const FeelsButtons = [
  { key: 'DissapointedFace', path: DissapointedFace, score: 2 },
  { key: 'FrowningFace', path: FrowningFace, score: 4 },
  { key: 'NeutralFace', path: NeutralFace, score: 6 },
  { key: 'SlightlyFace', path: SlightlyFace, score: 8 },
  { key: 'GrinningFace', path: GrinningFace, score: 10 },
];
const GeneralHappinessQuestion = ({
  handleStep,
  data,
  isFirstShortStarCompleted,
  closeModal,
}: GeneralHappinessQuestionProps) => {
  const dispatch = useAppDispatch();
  const hsConfigurations = useAppSelector((state: RootState) => state.configurations);
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);
  const [hideWelcome, setHideWelcome] = useState<boolean>(false);

  return (
    <div>
      {hsConfigurations.daysSinceAssessmentCreated === null &&
      companyConfig.welcomeMessageText &&
      !hideWelcome ? (
        <div className={styles.welcomeMessage}>
          <div className={styles.starForceSection}>
            <div>
              <Img src={HsStar} alt="HS star" className={styles.hsStarImage} />
              <h3>Welcome to Hapstar</h3>
            </div>
          </div>
          <p
            className={styles.welcomeText}
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(companyConfig.welcomeMessageText) }}
          />

          <div className={styles.btnContainer}>
            <DefaultButton className={styles.continuePrimary} onClick={() => setHideWelcome(true)}>
              Continue
            </DefaultButton>
          </div>
        </div>
      ) : (
        <div className={styles.step1Container}>
          <div className={styles.titleSection}>
            <h3>How are you feeling today?</h3>
          </div>
          <div className={styles.feelsSelectionSection}>
            {FeelsButtons.map((btn, index) => (
              <div
                key={index}
                className={styles.imgBtn}
                onClick={() => {
                  dispatch(
                    shortStarWizardActions.storeGeneralHappinessQuestion({
                      questionId: data?.questionId,
                      imagePath: btn.path,
                      score: btn.score,
                      questionText: data?.questionText,
                    }),
                  );
                  handleStep('QUESTIONS_SECTION');
                }}
              >
                <Img src={btn.path} alt={btn.key} />
              </div>
            ))}
          </div>

          {!hsConfigurations.shortStarModalForce && (
            <div className={styles.bottomCloseBtnCont}>
              <DefaultButton
                className={styles.hsSecondary}
                variant="secondary"
                onClick={closeModal}
              >
                NOT FOR NOW, TAKE ME TO PLATFORM
              </DefaultButton>
            </div>
          )}

          {/* <div className={styles.sidesTextContainer}>
        <div className={styles.textLeft}>Very Unhappy</div>
        <div className={styles.textRight}>Very Happy</div>
      </div> */}
        </div>
      )}
    </div>
  );
};

GeneralHappinessQuestion.defaultProps = {};

interface GeneralHappinessQuestionProps {
  handleStep: Dispatch<SetStateAction<ShortStatContentTypes>>;
  data: ShortStarQuestionsInterface | undefined | null;
  isFirstShortStarCompleted: boolean | null;
  closeModal: () => void;
}

export default GeneralHappinessQuestion;
