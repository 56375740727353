import { getIpAddress } from '@api/auth/login';
import { logInstantHelpStarDetails, shareSpeakupThoughts } from '@api/instantHelp';
import Checkbox from '@components/Atoms/Checkbox';
import DefaultButton from '@components/Atoms/DefaultButton';
import Modal from '@components/Atoms/Modal';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import { isEmptyValidation, noValidation } from '@helpers/validation.helper';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useGoNative from '@hooks/useGoNative';
import useInput, { getCheckedValue, getInputValue } from '@hooks/useInput';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { LogTypes } from 'custom.d';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { RootState } from 'store';
import { getInstantHelpData } from 'store/Actions/InstantHelpAction';
import InstantHelp from '../InstantHelp';
import styles from './index.module.scss';

const HelpSpeakup: React.FunctionComponent<HelpSpeakupProps> = ({
  speakUpVisible,
  assesmentDataForHelp,
  handleAssessmentData,
  previewInstantHelp,
  handleInstantPreview,
  skipStarStep,
  handleSkipButton,
  handleClose,
  isShortStarModal,
}) => {
  const isMobileApp = useGoNative();
  const {
    value: descriptionValue,
    isValid: descriptionIsValid,
    hasError: descriptionHasError,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: descriptionReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const {
    value: shareIdentityValue = false,
    isValid: shareIdentityIsValid,
    hasError: shareIdentityHasError,
    valueChangeHandler: shareIdentityChangeHandler,
    inputBlurHandler: shareIdentityBlurHandler,
    reset: shareIdentityReset,
  } = useInput(noValidation, (value) => value, false);

  const checkboxChangeHandler = (event: any) => {
    const newValue = getCheckedValue(event);
    shareIdentityChangeHandler(newValue);
  };

  const [submitted, setSubmitted] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const instantHelpData = useAppSelector((state: RootState) => state.instantHelp);
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);
  const { isDesktop } = useWindowDimensions();
  const [isSharingThought, setIsSharingThought] = useState<boolean>(false);

  useEffect(() => {
    handleSkipButton('Skip to Set Actions');
    async function getInstantData() {
      await dispatch(getInstantHelpData());
    }

    if (!instantHelpData.isLoaded) {
      getInstantData();
    }
  }, [dispatch, instantHelpData.isLoaded]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (descriptionIsValid && assesmentDataForHelp.assessmentId > 0) {
      try {
        setIsSharingThought(true);
        const response = await shareSpeakupThoughts(
          assesmentDataForHelp.assessmentId,
          descriptionValue,
          shareIdentityValue,
        );

        if (response) {
          descriptionReset();
          shareIdentityReset();
          toast.success('Your message has been successfully shared');
          handleSkipButton('Continue to Set Actions');
          setSubmitted(true);
        } else {
          toast.error('An unexpected error occurred.');
        }
      } catch (error) {
        if (error instanceof Error) {
          toast.error(`Error: ${error.message}` || 'An unexpected error occurred.');
        } else {
          toast.error('An unexpected error occurred.');
        }

        setIsSharingThought(false);
      } finally {
        setIsSharingThought(false);
      }
    }
  };

  const logStarInstantHelp = async () => {
    handleInstantPreview(true);
    const payload = {
      logType: LogTypes.INSTANT_HELP_CLICKED,
      ipAddress: await getIpAddress(),
      isNative: isMobileApp,
      os: navigator.userAgentData?.platform || '',
    };

    await logInstantHelpStarDetails(
      payload.logType,
      payload.ipAddress,
      payload.os,
      payload.isNative,
    );
  };

  return (
    <div className={!isShortStarModal ? 'container' : ''}>
      {previewInstantHelp && (
        <Modal
          open={previewInstantHelp}
          setOpen={handleInstantPreview}
          onTapBackgroundClose
          className={styles.modalRoot}
          overlay
        >
          <DefaultButton
            color="primary"
            type="button"
            className={styles.btnClose}
            onClick={() => handleInstantPreview(false)}
          >
            <div className={styles.btnCloseLabel}>Close</div>
            <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
          </DefaultButton>
          {instantHelpData.error && <p>Unable to fetch wellbeing data</p>}

          {instantHelpData.data && instantHelpData.data.length > 0 ? (
            instantHelpData.loading ? (
              <LoadingPage />
            ) : (
              <InstantHelp
                title="Wellbeing Hub"
                subText={companyConfigData?.data?.instantHelpDescription || ''}
                instantData={instantHelpData.data}
                loading={instantHelpData.loading}
              />
            )
          ) : (
            <div className={styles.noDataContainer}>
              <h3>No data found</h3>
            </div>
          )}

          <div className={styles.goBackButton}>
            <DefaultButton
              color="secondary"
              type="button"
              variant="secondary"
              onClick={() => handleInstantPreview(false)}
            >
              Go Back
            </DefaultButton>
          </div>
        </Modal>
      )}
      <ToastContainer hideProgressBar theme="dark" />
      <div className={styles.helpAndSpeakupContainer}>
        {!isShortStarModal && (
          <div className={styles.topContainer}>
            <div className={styles.hsTitle}>Help</div>
          </div>
        )}

        <div className={styles.hsDescription}>
          We saw that you scored low on one or more drivers. We all struggle sometimes, but we want
          you to know you’re not alone. There’s help available if you need it. You can send an
          anonymous message to your organization, or check out our immediate help resources. Just
          click below before continuing your star.
        </div>
        {speakUpVisible ? (
          <>
            <div className={styles.hsBoxContainer}>
              <div className={styles.speakUpBox}>
                {!submitted ? (
                  <>
                    <div className={styles.speakUpBoxTopContainer}>
                      <div className={styles.titleContainer}>
                        <span className={styles.title}>Speak Up</span>
                        <p className={styles.description}>
                          If you feel up to it, why not express your thoughts and feelings with your
                          organisation. They’re here to help.
                        </p>
                      </div>
                      <div className={styles.chatIcon} />
                    </div>

                    <div className={styles.formSection}>
                      <form onSubmit={handleSubmit}>
                        <div>
                          <textarea
                            name="description"
                            rows={5}
                            value={descriptionValue}
                            onChange={descriptionChangeHandler}
                            onBlur={descriptionBlurHandler}
                            placeholder="What’s on your mind?"
                            className={descriptionHasError ? 'is-invalid' : styles.textArea}
                          />
                          {descriptionHasError && <div className="invalid-feedback" />}
                        </div>
                        <div className={styles.middleDescriptionContainer}>
                          <span className={styles.middleDescription}>
                            {shareIdentityValue ? (
                              <>
                                <span>Your name and email address will be shared</span> alongside
                                your thoughts. If you’d like to keep your thoughts anonymous, untick
                                the box below.
                              </>
                            ) : (
                              <>
                                Your thoughts will be shared <span>anonymously</span>. However, if
                                you’d like them to know it’s you, tick the box below.
                              </>
                            )}
                          </span>
                        </div>

                        <div className={styles.checkContainer}>
                          <Checkbox
                            id="shareIdentity"
                            name="shareIdentity"
                            checked={shareIdentityValue}
                            onChange={checkboxChangeHandler}
                            className={styles.roundedCheckbox}
                          />
                          <label className={styles.checkBoxTitle} htmlFor="shareIdentity">
                            Share my identity
                          </label>
                        </div>

                        <div className={styles.hsGetHelpButton}>
                          <DefaultButton
                            color="primary"
                            type="submit"
                            disabled={!descriptionIsValid || isSharingThought}
                            className={styles.defaultButton}
                          >
                            {isSharingThought ? (
                              <>
                                <CircularProgress size={20} color="inherit" /> &nbsp;Sharing
                                thought...
                              </>
                            ) : (
                              'Share thoughts'
                            )}
                          </DefaultButton>
                        </div>
                      </form>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.speakUpBoxTopContainer2}>
                      <div className={styles.titleContainer}>
                        <span className={styles.title}>Speak Up</span>
                      </div>
                      <div className={styles.chatIcon} />
                    </div>

                    <div className={styles.expressDescriptionContainer}>
                      <p className={styles.expressDescription1}>
                        Thank you for expressing your thoughts and feelings with your organisation.
                        We have shared your thoughts with them.
                      </p>
                      <p className={styles.expressDescription2}>
                        If you are in need of immediate assistance, you can{' '}
                        <span onClick={logStarInstantHelp}>get help here.</span>
                      </p>
                      <p className={styles.expressDescription3}>
                        {isShortStarModal ? (
                          <>
                            Otherwise, you can{' '}
                            <span onClick={() => (handleClose ? handleClose() : null)}>
                              continue
                            </span>{' '}
                            here.
                          </>
                        ) : (
                          <>
                            {' '}
                            Otherwise, you can{' '}
                            <span onClick={() => skipStarStep(isDesktop ? 9 : 24)}>
                              set some actions now
                            </span>{' '}
                            to start improving.
                          </>
                        )}
                      </p>
                    </div>

                    <div className={styles.checkBoxWrapper}>
                      {' '}
                      <svg
                        className={styles.checkmark}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                      >
                        {' '}
                        <circle
                          className={styles.checkmarkCircle}
                          cx="26"
                          cy="26"
                          r="25"
                          fill="none"
                        />{' '}
                        <path
                          className={styles.checkmarkCheck}
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </svg>
                    </div>
                  </>
                )}
              </div>

              <div className={styles.helpBox}>
                <div className={styles.helpBoxTopContainer}>
                  <div className={styles.titleContainer}>
                    <span className={styles.title}>Help is available if you need it</span>
                    <p className={styles.description}>
                      If you find yourself in need of assistance, rest assured that support is
                      readily available to you. Whether you require immediate help or urgent aid,
                      they’re just a click or call away.
                    </p>
                    <p className={styles.description2}>
                      Click on Get Help button to access these resources.
                    </p>
                  </div>
                </div>
                <div className={styles.abstractImg} />
                <div className={styles.hsGetHelpBoxBtn}>
                  <DefaultButton
                    color="primary"
                    type="button"
                    className={styles.defaultButton}
                    onClick={logStarInstantHelp}
                  >
                    Get Help
                  </DefaultButton>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.hsSpeakupDisabledContainer}>
              <div className={styles.title}>Help is available if you need it</div>
              <div className={styles.description}>
                If you find yourself in need of assistance, rest assured that support is readily
                accessible to you. Whether you require immediate help or urgent aid, help is just a
                click or call away.
              </div>
              <div className={styles.secondaryDescription}>
                Click on Get Help button to access those resources.
              </div>
              <div className={styles.hsGetHelpBoxBtn}>
                <DefaultButton
                  color="primary"
                  type="button"
                  className={styles.hsDisabledefaultButton}
                  onClick={logStarInstantHelp}
                >
                  Get Help
                </DefaultButton>
              </div>
              <div className={styles.abstractImg} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

HelpSpeakup.defaultProps = {
  speakUpVisible: false,
  isShortStarModal: false,
  handleClose: () => null,
};

interface HelpSpeakupProps extends React.InputHTMLAttributes<HTMLInputElement> {
  speakUpVisible?: boolean;
  assesmentDataForHelp: { assessmentId: number; questionnaireId: number };
  handleAssessmentData: (data: { assessmentId: number; questionnaireId: number }) => void;
  previewInstantHelp: boolean;
  handleInstantPreview: (arg0: boolean) => void;
  skipStarStep: (arg0: number) => void;
  handleSkipButton: (arg0: string) => void;
  handleClose?: () => void;
  isShortStarModal?: boolean;
}

export default HelpSpeakup;
