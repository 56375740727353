import { getImproveActionsForDashboard } from '@api/actionPlan';
import {
  getBoostersAndDrainers,
  getCheckInScores,
  getDeepDiveScores,
  getFocusAndProgressData,
  getTalkToAdvisorRequests,
  getUrgencyIndex,
  getUserActions,
  getUserAssessmentCount,
  getUserBookingsCount,
  getUserEarlyWarningCount,
  getUserImprovePlanCount,
  getUserLearningResourcesCount,
  getUserReflectionsCount,
  getUserThoughts,
} from '@api/participantReporting';
import SkyBluePrevious from '@assets/images/icons/sky-blue-previous.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import ActionsSection from '@components/Molecules/PariticipantReport/ActionsSection';
import ActivityOverviewSection from '@components/Molecules/PariticipantReport/ActivityOverviewSection';
import AdvisorRecommendationsSection from '@components/Molecules/PariticipantReport/AdvisorRecommendationsSection';
import BoostersAndDrainersSection from '@components/Molecules/PariticipantReport/BoostersAndDrainersSection';
import CommentsSection from '@components/Molecules/PariticipantReport/CommentsSection';
import FocusAreasAndProgressSection from '@components/Molecules/PariticipantReport/FocusAreasAndProgressSection';
import InventionSection from '@components/Molecules/PariticipantReport/InventionSection';
import ScoreGraphSection from '@components/Molecules/PariticipantReport/ScoreGraphSection';
import ScoresSection from '@components/Molecules/PariticipantReport/ScoresSection';
import TalkToAdvisorRequestSection from '@components/Molecules/PariticipantReport/TalkToAdvisorRequestSection';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  BoostersAndDrainersInterfaceData,
  CheckInAndDeepDiveScoreInterface,
  FocusAndProgressRootInterface,
  OverviewCountInterface,
  TalkToAdvisorRequestResponse,
  UrgencyIndexInterface,
  UserActionData,
  UserAssessmentCountInterface,
  UserCreatedPlansCountInterface,
  UserLearningResourcesCountInterface,
  UserThoughtInterface,
} from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import { Backdrop, CircularProgress } from '@mui/material';
import { castResponse } from 'custom.d';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { RootState } from 'store';
import styles from './index.module.scss';

const INITIAL_OVERVIEW_DATA = {
  checkInCount: 0,
  deepDiveCount: 0,
  earlyWarningCount: 0,
  actionsCreatedCount: 0,
  actionsCompletedCount: 0,
  planProgressCount: 0,
  articlesReadCount: 0,
  videosWatchedCount: 0,
  reflectionsCount: 0,
  expertSessionsCount: 0,
};

const INITIAL_ACTIONS = {
  pendingUserActions: [],
  pendingHapstarActions: [],
  completedUserActions: [],
  completedHapstarActions: [],
};

const AdvisorReporting: React.FunctionComponent<AdvisorReportingProps> = (props) => {
  const { participantIdQuery, participantUniqueIdQuery, handleBack } = props;
  const participantId = participantIdQuery ? Number(participantIdQuery) : 0;

  const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD'); // 30 days before today
  const endDate = moment().add(1, 'days').format('YYYY-MM-DD');

  const [focusAreaData, setFocusAreaData] = useState<FocusAndProgressRootInterface | null>(null);
  const [focusAreaDataLoading, setFocusAreaDataLoading] = useState<boolean>(true);

  const [overviewDataCount, setOverviewDataCount] =
    useState<OverviewCountInterface>(INITIAL_OVERVIEW_DATA);
  const [overviewDataLoading, setOverviewDataLoading] = useState<boolean>(true);

  const [boostersAndDrainers, setBoostersAndDrainers] = useState<BoostersAndDrainersInterfaceData>({
    topBoosters: [],
    topDrainers: [],
    topCareerBoosters: [],
    topCareerDrainers: [],
  });
  const [boostersAndDrainersLoading, setBoostersAndDrainersLoading] = useState<boolean>(true);

  const [userActions, setUserActions] = useState<UserActionData>(INITIAL_ACTIONS);
  const [actionsLoading, setActionsLoading] = useState<boolean>(true);

  const [talkToAdvisorRequests, setTalkToAdvisorRequests] = useState<
    TalkToAdvisorRequestResponse[] | []
  >([]);
  const [talkToAdvisorRequestsLoading, setTalkToAdvisorRequestsLoading] = useState<boolean>(true);

  const [checkInScores, setCheckInScores] = useState<CheckInAndDeepDiveScoreInterface | null>(null);
  const [checkInScoresLoading, setCheckInScoresLoading] = useState<boolean>(true);

  const [deepDiveScores, setDeepDiveScores] = useState<CheckInAndDeepDiveScoreInterface | null>(
    null,
  );
  const [deepDiveScoresLoading, setDeepDiveScoresLoading] = useState<boolean>(true);

  const [urgencyIndexDaysCount] = useState<number>(90);
  const [urgencyIndexLoading, setUrgencyIndexLoading] = useState(false);
  const [urgencyIndex, setUrgencyIndex] = useState<UrgencyIndexInterface | null>(null);

  const [userThoughts, setUserThoughts] = useState<UserThoughtInterface[] | []>([]);
  const [userThoughtsLoading, setUserThoughtsLoading] = useState<boolean>(true);

  const [goingBack, setGoingBack] = useState(false);
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);

  useEffect(() => {
    if (participantId && participantId !== 0) {
      getFocusAreaAndProgress();
      getCountsForActivityOverview();
      getBoosterAndDrainerData();
      getAllPlanActions();
      getTalkToAdvisorResponses();
      getScoreData();
      getUrgencyIndexData();
      getAllUserThoughts();
    } else {
      setFocusAreaDataLoading(false);
      setOverviewDataLoading(false);
      setBoostersAndDrainersLoading(false);
      setActionsLoading(false);
      setTalkToAdvisorRequestsLoading(false);
      setCheckInScoresLoading(false);
      setDeepDiveScoresLoading(false);
      setUrgencyIndexLoading(false);
      setUserThoughtsLoading(false);
    }
  }, [participantId]);

  const getFocusAreaAndProgress = async () => {
    try {
      const response = await getFocusAndProgressData(participantId, null, null);

      if (response?.success) {
        const data = castResponse<FocusAndProgressRootInterface>(response);
        setFocusAreaData(data);
      } else {
        setFocusAreaData(null);
      }
    } catch (error) {
      setFocusAreaDataLoading(false);
      setFocusAreaData(null);
    } finally {
      setFocusAreaDataLoading(false);
    }
  };

  const getCountsForActivityOverview = async () => {
    try {
      setOverviewDataLoading(true);

      const [response1, response2, response3, response4, response5, response6, response7] =
        await Promise.all([
          getUserAssessmentCount(participantId, startDate, endDate),
          getUserEarlyWarningCount(participantId, startDate, endDate),
          getUserImprovePlanCount(participantId, startDate, endDate),
          getUserReflectionsCount(participantId, startDate, endDate),
          getUserBookingsCount(participantId, startDate, endDate),
          getUserLearningResourcesCount(participantId, startDate, endDate),
          getImproveActionsForDashboard(participantId),
        ]);

      if (response1.success) {
        const data1 = castResponse<UserAssessmentCountInterface>(response1);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          checkInCount: data1.checkIns ? Number(data1.checkIns) : 0,
          deepDiveCount: data1.deepDives ? Number(data1.deepDives) : 0,
        }));
      }

      if (response2.success) {
        const data2 = castResponse<{ earlyWarningDisplayed: number }>(response2);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          earlyWarningCount: data2.earlyWarningDisplayed ? Number(data2.earlyWarningDisplayed) : 0,
        }));
      }

      if (response3.success) {
        const data3 = castResponse<UserCreatedPlansCountInterface>(response3);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          actionsCreatedCount: data3.totalActionsCount ? Number(data3.totalActionsCount) : 0,
          actionsCompletedCount: data3.totalActionsCompletedCount
            ? Number(data3.totalActionsCompletedCount)
            : 0,
        }));
      }

      if (response4.success) {
        const data4 = castResponse<{ userReflectionsCount: number }>(response4);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          reflectionsCount: data4.userReflectionsCount ? Number(data4.userReflectionsCount) : 0,
        }));
      }

      if (response5.success) {
        const data5 = castResponse<{ userBookingCount: number }>(response5);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          expertSessionsCount: data5.userBookingCount ? Number(data5.userBookingCount) : 0,
        }));
      }

      if (response6.success) {
        const data6 = castResponse<UserLearningResourcesCountInterface>(response6);
        setOverviewDataCount((prevState) => ({
          ...prevState,
          articlesReadCount: data6.articleContent ? Number(data6.articleContent) : 0,
          videosWatchedCount: data6.videoContent ? Number(data6.videoContent) : 0,
        }));
      }

      if (response7) {
        setOverviewDataCount((prevState) => ({
          ...prevState,
          planProgressCount: response7.totalProgress ? Number(response7.totalProgress) : 0,
        }));
      }
    } catch (error) {
      setOverviewDataCount(INITIAL_OVERVIEW_DATA);
    } finally {
      setOverviewDataLoading(false);
    }
  };

  const getBoosterAndDrainerData = async () => {
    try {
      setBoostersAndDrainersLoading(true);
      const response = await getBoostersAndDrainers(participantId);
      if (response.success) {
        const data = castResponse<BoostersAndDrainersInterfaceData>(response);
        setBoostersAndDrainers(data);
      } else {
        setBoostersAndDrainers({
          topBoosters: [],
          topDrainers: [],
          topCareerBoosters: [],
          topCareerDrainers: [],
        });
      }
    } catch {
      setBoostersAndDrainers({
        topBoosters: [],
        topDrainers: [],
        topCareerBoosters: [],
        topCareerDrainers: [],
      });
    } finally {
      setBoostersAndDrainersLoading(false);
    }
  };

  const getAllPlanActions = async () => {
    try {
      setActionsLoading(true);
      const response = await getUserActions(participantId);
      if (response.success) {
        const data = castResponse<UserActionData>(response);
        setUserActions(data);
      } else {
        setUserActions(INITIAL_ACTIONS);
      }
    } catch {
      setUserActions(INITIAL_ACTIONS);
    } finally {
      setActionsLoading(false);
    }
  };

  const getTalkToAdvisorResponses = async () => {
    try {
      setTalkToAdvisorRequestsLoading(true);
      const response = await getTalkToAdvisorRequests(participantId);
      if (response.success) {
        const data = castResponse<TalkToAdvisorRequestResponse[]>(response);
        setTalkToAdvisorRequests(data);
      } else {
        setTalkToAdvisorRequests([]);
      }
    } catch {
      setTalkToAdvisorRequests([]);
    } finally {
      setTalkToAdvisorRequestsLoading(false);
    }
  };

  const getScoreData = () =>
    new Promise<void>((resolve, reject) => {
      (async () => {
        try {
          setCheckInScoresLoading(true);
          setDeepDiveScoresLoading(true);

          const [checkIns, deepDives] = await Promise.all([
            getCheckInScores(participantId),
            getDeepDiveScores(participantId),
          ]);

          if (checkIns.success) {
            const checkInData = castResponse<CheckInAndDeepDiveScoreInterface>(checkIns);
            setCheckInScores(checkInData);
          } else {
            setCheckInScores(null);
          }

          if (deepDives.success) {
            const deepDiveData = castResponse<CheckInAndDeepDiveScoreInterface>(deepDives);
            setDeepDiveScores(deepDiveData);
          } else {
            setDeepDiveScores(null);
          }

          resolve();
        } catch (error) {
          setCheckInScores(null);
          setDeepDiveScores(null);
          reject(error);
        } finally {
          setCheckInScoresLoading(false);
          setDeepDiveScoresLoading(false);
        }
      })();
    });

  const getUrgencyIndexData = async () => {
    try {
      setUrgencyIndexLoading(true);
      const response = await getUrgencyIndex(participantId, urgencyIndexDaysCount);
      if (response.success) {
        const data = castResponse<UrgencyIndexInterface>(response);
        setUrgencyIndex(data);
      } else {
        setUrgencyIndex(null);
      }
    } catch {
      setUrgencyIndex(null);
    } finally {
      setUrgencyIndexLoading(false);
    }
  };

  const getAllUserThoughts = async () => {
    try {
      setUserThoughtsLoading(true);
      const response = await getUserThoughts(participantId);
      if (response.success) {
        const data = castResponse<any>(response);
        setUserThoughts(data);
      } else {
        setUserThoughts([]);
      }
    } catch {
      setUserThoughts([]);
    } finally {
      setUserThoughtsLoading(false);
    }
  };

  if (participantIdQuery === null || participantIdQuery === undefined || participantId === 0) {
    return (
      <MainLayout title="Advisor Reporting" secondaryNavEnabled useAdvisorNav isAdvisorPage>
        <div className={styles.errorContainer}>
          <h4>
            {participantId === 0 ? 'Wrong Participant Id.' : 'Participant Id is missing.'} Please
            enter it correctly and try again.
          </h4>
          <DefaultButton onClick={handleBack}>Back to home page</DefaultButton>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout title="Advisor Reporting" secondaryNavEnabled useAdvisorNav isAdvisorPage>
      <div className={styles.contentContainer}>
        <div className={styles.topSection}>
          <div>
            <h2 className={styles.mainTitle}>Participant Snapshot</h2>
            <h5 className={styles.participantUniqueValue}>
              Participant Id: {participantUniqueIdQuery}
            </h5>
          </div>

          <div
            className={styles.navigateBack}
            onClick={async () => {
              setGoingBack(true);
              setTimeout(() => {
                setGoingBack(false);
                handleBack();
              }, 500);
            }}
          >
            <Img src={SkyBluePrevious} alt="HS Previous" className={styles.previous} />
            <h5>Pariticipant Report</h5>
          </div>
        </div>

        <InventionSection data={urgencyIndex} loading={urgencyIndexLoading} />

        {companyConfig && companyConfig.participantReportUserInsightsEnabled && (
          <AdvisorRecommendationsSection participantId={participantId} />
        )}

        <ScoresSection
          checkInScores={checkInScores}
          checkInScoresLoading={checkInScoresLoading}
          deepDiveScores={deepDiveScores}
          deepDiveScoresLoading={deepDiveScoresLoading}
        />

        <FocusAreasAndProgressSection data={focusAreaData} loading={focusAreaDataLoading} />

        <ActivityOverviewSection data={overviewDataCount} loading={overviewDataLoading} />

        <BoostersAndDrainersSection
          data={boostersAndDrainers}
          loading={boostersAndDrainersLoading}
        />

        <ActionsSection
          data={userActions}
          refetchActions={getAllPlanActions}
          loading={actionsLoading}
          participantId={participantId}
        />

        <TalkToAdvisorRequestSection
          data={talkToAdvisorRequests}
          loading={talkToAdvisorRequestsLoading}
        />

        <CommentsSection
          data={userThoughts}
          loading={userThoughtsLoading}
          refetchThoughts={getAllUserThoughts}
        />

        <ScoreGraphSection participantId={participantId} />
      </div>

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={goingBack}
        onClick={() => setGoingBack(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MainLayout>
  );
};

interface AdvisorReportingProps {
  participantIdQuery: number | null | undefined;
  participantUniqueIdQuery: string | null;
  handleBack: () => void;
}

export default AdvisorReporting;
