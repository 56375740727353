import { getActions, updateAction } from '@api/actions';
import { getAssessment, getScores } from '@api/assessment';
import { getIpAddress } from '@api/auth/login';
import { getHistoricalData } from '@api/historicalArea';
import { getHelpResponses } from '@api/instantHelp';
import { createLog } from '@api/log';
import { getScore } from '@api/score';
import { getThoughtsByFilter } from '@api/thoughts';
import completeImage from '@assets/images/icons/Star.svg';
import blueTray from '@assets/images/icons/blueTray.svg';
import leftArrow from '@assets/images/leftArow.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import CategoryScoreCard from '@components/CategoryScoreCard';
import Area from '@components/Charts/Area';
import Gauge from '@components/Charts/Gauge';
import ColoredBox from '@components/ColoredBox';
import ModalContent from '@components/ModalContent';
import FinishFirstStarMessage from '@components/ModalContent/FinishFirstStarMessage';
import PageTitle from '@components/PageTitle';
import ScorePageContent from '@components/ScorePageContent';
import Star, { WingDetails } from '@components/Star';
import ThoughtsAndSpeakupCard from '@components/ThoughtsAndSpeakupCard';
import NewHomeThoughtsAndSpeakupCard from '@components/ThoughtsAndSpeakupCard/newHomepage';
import { CreateStarContext } from '@context/CreateStarContext';
import {
  COMPLETE_ACTIONS,
  SET_ACTIONS,
  SET_ACTIONS_COMPLETED,
  SET_CURRENT_TAB,
  SET_GRAPH_DATA,
  SET_HISTORICAL_DATA,
  useGlobalState,
} from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import useDevFeatureToggle from '@hooks/useDevFeatureToggle';
import useGoNative from '@hooks/useGoNative';
import useQuery from '@hooks/useQuery';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CategoryScores, CreateStarCategoryInterface } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import { formatDateStringSplitDots } from '@utils/date.util';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  LogTypes,
  ReminderTypes,
  SpeakUpResponseInterface,
  ThoughtDataInterface,
} from '../../custom.d';
import styles from './index.module.scss';

const DeepDiveStarPage: React.FunctionComponent = () => {
  const location = useLocation();
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user || '{}');
  const isMobileApp = useGoNative();
  const { activeSubNav, toggleSubNav } = useContext(SecondaryNavigationContext);
  const [state, dispatch] = useGlobalState();
  const [categoryScores, setCategoryScores] = useState<CategoryScores[] | any>();
  const { categoryQuestion } = useContext(CreateStarContext);
  const [averageScore, setAverageScore] = useState<number | any>(null);
  const [wingDetails, setWingDetails] = useState<WingDetails[]>([]);
  const [areaData, setAreaData] = useState<number[]>([]);
  const [areaLabels, setAreaLabels] = useState<string[]>([]);
  const [areaStars, setAreaStars] = useState<WingDetails[][]>([]);
  const [historicalData, setHistoricalData] = useState<AssessmentDataInterface[]>([]);
  const [error, setError] = useState<string>('');
  const [recordsPerPage] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [indexGraph, setIndexGraph] = useState<number>(9);
  const [selectedIndex, setSelectedIndex] = useState<number>(9);
  const history = useHistory();
  const [finishFirstStarFocusArea, setFinishFirstStarFocusArea] = useState(false);
  const [speakupResponses, setSpeakupResponses] = useState<SpeakUpResponseInterface[]>([]);
  const [speakupResponsesResponded, setSpeakupResponsesResponded] = useState<
    SpeakUpResponseInterface[]
  >([]);
  const [allResponses, setAllResponses] = useState<CombinedCommentsArray>([]);
  const [allResponsesResponded, setAllResponsesResponded] = useState<CombinedCommentsArray>([]);
  const { isDesktop, isMobile } = useWindowDimensions();
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const searchParams = useQuery();
  const param = searchParams.get('1stTimeUser');
  const historicalDataPerPage = isMobile ? 5 : 10;
  const [assessmentForFilter, setAssessmentForFilter] = useState<AssessmentFilterState>({
    selectedAssessment: defaultAssessment,
    latestAssessment: defaultAssessment,
  });
  const [thoughtsResponses, setThoughtsResponses] = useState<ThoughtDataInterface[]>([]);
  const [thoughtsResponsesResponded, setThoughtsResponsesResponded] = useState<
    ThoughtDataInterface[]
  >([]);
  const selectedAssessment =
    assessmentForFilter?.selectedAssessment ?? assessmentForFilter?.latestAssessment;

  const [categoryQuestion2, setCategoryQuestion] = useState<CreateStarCategoryInterface[]>([]);
  const devEnabled = useDevFeatureToggle();

  const params = new URLSearchParams(location.search);
  const redirectedBy = params.get('redirectedBy');

  useEffect(() => {
    toggleSubNav({ id: 1, name: 'Overall' });
  }, []);

  useEffect(() => {
    if (redirectedBy && redirectedBy === ReminderTypes.NO_DEEP_DIVE_TAKEN) {
      handleCreateUserReminderLog();
    }
  }, [redirectedBy]);

  const handleCreateUserReminderLog = async () => {
    const ipAddress = await getIpAddress();
    const payload = {
      type: LogTypes.NO_DEEP_DIVE_TAKEN_EMAIL_CLICKED,
      ipAddress,
      os: navigator.userAgentData?.platform || '',
      isNative: isMobileApp,
    };

    const log = await createLog(payload);

    if (log) {
      params.delete('redirectedBy');
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
  };

  useEffect(() => {
    if (
      selectedAssessment &&
      selectedAssessment?.assessment_id > 0 &&
      selectedAssessment?.questionnaire_id > 0
    ) {
      getThoughtsByFilter(true, [activeSubNav.id], selectedAssessment?.assessment_id, 'DESC').then(
        (data: ThoughtDataInterface[]) => {
          setThoughtsResponses(data);
        },
      );
      getThoughtsByFilter(false, [activeSubNav.id], selectedAssessment?.assessment_id, 'DESC').then(
        (data: ThoughtDataInterface[]) => {
          setThoughtsResponsesResponded(data);
        },
      );
    }
  }, [activeSubNav.id, selectedAssessment]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      getAssessment(1, isDesktop ? 10 : 5, activeSubNav.id, state?.graphData?.assesmentId, null)
        .then((data) => {
          const {
            result,
            areaData: areaDataResp,
            areaLabels: areaLabelsResp,
            areaStars: areaStarsResp,
            catScores,
            starDetails,
          } = data;

          setAreaData(areaDataResp);
          setAreaLabels(areaLabelsResp);
          setAreaStars(areaStarsResp);
          setCategoryScores(
            [...catScores].sort((a: CategoryScores, b: CategoryScores) => b.score - a.score),
          );
          setWingDetails(starDetails);
          setError('');
        })
        .catch((err) => {
          if (err) {
            setError('NO DATA, SET YOUR STAR');
          }
        });
      const getUserScore = async () => {
        const { data } = await getScore(activeSubNav.id, state?.graphData?.assesmentId);
        setAverageScore(data.average);
      };

      const getScoresData = async () => {
        setLoading(true);
        setError('');

        try {
          const data = await getScores(activeSubNav.id, state?.graphData?.assesmentId, null);
          setLoading(false);
          setCategoryQuestion(data);
        } catch (err: any) {
          if (JSON.parse(err.message).message) {
            history.push('/create-a-star');
          }
        }
      };

      const getActions1 = async () => {
        const actionsData = await getActions(activeSubNav.id, state?.graphData?.assesmentId);
        if (
          actionsData.message &&
          activeSubNav.id === JSON.parse(sessionStorage.getItem('subNav') as any)?.id
        ) {
          if (devEnabled) {
            history.push('/new/create-a-star?onlyDevFeature=true');
          } else {
            history.push('/create-a-star');
          }
        } else if (!actionsData.message) {
          dispatch({ type: SET_ACTIONS, payload: actionsData });
        }
      };

      getActions1();
      getUserScore();
      getScoresData();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err, 'err');
    }
  }, [
    dispatch,
    isDesktop,
    activeSubNav.id,
    indexOfLastRecord,
    indexOfFirstRecord,
    state?.graphData?.assesmentId,
  ]);

  useEffect(() => {
    if (historicalData.length > 0) {
      const lastItem = historicalData[historicalData.length - 1];
      setAssessmentForFilter({
        selectedAssessment: historicalData?.find(
          (dt) => dt.assessment_id === state?.graphData?.assesmentId,
        ),
        latestAssessment: lastItem,
      });
    }
  }, [historicalData, state?.graphData?.assesmentId]);

  useEffect(() => {
    const getHistorical = async () => {
      const data = await getHistoricalData(
        activeSubNav.id,
        null,
        currentPage,
        historicalDataPerPage,
      );
      setHistoricalData(data?.data?.reverse());
      dispatch({ type: SET_HISTORICAL_DATA, payload: data.data });
      const totalRecords = data?.pagination?.TotalRecords ? data?.pagination?.TotalRecords : 0;
      const perPage = data?.pagination?.perPage ? data?.pagination?.perPage : 10;
      setTotalPages(Math.ceil(totalRecords / perPage));
    };

    getHistorical();
  }, [currentPage, activeSubNav.id, dispatch, historicalDataPerPage]);

  useEffect(() => {
    if (
      selectedAssessment &&
      selectedAssessment?.assessment_id > 0 &&
      selectedAssessment?.questionnaire_id > 0
    ) {
      getHelpResponses(
        selectedAssessment?.assessment_id,
        selectedAssessment?.questionnaire_id,
        false,
      ).then((data: SpeakUpResponseInterface[]) => setSpeakupResponses(data));

      getHelpResponses(
        selectedAssessment?.assessment_id,
        selectedAssessment?.questionnaire_id,
        true,
      ).then((data: SpeakUpResponseInterface[]) => setSpeakupResponsesResponded(data));
    }
  }, [assessmentForFilter, selectedAssessment]);

  useEffect(() => {
    const modifiedThoughtsResponsesv1 = thoughtsResponses.map((res: ThoughtDataInterface) => ({
      ...res,
      isSpeakup: false,
    }));

    const modifiedThoughtsResponsesv2 = thoughtsResponsesResponded
      .filter((dt) => dt.response)
      .map((res: ThoughtDataInterface) => ({
        ...res,
        isSpeakup: false,
      }));

    const modifiedSpeakupResponsesv1 = speakupResponses
      .filter((dt) => dt.questionnaireId === activeSubNav.id)
      .map((res: SpeakUpResponseInterface) => ({
        ...res,
        isSpeakup: true,
      }));

    const modifiedSpeakupResponsesv2 = speakupResponsesResponded
      .filter((dt) => dt.questionnaireId === activeSubNav.id)
      .map((res: SpeakUpResponseInterface) => ({
        ...res,
        isSpeakup: true,
      }));

    const combinedResponsesv1 = [...modifiedThoughtsResponsesv1, ...modifiedSpeakupResponsesv1];

    const combinedResponsesv2 = [...modifiedThoughtsResponsesv2, ...modifiedSpeakupResponsesv2];

    const sortedResponsesv1 = combinedResponsesv1.sort(
      (
        a: ThoughtDataInterface | SpeakUpResponseInterface,
        b: ThoughtDataInterface | SpeakUpResponseInterface,
      ) => {
        const dateA: string | undefined = 'createdAt' in a ? a.createdAt : a.speakUpCreatedDate;
        const dateB: string | undefined = 'createdAt' in b ? b.createdAt : b.speakUpCreatedDate;

        if (dateA && dateB) {
          return Number(new Date(dateB)) - Number(new Date(dateA));
        }

        return 0;
      },
    );

    const sortedResponsesv2 = combinedResponsesv2.sort(
      (
        a: ThoughtDataInterface | SpeakUpResponseInterface,
        b: ThoughtDataInterface | SpeakUpResponseInterface,
      ) => {
        const dateA: string | undefined = 'createdAt' in a ? a.createdAt : a.speakUpCreatedDate;
        const dateB: string | undefined = 'createdAt' in b ? b.createdAt : b.speakUpCreatedDate;

        if (dateA && dateB) {
          return Number(new Date(dateB)) - Number(new Date(dateA));
        }

        return 0;
      },
    );

    setAllResponses(sortedResponsesv1);
    setAllResponsesResponded(sortedResponsesv2);
  }, [
    speakupResponses,
    activeSubNav,
    thoughtsResponses,
    thoughtsResponsesResponded,
    speakupResponsesResponded,
  ]);

  const addToCompleted = async (action: any) => {
    dispatch({ type: COMPLETE_ACTIONS, payload: action.id });

    /* eslint-disable */
    if (state?.actions?.filter((k: any) => !k.complete).length - 1 < 1) {
      dispatch({ type: SET_ACTIONS_COMPLETED, payload: true });
    }
    /* eslint-enable */

    await updateAction(
      {
        actionId: action.id,
        completeStatus: !action.complete,
      },
      activeSubNav.id,
      state?.graphData?.assesmentId,
    );
  };

  const onPrevHistoricalData = () => {
    setIndexGraph((prev) => prev - 1);
    setSelectedIndex((prev) => prev - 1);
    dispatch({
      type: SET_GRAPH_DATA,
      payload: {
        assesmentId: historicalData[indexGraph - 1]?.assessment_id,
        date: dayjs(historicalData[indexGraph - 1]?.created_at).format('DD/MM/YYYY'),
      },
    });
  };

  const onNextHistoricalData = () => {
    setIndexGraph((prev) => prev + 1);
    setSelectedIndex((prev) => prev + 1);
    dispatch({
      type: SET_GRAPH_DATA,
      payload: {
        assesmentId: historicalData[indexGraph + 1]?.assessment_id,
        date: dayjs(historicalData[indexGraph + 1]?.created_at).format('DD/MM/YYYY'),
      },
    });
  };

  const nextPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const prevPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleIndexGraph = (index: number) => {
    setIndexGraph(index);
  };

  useEffect(() => {
    setIndexGraph(historicalData.length - 1);
    setSelectedIndex(historicalData.length - 1);
  }, [historicalData]);

  const handleFinishFirstStarFocusArea = () => {
    setFinishFirstStarFocusArea(!finishFirstStarFocusArea);
  };

  useEffect(() => {
    if (param === 'true') {
      setFinishFirstStarFocusArea(true);
    }
  }, [param]);

  return (
    <MainLayout title="Scores">
      <div className={styles.hsTitleRoot}>
        <PageTitle
          title={
            state?.graphData?.date && indexGraph + 1 !== historicalData.length
              ? state?.graphData?.date
              : 'Current'
          }
        />

        <div>
          <DefaultButton
            onClick={() => {
              dispatch({ type: SET_CURRENT_TAB, payload: { id: null, name: '' } });
              if (devEnabled) {
                history.push(
                  categoryScores?.length > 0
                    ? '/new/create-a-star?onlyDevFeature=true'
                    : '/introduction',
                );
              } else {
                history.push(categoryScores?.length > 0 ? '/create-a-star' : '/introduction');
              }
            }}
            variant="primary"
            type="button"
          >
            Set my star
          </DefaultButton>
        </div>
      </div>
      {(!categoryScores && !wingDetails && !areaData) || error ? (
        <div className={styles.areaError}>{error}</div>
      ) : (
        <div className="container-fluid">
          <div className="row mt-5">
            <div className={styles.scoreContainer}>
              <div className={styles.arrowCont}>
                {indexGraph !== 0 && historicalData.length > 1 && (
                  <div onClick={onPrevHistoricalData} className={styles.historicalDataLeftArrow}>
                    <Img src={leftArrow} alt="icon" className={styles.leftArrowIcon} />
                  </div>
                )}
              </div>

              <div className={styles.wingDetailsContainer}>
                {wingDetails.length > 0 && <Star wingDetails={wingDetails} />}
              </div>
              <div className={`${styles.gaugeContainer}`}>
                {averageScore && <Gauge score={averageScore} />}
              </div>
              <div className={styles.categoryScoreContainer}>
                {categoryScores?.length > 0 &&
                  categoryScores?.map((categoryScore: CategoryScores) => (
                    <CategoryScoreCard
                      key={categoryScore.id}
                      category={categoryScore.category}
                      score={categoryScore.score}
                      color={categoryScore.color}
                    />
                  ))}
              </div>
              <div className={styles.arrowCont}>
                {indexGraph < historicalData.length - 1 && (
                  <div onClick={onNextHistoricalData} className={styles.historicalDataRightArrow}>
                    <Img src={leftArrow} alt="icon" className={styles.rightArrowIcon} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.areaContainer}>
            <div className={styles.fullWidth}>
              {areaData.length > 0 && areaStars.length > 0 && areaLabels.length > 0 && (
                <Area
                  values={historicalData}
                  stars={areaStars}
                  labels={areaLabels}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  setIndexGraph={(index: number) => handleIndexGraph(index)}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                />
              )}
            </div>
          </div>

          <div>
            <p>&nbsp;</p>
          </div>

          <ScorePageContent data={categoryQuestion2} showButton />

          <div className="row mt-5">
            <div className={styles.actionTabSection}>
              <ColoredBox title="My Thoughts" color="blue">
                <Tabs>
                  <TabList>
                    <Tab>Entries</Tab>
                    <Tab>Responses</Tab>
                  </TabList>
                  <TabPanel>
                    {allResponses?.length > 0 ? (
                      allResponses.map((action: any, index: number) => (
                        <ThoughtsAndSpeakupCard
                          key={index}
                          focusArea={action.isSpeakup ? 'Speak up' : action.title}
                          focusAreaColor={action.isSpeakup ? '#019DF2' : action.color}
                          action={action.isSpeakup ? action.speakUpMessage : action.thoughtsText}
                          score={action.isSpeakup ? 0 : action.score}
                          completed={action.complete || false}
                          createdDate={
                            action.isSpeakup ? action.speakUpCreatedDate : action.created_at
                          }
                          isSpeakup={action.isSpeakup}
                        />
                      ))
                    ) : (
                      <div className={styles.noActionsContainer}>
                        <Img src={blueTray} className={styles.imgHeight94} alt="blue tray" />
                        <p className={styles.noActionsText}>
                          You haven’t shared any thoughts. Adding a thought will help remind you of
                          your scoring reasons and give context to them
                        </p>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {allResponsesResponded?.length > 0 ? (
                      allResponsesResponded.map((action: any, index: number) => (
                        <NewHomeThoughtsAndSpeakupCard
                          key={index}
                          commentTitle={
                            action?.isSpeakup
                              ? `Your comment on ${formatDateStringSplitDots(
                                  action?.speakUpCreatedDate,
                                )}`
                              : 'Your comment'
                          }
                          focusArea={action?.isSpeakup ? 'Speak up' : action?.title}
                          questionnaireName={
                            action?.isSpeakup ? action?.questionnaire : action?.questionnaireName
                          }
                          focusAreaColor={action.isSpeakup ? '#019DF2' : action.color}
                          action={action?.isSpeakup ? action?.speakUpMessage : action?.thoughtsText}
                          score={action?.isSpeakup ? 0 : action?.score}
                          response={
                            action?.isSpeakup
                              ? action?.response?.responseMessage || ''
                              : action?.response?.responseMessage
                          }
                          responseTitle={
                            action?.isSpeakup
                              ? `Response on ${formatDateStringSplitDots(
                                  action?.response?.responseCreated,
                                )}`
                              : 'Response'
                          }
                          thoughtId={action?.isSpeakup ? action?.speakUpId : action?.thoughtId}
                          updateResponseAction={() => null}
                          speakupResponseId={action?.response?.responseId || 0}
                          isSpeakup={action?.isSpeakup}
                          disabledSeen
                        />
                      ))
                    ) : (
                      <div className={styles.noActionsContainer}>
                        <Img src={blueTray} className={styles.imgHeight94} alt="blue tray" />
                        <p className={styles.noActionsText}>
                          No responses yet. When your administrator responds to your shared
                          thoughts, they’ll appear here
                        </p>
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              </ColoredBox>
            </div>
          </div>

          <Modal
            isOpen={finishFirstStarFocusArea}
            onRequestClose={handleFinishFirstStarFocusArea}
            contentLabel="Example Modal"
            ariaHideApp={false}
            style={{
              content: {
                width: isMobile ? '90%' : undefined,
              },
            }}
          >
            <ModalContent
              onClose={() => setFinishFirstStarFocusArea(false)}
              hasBackground
              title={
                <>
                  <div
                    className={
                      isMobile ? styles.firstStarMessageTitleM : styles.firstStarMessageTitle
                    }
                  >
                    <div>Well done!</div>
                    <div>
                      You’ve finished your first star&nbsp;
                      <Img src={completeImage} alt="star" className={styles.completedStar} />
                    </div>
                  </div>
                </>
              }
            >
              <FinishFirstStarMessage />
            </ModalContent>
          </Modal>
        </div>
      )}
    </MainLayout>
  );
};

type CombinedCommentsArray = (ThoughtDataInterface | SpeakUpResponseInterface)[];

interface AssessmentDataInterface {
  assessment_id: number;
  user_id: number;
  questionnaire_id: number;
  created_at: string;
  score: number;
}
interface AssessmentFilterState {
  selectedAssessment?: AssessmentDataInterface;
  latestAssessment?: AssessmentDataInterface;
}

const defaultAssessment: AssessmentDataInterface = {
  assessment_id: 0,
  user_id: 0,
  questionnaire_id: 0,
  created_at: '',
  score: 0,
};

export default DeepDiveStarPage;
