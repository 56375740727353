/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import MainLayout from '@layouts/MainLayout';
import React from 'react';
import row1ShapesImage from '@assets/images/whatIsHapstar/Row1.svg';
import row2ShapesImage from '@assets/images/whatIsHapstar/Row2.svg';
import row3ShapesImage from '@assets/images/whatIsHapstar/Row3.svg';
import row4ShapesImage from '@assets/images/whatIsHapstar/Row4.svg';
import row5ShapesImage from '@assets/images/whatIsHapstar/Row5.svg';
import styles from './index.module.scss';

const WhatIsHapstar: React.FunctionComponent = () => {
  return (
    <MainLayout title="About" secondaryNavEnabled isEmployerPage>
      <div className={styles.container}>
        <div className={styles.whatIsHapstar}>
          <div>
            <h1>About</h1>
          </div>

          <div style={{ marginTop: 45 }}>
            <div>
              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                <b>Welcome to your wellbeing platform.</b>
              </p>
            </div>
            <div>
              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                This is your opportunity to unlock your wellbeing profile, understand how it impacts
                your mental health and access your personalised support resources.
              </p>
            </div>
            <div>
              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                The process is simple: input your scores, your scores are analysed and matched with
                resources carefully designed to help, then track how your profile changes over time.
              </p>

              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                <b>So, what do you need to do?</b>
              </p>

              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                1- Check in with a simple score showing how you feel
              </p>

              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                2- Explore why you feel how you do by scoring your 5 core pillars of wellbeing -
                mental, physical, social, financial and career health
              </p>

              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                3- Take a detailed assessment for each pillar to create a holistic view of your
                overall wellbeing
              </p>

              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                4- Access your personalised wellbeing plan and work through the activities of your
                choice to start addressing the issues that matter
              </p>

              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                5- Re-score over time to gain a balanced view of your wellbeing - a low score on one
                day may just be because you are over-tired, so don’t let one-off scores impact your profile
              </p>
            </div>
          </div>
        </div>
        <img src={row1ShapesImage} alt="" className={styles.rightPosition} />
        <div className={styles.flexRow}>
          <div>
            <img src={row2ShapesImage} alt="" className={styles.img} />
          </div>
          <div className={styles.marginLeft}>
            <div>
            <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                <b>Your overall wellbeing is impacted by an interaction of your Mental, Physical, Social,
                  Financial, and Career health.</b>{' '}
              </p>
              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                For example, think of how your social wellbeing or financial health can affect your mental 
                state. Social wellbeing evidence shows that having good-quality relationships can help us to
                live longer and happier lives with fewer mental health problems. Having close, positive relationships
                can give us a purpose and sense of belonging. Loneliness and isolation remain the key predictors
                for poor psychological and physical health. Having a lack of good relationships and long-term feelings
                of loneliness have been shown by a range of studies to be associated with higher rates of mortality,
                poor physical health outcomes and lower life satisfaction.{' '}
              </p>
              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
              Your wellbeing, like your mental health, is complex. The first step is taking time to understand.
              The second step is having personalised support or learning to build healthier habits or changes -
              no matter how big or small.{' '}
              </p>
              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                <b>That’s what this wellbeing platform is for.</b>{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default WhatIsHapstar;
