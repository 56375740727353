import { useState } from 'react';
import useQuery from './useQuery';

export default function useChatAssistant() {
  const searchParams = useQuery();
  const checkChatAssistant = searchParams.get('chatAssistant') === 'true';
  const [chatAssistant] = useState(checkChatAssistant);

  return chatAssistant;
}
