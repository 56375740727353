import { getExpertBookingHistory } from '@api/experts';
import DataTable from '@components/Atoms/DataTable';
import Modal from '@components/Atoms/Modal';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ExpertBookingUserPreference } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { formatDateToDateMonthYear } from '@utils/date.util';
import classNames from 'classnames';
import { EXPERT_SESSION_REQUEST_AREAS, castResponse } from 'custom.d';
import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import BookingAllExperts from '../BookingAllExperts';
import styles from './index.module.scss';

const BookingRequests: FunctionComponent = () => {
  const { isDesktop, isTablet } = useWindowDimensions();
  const [openExperts, setOpenExperts] = useState<{
    open: boolean;
    requestId: number;
    rowDetails: BookingRequestData | null;
  }>({ open: false, requestId: 0, rowDetails: null });
  const [historyData, setHistoryData] = useState<ExpertBookingUserPreference[] | []>([]);
  const [historyDataLoading, setHistoryDataLoading] = useState<boolean>(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [selectedArea, setSelectedArea] = useState('All areas');

  useEffect(() => {
    if (openExperts.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [openExperts.open]);

  useEffect(() => {
    getHistory();
  }, [openExperts.open]);

  const getHistory = async () => {
    try {
      setHistoryDataLoading(true);
      const response = await getExpertBookingHistory();
      if (response.success) {
        const data = castResponse<ExpertBookingUserPreference[]>(response);
        const sortedData = data.sort((a, b) => a.id - b.id);
        setHistoryData(sortedData);
      } else {
        setHistoryData([]);
      }
    } catch {
      setHistoryData([]);
    } finally {
      setHistoryDataLoading(false);
    }
  };

  type BookingRequestData = (typeof historyData)[0];

  const columns: Array<{
    id: keyof BookingRequestData;
    label: string;
    render?: (value: any) => React.ReactNode;
  }> = [
    { id: 'id', label: 'ID' },
    { id: 'area', label: 'Area' },
    {
      id: 'preferredDays',
      label: 'Preferred Days',
      render: (value: string) => {
        try {
          const parsed = JSON.parse(value);

          return Array.isArray(parsed) ? parsed.join(', ') : 'N/A';
        } catch {
          return 'N/A';
        }
      },
    },
    {
      id: 'preferredTimes',
      label: 'Preferred Times',
      render: (value: string) => {
        try {
          const parsed = JSON.parse(value);

          return Array.isArray(parsed) ? parsed.join(', ') : 'N/A';
        } catch {
          return 'N/A';
        }
      },
    },
    { id: 'emailSent', label: 'Email Sent', render: (value: boolean) => (value ? 'Yes' : 'No') },
    { id: 'userTimezone', label: 'Timezone' },
    {
      id: 'createdAt',
      label: 'Created At',
      render: (value: string) =>
        `${formatDateToDateMonthYear(value)}, ${new Date(value).toLocaleTimeString()}`,
    },
    {
      id: 'done',
      label: 'Status',
      render: (value: boolean) =>
        value ? (
          <span className={styles.successStatus}>Completed</span>
        ) : (
          <span className={styles.notSuccessStatus}>Pending</span>
        ),
    },
  ];

  const handleRowClick = (row: BookingRequestData) => {
    if (row.done) {
      toast.info('This request has already been completed.');
    } else {
      setOpenExperts({ open: true, requestId: row.id, rowDetails: row });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCompleted(event.target.checked);
  };

  const handleAreaChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedArea(event.target.value as string);
  };

  const filteredData = historyData.filter(
    (row) =>
      (showCompleted ? row.done : !row.done) &&
      (selectedArea === 'All areas' || row.area === selectedArea),
  );

  return (
    <MainLayout title="Experts Management" useSuperAdminNav isSuperAdminPage secondaryNavEnabled>
      <div className={styles.mainContainer}>
        <div className={styles.topSection}>
          <h1 className={styles.pageTitle}>Booking Requests</h1>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ marginTop: '-20px', marginBottom: '20px' }}
          >
            Filter by Area:
            <FormControl
              sx={{
                minWidth: 200,
                height: '40px',
                borderRadius: '30px',
                border: '3px solid #019df2',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                '& .MuiInputBase-root': {
                  height: '100%',
                  borderRadius: '30px',
                  padding: '0 12px',
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              <Select
                labelId="area-filter-label"
                value={selectedArea}
                onChange={(e: any) => handleAreaChange(e)}
                label=""
                sx={{
                  borderRadius: '20px',
                  '& .MuiSelect-select': {
                    padding: '8px 12px',
                  },
                }}
              >
                <MenuItem value="All areas">All Areas</MenuItem>
                {EXPERT_SESSION_REQUEST_AREAS.filter((dt) => !dt.disabled).map((area) => (
                  <MenuItem
                    key={area.value}
                    value={area.value}
                    disabled={area.disabled}
                    sx={{
                      borderRadius: '10px',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                    }}
                  >
                    {area.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: '#505050', borderWidth: '1px' }}
            />
            <FormControlLabel
              className={styles.showHideCheckbox}
              control={
                <Checkbox
                  checked={showCompleted}
                  onChange={handleCheckboxChange}
                  icon={<RadioButtonUncheckedRoundedIcon />}
                  checkedIcon={<CheckCircleOutlineRoundedIcon />}
                  sx={{
                    color: '#019df2',
                    '&.Mui-checked': {
                      color: '#04bc00',
                    },
                  }}
                />
              }
              label={!showCompleted ? 'Show Completed' : 'Show Pending'}
              sx={{
                '.MuiFormControlLabel-label': {
                  fontWeight: 600,
                },
              }}
            />
          </Box>
        </div>

        {historyDataLoading ? (
          <div className={styles.tableSkeleton}>
            {Array.from({ length: 15 }).map((_, index) => (
              <Skeleton variant="text" width="100%" height={30} key={index} />
            ))}
          </div>
        ) : (
          <DataTable
            data={filteredData}
            columns={columns}
            onRowClick={handleRowClick}
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        )}
      </div>

      {openExperts.open &&
        ReactDOM.createPortal(
          <Modal
            open={openExperts.open}
            setOpen={() => {
              setOpenExperts({ open: false, requestId: 0, rowDetails: null });
              getHistory();
            }}
            className={styles.modalRoot}
            overlay
          >
            <div className={styles.btnWrapper}>
              <div className={styles.btnClose}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenExperts({ open: false, requestId: 0, rowDetails: null });
                    getHistory();
                  }}
                >
                  Close&nbsp;
                  <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
                </button>
              </div>
            </div>
            <BookingAllExperts requestId={openExperts.requestId} />
          </Modal>,
          document.body,
        )}
    </MainLayout>
  );
};

export default BookingRequests;
