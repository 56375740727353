import { addBookingForRequest, getExpertBookingOptions } from '@api/experts';
import AudioIcon from '@assets/images/icons/audio-only.svg';
import CalendarIcon from '@assets/images/icons/calendar-check-icon.svg';
import CheckedTick from '@assets/images/icons/checked-tick.svg';
import ClockIcon from '@assets/images/icons/clock-icon.svg';
import InstantMessagingIcon from '@assets/images/icons/instant-messaging.svg';
import DefaultIcon from '@assets/images/icons/phone-call-dark.svg';
import RotateIcon from '@assets/images/icons/rotate-icon.svg';
import UserIcon from '@assets/images/icons/user-icon.svg';
import VideoIcon from '@assets/images/icons/video-only.svg';
import WarningIcon from '@assets/images/icons/warning-icon.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import Modal from '@components/Atoms/Modal';
import { smoothScrollTo } from '@helpers/general.helpers';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useWindowDimensions from '@hooks/useWindowDimensions';
import {
  ExpertBooking,
  ExpertBookingOptionsData,
  ExpertBookingRequestDetailsInterface,
  ExpertInterface,
  ExpertSessionDurationOptionsInterface,
  ExpertSessionTypeOptionsInterface,
} from '@interfaces/index';
import { CircularProgress, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { castResponse } from 'custom.d';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { expertActions } from 'store/Slices/ExpertSlice';
import styles from './index.module.scss';

const ConfirmBookingRequest: React.FunctionComponent<ConfirmBookingProps> = ({
  handleBookingEnabling,
  bookingData,
  timezoneDetails,
  expertDetails,
  handleFetchAvailability,
  requestDetails,
}) => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useWindowDimensions();
  const [isBookingSuccess, setIsBookingSuccess] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [continueBooking, setContinueBooking] = useState(false);
  const [expertData, setExpertData] = useState<ExpertBookingOptionsData | null>(null);
  const [selectedSessionDuration, setSelectedSessionDuration] =
    useState<ExpertSessionDurationOptionsInterface | null>(null);
  const [sessionLoading, setSessionLoading] = useState(true);
  const [selectedSessionType, setSelectedSessionType] =
    useState<ExpertSessionTypeOptionsInterface | null>(null);
  const [sessionDetails, setSessionDetails] = useState<ExpertBooking | null>(null);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [lastSessionDurationErrorMsgEnabled, setLastSessionDurationErrorMsgEnabled] =
    useState(false);
  const SESSION_DURATION_INITIAL_VALUE = '25';
  const [termsOpen, setTermsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (expertDetails?.id) {
      getOptions();
    }
  }, [expertDetails.id]);

  const getOptions = async () => {
    setSessionLoading(true);
    const response = await getExpertBookingOptions(expertDetails.id);
    if (response?.success) {
      const data = castResponse<ExpertBookingOptionsData>(response);
      if (
        data?.expertSessionDurations?.length > 0 &&
        data?.expertSessionDurations?.filter(
          (duration: { sessionDurationName: string }) =>
            duration?.sessionDurationName !== SESSION_DURATION_INITIAL_VALUE,
        )
      ) {
        setLastSessionDurationErrorMsgEnabled(true);
      }

      data.expertSessionDurations = data.expertSessionDurations
        .filter((duration: { sessionDurationName: string }) => {
          if (bookingData.timeDifference === 0) {
            return duration.sessionDurationName === SESSION_DURATION_INITIAL_VALUE;
          }

          return Number(duration.sessionDurationName) <= bookingData.timeDifference;
        })
        .sort(
          (a: { sessionDurationName: string }, b: { sessionDurationName: string }) =>
            Number(a.sessionDurationName) - Number(b.sessionDurationName),
        );

      data.expertSessionTypes = data.expertSessionTypes.sort(
        (a: { sessionType: any }, b: { sessionType: any }) =>
          Number(a.sessionType) - Number(b.sessionType),
      );

      setSelectedSessionDuration(
        data?.expertSessionDurations?.find((dt: { default: boolean }) => dt.default) ||
          data?.expertSessionDurations[0],
      );
      setSelectedSessionType(data.expertSessionTypes[0]);
      setExpertData(data);
      setSessionLoading(false);
    } else {
      setSelectedSessionDuration(null);
      setSelectedSessionType(null);
      setExpertData(null);
      setSessionLoading(false);
    }
  };

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);

    const options: any = { weekday: 'short', day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  };

  function convertToUTC(
    dateObj: { date: string | null; time: string | null },
    timezoneOffset: string,
  ) {
    const { date, time } = dateObj;

    if (!date || !time) {
      throw new Error('Date and time must be provided');
    }

    const localDateTimeString = `${date}T${time}:00`;

    const localDateTime = moment(localDateTimeString).utcOffset(timezoneOffset, true);

    const utcDateTime = localDateTime.utc();

    return utcDateTime.format('YYYY-MM-DDTHH:mm:ss');
  }

  const confirmBooking = async () => {
    try {
      if (selectedSessionType && selectedSessionDuration) {
        setBookingLoading(true);
        const req: any = await addBookingForRequest(
          requestDetails.userId,
          requestDetails.id,
          convertToUTC(bookingData, timezoneDetails?.offSet),
          expertDetails.id,
          selectedSessionType.sessionType,
          selectedSessionDuration?.sessionDuration,
          timezoneDetails.timeZone,
        );

        if (req?.success) {
          const data = castResponse<ExpertBooking>(req);
          await setSessionDetails(data);
          await setIsBookingSuccess(true);
          await toast.success('Booking successful. Please check your email for more information.');
        } else {
          toast.error(req?.responseObject[0]?.message || 'Booking failed. Please try again.');
          setErrorMsg(req?.responseObject[0]?.message || 'Booking failed. Please try again.');
        }
      } else {
        setIsBookingSuccess(false);
        toast.error('Error occurred. Try again later or contact admin.');
        setErrorMsg('Error occurred. Try again later or contact admin.');
      }
    } catch (e) {
      setIsBookingSuccess(false);
      toast.error('Error occurred. Try again later or contact admin.');
      setErrorMsg('Error occurred. Try again later or contact admin.');
    } finally {
      setBookingLoading(false);
      dispatch(expertActions.updateRefetchSessionsState(true));
    }
  };

  const handleSelectSessionDuration = (sessionDue: ExpertSessionDurationOptionsInterface) => {
    setSelectedSessionDuration(sessionDue);
  };

  const handleSelectSessionType = (sessionDue: ExpertSessionTypeOptionsInterface) => {
    setSelectedSessionType(sessionDue);
  };

  const getIconForType = (typeName: string | undefined | null) => {
    switch (typeName?.toLowerCase()) {
      case 'video':
        return VideoIcon;
      case 'audio':
        return AudioIcon;
      case 'instant messaging':
      case 'im':
        return InstantMessagingIcon;
      default:
        return DefaultIcon;
    }
  };

  const addMinutes = (date: string | null, time: string | null, minutesToAdd: number): string => {
    if (!date || !time) {
      return '00:00';
    }

    return new Date(new Date(`${date}T${time}:00Z`).getTime() + minutesToAdd * 60000)
      ?.toISOString()
      ?.substr(11, 5);
  };

  const newEndTime =
    bookingData?.date && bookingData?.time && selectedSessionDuration?.sessionDurationName
      ? addMinutes(
          bookingData?.date,
          bookingData?.time,
          Number(selectedSessionDuration?.sessionDurationName),
        )
      : '00:00';

  useEffect(() => {
    if (termsOpen) {
      const popupContainer = document.querySelector(`.${styles.modalRoot}`);
      if (popupContainer) {
        popupContainer.scrollTop = 0;
      }
    }
  }, [termsOpen]);

  return (
    <>
      {expertData?.expertSessionTypes?.length === 0 ||
      expertData?.expertSessionDurations?.length === 0 ? (
        <>
          {' '}
          <div className={styles.bookingUnavailableSection}>
            <p>Select your desired session date and time to book this expert.</p>
            <div className={styles.bookingUnavailableSectionContent}>
              <Img src={WarningIcon} alt="Warning icon" className={styles.warningIcon} />
              <div className={styles.content}>
                {lastSessionDurationErrorMsgEnabled ? (
                  <>
                    <p>This time slot is not available for the session duration.</p>
                    <p>Please select a different time slot. </p>
                  </>
                ) : (
                  <>
                    <p>This expert is currently not available.</p>
                    <p>Please try later or select a different expert.</p>
                  </>
                )}

                <DefaultButton
                  variant="secondary"
                  onClick={() => {
                    handleBookingEnabling({
                      enabled: false,
                      date: null,
                      time: null,
                      timeDifference: 0,
                    });
                    setLastSessionDurationErrorMsgEnabled(false);
                  }}
                >
                  Back
                </DefaultButton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.bookingContainer}>
          <div className={styles.card}>
            {!continueBooking ? (
              <>
                <h5>Your booking</h5>

                {isBookingSuccess && (
                  <div className={styles.checkBoxWrapper}>
                    {' '}
                    <svg
                      className={styles.checkmark}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      {' '}
                      <circle
                        className={styles.checkmarkCircle}
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />{' '}
                      <path
                        className={styles.checkmarkCheck}
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                )}

                <div className={styles.bookingInfoSection}>
                  <div className={styles.bookingInfo}>
                    <Img src={UserIcon} alt="user-icon" className={styles.icon} />
                    <p>{`${expertDetails.firstName} ${expertDetails.lastName}`}</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className={styles.bookingInfo}>
                    <Img src={CalendarIcon} alt="calendar-icon" className={styles.icon} />
                    <p>{bookingData.date && formatDate(bookingData.date)}</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className={styles.bookingInfo}>
                    <Img src={ClockIcon} alt="clock-icon" className={styles.icon} />
                    <p>{`${bookingData.time} (${timezoneDetails.timeZone})`}</p>
                    <p>&nbsp;</p>
                  </div>
                </div>

                <div className={styles.sessionDurationsSelection}>
                  <h5 className={styles.sessionDurationTopic}>Select session duration</h5>
                  <div className={styles.bookingSessionDurations}>
                    {sessionLoading ? (
                      <>
                        <Skeleton width="100px" height="100px" variant="rounded" />
                        <Skeleton width="100px" height="100px" variant="rounded" />
                        <Skeleton width="100px" height="100px" variant="rounded" />
                      </>
                    ) : (
                      <>
                        {expertData &&
                          expertData?.expertSessionDurations.map((sessionDue, index) => (
                            <div
                              className={`${styles.bookingSessionDuration} ${
                                selectedSessionDuration &&
                                selectedSessionDuration.sessionDuration ===
                                  sessionDue.sessionDuration
                                  ? styles.selected
                                  : ''
                              } `}
                              key={index}
                              onClick={() => handleSelectSessionDuration(sessionDue)}
                            >
                              <div>
                                <p>{sessionDue.sessionDurationName}</p>
                                <p>minutes</p>
                              </div>

                              {selectedSessionDuration?.sessionDuration ===
                              sessionDue.sessionDuration ? (
                                <Img
                                  src={CheckedTick}
                                  alt={`${sessionDue?.sessionDurationName} checked`}
                                  className={styles.checkTick}
                                />
                              ) : (
                                <div className={styles.checkTickNullImage} />
                              )}
                            </div>
                          ))}
                      </>
                    )}
                  </div>

                  <div className={styles.bookingSessionTypesInfo}>
                    {sessionLoading ? (
                      <div className={styles.sessionLoading}>
                        <Skeleton width="100%" height="20px" variant="text" />
                        <Skeleton width="100%" height="20px" variant="text" />
                      </div>
                    ) : (
                      <>
                        {selectedSessionDuration?.sessionDurationName ===
                        SESSION_DURATION_INITIAL_VALUE ? (
                          <>
                            <p>
                              {selectedSessionDuration?.sessionDurationName} minutes session is
                              recommended for initial consultations and check-ups.
                            </p>
                            <p className={styles.creditsInfo}>
                              This will consume {selectedSessionDuration?.sessionDurationCost} of
                              user&apos;s remaining {expertData?.usersRemainingCredits} credits.
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              {selectedSessionDuration?.sessionDurationName || 0} minutes session is
                              recommended for a more in-depth session.
                            </p>
                            <p className={styles.creditsInfo}>
                              This will consume {selectedSessionDuration?.sessionDurationCost || 0}{' '}
                              of user&apos;s remaining {expertData?.usersRemainingCredits || 0}{' '}
                              credits.
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className={styles.sessionTypesSection}>
                  <h5 className={styles.sessionTypeTopic}>Select session type</h5>

                  <div className={styles.bookingSessionTypes}>
                    {sessionLoading ? (
                      <>
                        <Skeleton width="100px" height="100px" variant="rounded" />
                        <Skeleton width="100px" height="100px" variant="rounded" />
                        <Skeleton width="100px" height="100px" variant="rounded" />
                      </>
                    ) : (
                      <>
                        {expertData?.expertSessionTypes.map((sessionType, index) => (
                          <div
                            className={`${styles.bookingSessionType} ${
                              selectedSessionType?.sessionType === sessionType.sessionType
                                ? styles.selected
                                : ''
                            } `}
                            key={index}
                            onClick={() => handleSelectSessionType(sessionType)}
                          >
                            <div className={styles.typeIconWrapper}>
                              <Img
                                className={styles.typeIcon}
                                src={getIconForType(sessionType.sessionTypeName)}
                                alt={sessionType.sessionTypeName}
                              />
                            </div>

                            <p>{sessionType.sessionTypeName}</p>

                            {selectedSessionType &&
                            selectedSessionType.sessionType === sessionType.sessionType ? (
                              <Img
                                src={CheckedTick}
                                alt={`${sessionType.sessionTypeName} checked`}
                                className={styles.checkTick}
                              />
                            ) : (
                              <div className={styles.checkTickNullImage} />
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.btnContainer}>
                  {isBookingSuccess ? (
                    <div className={styles.btns}>
                      <DefaultButton
                        variant="secondary"
                        onClick={() => {
                          handleBookingEnabling({
                            enabled: false,
                            date: null,
                            time: null,
                            timeDifference: 0,
                          });
                          setLastSessionDurationErrorMsgEnabled(false);
                          smoothScrollTo(0, 150);
                        }}
                      >
                        Go back
                      </DefaultButton>{' '}
                    </div>
                  ) : (
                    <div className={styles.btns}>
                      <DefaultButton
                        onClick={() => {
                          if (isDesktop) {
                            smoothScrollTo(1200, 150);
                          } else {
                            smoothScrollTo(2000, 150);
                          }

                          setContinueBooking(true);
                        }}
                        disabled={
                          expertData?.expertSessionTypes?.length === 0 ||
                          expertData?.expertSessionDurations?.length === 0 ||
                          selectedSessionDuration === null ||
                          selectedSessionType === null
                        }
                      >
                        Continue
                      </DefaultButton>

                      <DefaultButton
                        variant="secondary"
                        onClick={() => {
                          handleBookingEnabling({
                            enabled: false,
                            date: null,
                            time: null,
                            timeDifference: 0,
                          });
                          setLastSessionDurationErrorMsgEnabled(false);
                        }}
                      >
                        Cancel
                      </DefaultButton>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <h5>{isBookingSuccess ? 'Booking is completed' : 'Confirm booking'}</h5>

                {isBookingSuccess && (
                  <div className={styles.checkBoxWrapper}>
                    {' '}
                    <svg
                      className={styles.checkmark}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      {' '}
                      <circle
                        className={styles.checkmarkCircle}
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />{' '}
                      <path
                        className={styles.checkmarkCheck}
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                )}

                <div className={styles.bookingInfoSection}>
                  <div className={styles.bookingInfo}>
                    <Img src={UserIcon} alt="user-icon" className={styles.icon} />
                    <p>{`${expertDetails.firstName} ${expertDetails.lastName}`}</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className={styles.bookingInfo}>
                    <Img src={CalendarIcon} alt="calendar-icon" className={styles.icon} />
                    <p>{bookingData.date && formatDate(bookingData.date)}</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className={styles.bookingInfo}>
                    <Img src={ClockIcon} alt="clock-icon" className={styles.icon} />
                    <p>{`${bookingData.time} (${timezoneDetails.timeZone})`}</p>
                    <p>&nbsp;</p>
                  </div>

                  <div className={styles.bookingInfo}>
                    <Img src={RotateIcon} alt="rotate-icon" className={styles.icon} />
                    <p>
                      {selectedSessionDuration && selectedSessionDuration?.sessionDurationName}{' '}
                      Minutes
                    </p>
                    <p>&nbsp;</p>
                  </div>

                  {selectedSessionType && (
                    <div className={styles.bookingInfo}>
                      <Img
                        src={getIconForType(selectedSessionType?.sessionTypeName)}
                        alt="rotate-icon"
                        className={styles.icon}
                      />
                      <p>{selectedSessionType?.sessionTypeName || '-'}</p>
                      <p>&nbsp;</p>
                    </div>
                  )}
                </div>

                {isBookingSuccess && (
                  <>
                    <p className={styles.successMessage}>
                      The user can join the session through the link available on their Hapstar
                      dashboard under the Experts section. They will also receive an email with the
                      session link for easy access.
                    </p>
                    <DefaultButton
                      variant="secondary"
                      onClick={() => window.location.reload()}
                      disabled={bookingLoading}
                    >
                      Back to management portal
                    </DefaultButton>
                  </>
                )}

                {!isBookingSuccess && (
                  <div className={styles.btnContainer}>
                    <p className={styles.disclaimerText}>
                      We partner with Mindberry to facilitate access to third-party mental health
                      professionals.
                    </p>

                    <div className={styles.policiesContainer}>
                      <p>
                        Your session is secured using credits provided by your organisation.{' '}
                        <span>
                          Your credit will not be refunded for cancellations made less than 48 hours
                          before your session.
                        </span>{' '}
                        This policy helps ensure our experts remain available for all users who need
                        support.
                      </p>
                    </div>

                    <p className={styles.disclaimerTextCompleteBooking}>
                      By clicking <span>&apos;Complete Booking&apos;</span> you agree to{' '}
                      <span
                        className={styles.terms}
                        onClick={() => {
                          setTermsOpen(true);
                        }}
                      >
                        the terms and conditions of use.
                      </span>
                    </p>

                    {errorMsg && errorMsg?.length > 0 && (
                      <div className={styles.errorMessage}>
                        <span>Error: {errorMsg}</span>
                      </div>
                    )}

                    {!isBookingSuccess && (
                      <div className={styles.btns}>
                        <DefaultButton
                          onClick={confirmBooking}
                          disabled={
                            expertData?.expertSessionTypes?.length === 0 ||
                            expertData?.expertSessionDurations?.length === 0 ||
                            selectedSessionDuration === null ||
                            selectedSessionType === null ||
                            bookingLoading
                          }
                        >
                          {bookingLoading ? (
                            <>
                              {' '}
                              <CircularProgress
                                color="inherit"
                                size={20}
                                className={styles.loadingSpinner}
                              />
                              &nbsp;&nbsp;&nbsp;Connecting...
                            </>
                          ) : (
                            'Complete Booking'
                          )}
                        </DefaultButton>

                        <DefaultButton
                          variant="secondary"
                          onClick={() => setContinueBooking(false)}
                          disabled={bookingLoading}
                        >
                          Back
                        </DefaultButton>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {termsOpen &&
        ReactDOM.createPortal(
          <Modal open={termsOpen} setOpen={setTermsOpen} className={styles.modalRoot} overlay>
            <div className={styles.btnWrapper}>
              <div className={styles.btnClose}>
                <button type="button" onClick={() => setTermsOpen(false)}>
                  Close&nbsp;
                  <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
                </button>
              </div>
            </div>

            <div className={styles.modalContent}>
              <h5>Terms and Conditions of Use</h5>
              <hr />

              <div>
                <p className={styles.sectionTitle}>Therapist Services</p>
                <p>
                  Hapstar operates as a software platform, partnering with Mindberry to facilitate
                  access to third-party mental health professionals (&quot;Therapist Service
                  Providers&quot;) via our &quot;Therapist Services&quot; module for virtual 1-2-1
                  confidential sessions. Hapstar does not directly provide Therapist Services. When
                  you book a confidential appointment, you are entering into an agreement with the
                  selected Therapist Service Provider, not Hapstar. Available professionals may
                  include therapists, counsellors, coaches, psychologists, social workers, and
                  similar licensed specialists.
                </p>

                <p className={styles.sectionTitle}>Consent to Share Information</p>
                <p>
                  By accessing Therapist Services, you consent to share your name and email with
                  Mindberry and the relevant Therapist Service Provider.
                </p>

                <p className={styles.sectionTitle}>Terms of Use</p>
                <p>
                  Your use of Therapist Services signifies your acceptance of these terms. If you
                  disagree, you are not authorised to use these services. Full terms are available
                  here:
                  <a
                    href="https://www.mind-berry.com/terms-of-use"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    Mindberry Terms of Use.
                  </a>
                </p>

                <p className={styles.sectionTitle}>Responsibilities and Limitations</p>
                <p>
                  Your wellbeing is your responsibility. Therapist Service Providers are vetted by
                  Mindberry, which verifies their credentials, though it is recommended you confirm
                  your Therapist&apos;s current standing with their professional body.
                </p>

                <p className={styles.sectionTitle}>Disclaimer of Liability</p>
                <p>
                  Hapstar is not liable for services provided by Therapist Service Providers or
                  Mindberry. You agree that Hapstar is not responsible for any action of any of Your
                  Users as a result of their use of Therapist Services. You agree to hold Hapstar
                  harmless from any and all causes of action and claims of any nature resulting from
                  Your Users&apos; use of the Therapist Services, including (without limitation) any
                  act, omission, opinion, response, advice, suggestion, information and/or service
                  of any Therapist Service Provider.
                </p>

                <p className={styles.sectionTitle}>Emergency Services</p>
                <p>Therapist Services are not for emergencies.</p>

                <div className={styles.highlight}>
                  <p>
                    IF YOU ARE THINKING ABOUT SUICIDE OR IF YOU ARE CONSIDERING HARMING YOURSELF OR
                    OTHERS OR IF YOU FEEL THAT ANY OTHER PERSON MAY BE IN ANY DANGER OR IF YOU HAVE
                    ANY MEDICAL EMERGENCY, YOU MUST IMMEDIATELY CALL THE EMERGENCY SERVICES AND
                    NOTIFY THE RELEVANT AUTHORITIES. SEEK IMMEDIATE IN-PERSON ASSISTANCE.
                  </p>
                </div>

                <div className={styles.highlight}>
                  <p>
                    DO NOT USE THE PLATFORM OR THERAPIST SERVICES FOR ANY OF THE AFOREMENTIONED
                    CASES. THE THERAPISTS CANNOT PROVIDE THE ASSISTANCE REQUIRED IN ANY OF THE
                    AFOREMENTIONED CASES.
                  </p>
                </div>

                <p className={styles.sectionTitle}>Medical Advice Limitations</p>
                <p>
                  The Platform is not a substitute for in-person care, clinical diagnoses, or
                  treatment planning. Seek direct professional assistance for medical or clinical
                  needs.
                </p>
              </div>
            </div>
          </Modal>,
          document.body,
        )}
    </>
  );
};

interface ConfirmBookingProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  handleBookingEnabling: (arg0: {
    enabled: boolean;
    date: string | null;
    time: string | null;
    timeDifference: number;
  }) => void;
  bookingData: {
    enabled: boolean;
    date: string | null;
    time: string | null;
    timeDifference: number;
  };
  timezoneDetails: {
    id: number;
    name: string;
    timeZone: string;
    offSet: string;
  };
  expertDetails: ExpertInterface;
  handleFetchAvailability: () => void;
  requestDetails: ExpertBookingRequestDetailsInterface;
}

export default ConfirmBookingRequest;
