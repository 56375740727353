export const emailValidation = (value: string) => {
  const emailValidationRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // eslint-disable-line

  return emailValidationRegex.test(value);
};

export const isEmptyValidation = (value: string) => value.trim() !== '';

export const isNullValidation = (value: any) => value !== null;

export const noValidation = (value: any) => true;
