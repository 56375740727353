import blueTray from '@assets/images/icons/blueTray.svg';
import HelpIcon from '@assets/images/icons/help-circle.svg';
import IndicatorCheck from '@assets/images/icons/indicator-check-red.svg';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import PopUpModal from '@components/PopUpModal';
import { smoothScrollTo } from '@helpers/general.helpers';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { UrgencyIndexInterface } from '@interfaces/index';
import { Skeleton, Tooltip } from '@mui/material';
import { InitInfoDialog, URGENCY_INDEX_INFO } from 'custom.d';
import React, { useState } from 'react';
import styles from './index.module.scss';

const InventionSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, loading } = props;
  const { isDesktop } = useWindowDimensions();
  const [infoDialog, setInfoDialog] = useState<{
    open: boolean;
    top: string;
    right: string;
    firstDescription: string;
    description: string;
    title: string;
    htmlDescription: any;
  }>(InitInfoDialog);

  const areAllUrgencyIndexDataNull =
    data && data?.urgencyIndexData
      ? Object.values(data.urgencyIndexData).every((value) => value === null)
      : false;

  return (
    <Card className={styles.inventoryCard}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <div className={styles.top}>
            <Skeleton variant="rounded" height={30} width="40%" />
            <Skeleton variant="rounded" height={40} width="100%" />
          </div>

          <div className={styles.body}>
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
            <Skeleton variant="rounded" height={20} width="50%" />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.topHeader}>
            <h4 className={styles.cardTitle}>
              Intervention Urgency Index: {data?.totalIndex || 0}/7
            </h4>
            <Tooltip
              title="Intervention Urgency Index - Click for more details"
              followCursor
              onClick={() => {
                if (!isDesktop) {
                  smoothScrollTo(0, 0);
                }

                setInfoDialog({
                  open: true,
                  top: '150px',
                  right: '30%',
                  firstDescription: '',
                  description: '',
                  htmlDescription: URGENCY_INDEX_INFO,
                  title: 'Intervention Urgency Index',
                });
              }}
            >
              <img src={HelpIcon} alt="help" className={styles.helpIcon} />
            </Tooltip>
          </div>

          <div className={styles.barContainer}>
            <div className={styles.bar}>
              {[...Array(7)].map((_, idx) => (
                <div key={idx} className={`${styles.segment} ${styles[`segment${idx + 1}`]}`} />
              ))}
            </div>
            {data && data?.totalIndex > 0 && (
              <div
                className={styles.indicator}
                style={{
                  left: `${((data?.totalIndex || 1) - 1) * (100 / 7) + 50 / 7}%`,
                }}
              >
                <div className={styles.arrow} />
                <span className={styles.value}>{data?.totalIndex || 0}</span>
              </div>
            )}
          </div>

          {!loading && !data?.urgencyIndexData ? (
            <div className={styles.noDataSection}>
              <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
              <p>No urgency index data found.</p>
            </div>
          ) : (
            <div className={styles.indicatorContainer}>
              {areAllUrgencyIndexDataNull ? (
                <div className={styles.noDataSection}>
                  <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                  <p>No urgency index data found.</p>
                </div>
              ) : (
                <h5>Indicators present:</h5>
              )}

              <div className={styles.indicators}>
                {/* ********************** belowThresholdScore STARTS HERE ********************** */}
                {data?.urgencyIndexData.belowThresholdScore && (
                  <div className={styles.indicator}>
                    <Img
                      src={IndicatorCheck}
                      alt="indicator-check"
                      className={styles.indicatorIcon}
                    />

                    {data?.urgencyIndexData?.belowThresholdScore &&
                      data?.urgencyIndexData?.belowThresholdScore?.score && (
                        <p>
                          <span className={styles.inventionTitle}>
                            Below threshold score
                            {`${
                              data.urgencyIndexData.belowThresholdScore &&
                              data.urgencyIndexData.belowThresholdScore?.data
                                ? ': '
                                : ''
                            }`}
                          </span>
                          {data.urgencyIndexData.belowThresholdScore?.data &&
                            data.urgencyIndexData.belowThresholdScore.data
                              .filter(
                                (dt, index, array) =>
                                  // Filter duplicates based on the questionText
                                  array.findIndex(
                                    (item) =>
                                      item.question?.questionText === dt.question?.questionText,
                                  ) === index,
                              )
                              .slice(0, 5)
                              .map((dt, index, array) => (
                                <span key={dt.id}>
                                  {dt.question.questionText}
                                  {index < array.length - 1 && ', '}
                                </span>
                              ))}
                        </p>
                      )}
                  </div>
                )}
                {/* ********************** belowThresholdScore ENDS HERE ********************** */}

                {/* ********************** focusAreaSelected STARTS HERE ********************** */}
                {data?.urgencyIndexData?.focusAreaSelected &&
                  data?.urgencyIndexData?.focusAreaSelected?.score && (
                    <div className={styles.indicator}>
                      <Img
                        src={IndicatorCheck}
                        alt="indicator-check"
                        className={styles.indicatorIcon}
                      />

                      {data?.urgencyIndexData?.focusAreaSelected &&
                        data?.urgencyIndexData?.focusAreaSelected?.score && (
                          <p>
                            <span className={styles.inventionTitle}>
                              Focus area selected
                              {`${
                                data.urgencyIndexData.focusAreaSelected &&
                                data.urgencyIndexData.focusAreaSelected?.data
                                  ? ': '
                                  : ''
                              }`}
                            </span>
                            {data.urgencyIndexData.focusAreaSelected?.data &&
                              data.urgencyIndexData.focusAreaSelected.data
                                .filter(
                                  (dt, index, array) =>
                                    array.findIndex(
                                      (item) =>
                                        item.question?.questionText === dt.question?.questionText,
                                    ) === index,
                                )
                                .slice(0, 5)
                                .map((dt, index, array) => (
                                  <span key={dt.id}>
                                    {dt?.question?.questionText || ''}
                                    {index < array.length - 1 && ', '}
                                  </span>
                                ))}
                          </p>
                        )}
                    </div>
                  )}
                {/* ********************** focusAreaSelected ENDS HERE ********************** */}

                {/* ********************** wantedToTalkToAdvisor STARTS HERE ********************** */}
                {data?.urgencyIndexData?.wantedToTalkToAdvisor &&
                  data?.urgencyIndexData?.wantedToTalkToAdvisor?.score && (
                    <div className={styles.indicator}>
                      <Img
                        src={IndicatorCheck}
                        alt="indicator-check"
                        className={styles.indicatorIcon}
                      />

                      {data?.urgencyIndexData?.wantedToTalkToAdvisor &&
                        data?.urgencyIndexData?.wantedToTalkToAdvisor?.score && (
                          <p>
                            <span className={styles.inventionTitle}>
                              Wanted to talk to an expert
                              {`${
                                data.urgencyIndexData.wantedToTalkToAdvisor &&
                                data.urgencyIndexData.wantedToTalkToAdvisor?.data
                                  ? ': '
                                  : ''
                              }`}
                            </span>
                            {data.urgencyIndexData.wantedToTalkToAdvisor?.data &&
                              data.urgencyIndexData.wantedToTalkToAdvisor.data
                                .filter(
                                  (dt, index, array) =>
                                    // Filter duplicates based on the questionText
                                    array.findIndex(
                                      (item) =>
                                        item.question?.questionText === dt.question?.questionText,
                                    ) === index,
                                )
                                .slice(0, 5)
                                .map((dt, index, array) => (
                                  <span key={dt.id}>
                                    {dt?.question?.questionText || ''}
                                    {index < array.length - 1 && ', '}
                                  </span>
                                ))}
                          </p>
                        )}
                    </div>
                  )}
                {/* ********************** wantedToTalkToAdvisor ENDS HERE ********************** */}

                {/* ********************** usedSpeakUpOrSharedThoughtsWithOrganisation STARTS HERE ********************** */}
                {data?.urgencyIndexData?.usedSpeakUpOrSharedThoughtsWithOrganisation &&
                  data?.urgencyIndexData?.usedSpeakUpOrSharedThoughtsWithOrganisation?.score && (
                    <div className={styles.indicator}>
                      <Img
                        src={IndicatorCheck}
                        alt="indicator-check"
                        className={styles.indicatorIcon}
                      />

                      {data?.urgencyIndexData?.usedSpeakUpOrSharedThoughtsWithOrganisation &&
                        data?.urgencyIndexData?.usedSpeakUpOrSharedThoughtsWithOrganisation
                          ?.score && (
                          <p>
                            <span className={styles.inventionTitle}>
                              Used speak up or shared thoughts with advisor
                            </span>
                          </p>
                        )}
                    </div>
                  )}
                {/* ********************** usedSpeakUpOrSharedThoughtsWithOrganisation ENDS HERE ********************** */}

                {/* ********************** clickedAnEmergencyHelpSource STARTS HERE ********************** */}
                {data?.urgencyIndexData?.clickedAnEmergencyHelpSource &&
                  data?.urgencyIndexData?.clickedAnEmergencyHelpSource?.score && (
                    <div className={styles.indicator}>
                      <Img
                        src={IndicatorCheck}
                        alt="indicator-check"
                        className={styles.indicatorIcon}
                      />

                      {data?.urgencyIndexData?.clickedAnEmergencyHelpSource &&
                        data?.urgencyIndexData?.clickedAnEmergencyHelpSource?.score && (
                          <p>
                            <span className={styles.inventionTitle}>
                              Clicked an emergency help source
                              {`${
                                data.urgencyIndexData.clickedAnEmergencyHelpSource &&
                                data.urgencyIndexData.clickedAnEmergencyHelpSource?.data
                                  ? ': '
                                  : ''
                              }`}
                            </span>
                            {data.urgencyIndexData.clickedAnEmergencyHelpSource?.data &&
                              data.urgencyIndexData.clickedAnEmergencyHelpSource.data
                                .filter(
                                  (dt, index, array) =>
                                    array.findIndex((item) => item?.resource === dt?.resource) ===
                                    index,
                                )
                                .slice(0, 5)
                                .map((dt, index, array) => (
                                  <span key={index}>
                                    {dt?.resource || ''}
                                    {index < array.length - 1 && ', '}
                                  </span>
                                ))}
                          </p>
                        )}
                    </div>
                  )}
                {/* ********************** clickedAnEmergencyHelpSource ENDS HERE ********************** */}

                {/* ********************** lowScoreVsHapstarAverage STARTS HERE ********************** */}
                {data?.urgencyIndexData?.lowScoreVsHapstarAverage &&
                  data?.urgencyIndexData?.lowScoreVsHapstarAverage?.score && (
                    <div className={styles.indicator}>
                      <Img
                        src={IndicatorCheck}
                        alt="indicator-check"
                        className={styles.indicatorIcon}
                      />

                      {data?.urgencyIndexData?.lowScoreVsHapstarAverage &&
                        data?.urgencyIndexData?.lowScoreVsHapstarAverage?.score && (
                          <p>
                            <span className={styles.inventionTitle}>
                              Low score vs programme average
                              {`${
                                data.urgencyIndexData.lowScoreVsHapstarAverage &&
                                data.urgencyIndexData.lowScoreVsHapstarAverage?.data
                                  ? ': '
                                  : ''
                              }`}
                            </span>
                            {data.urgencyIndexData.lowScoreVsHapstarAverage?.data &&
                              data.urgencyIndexData.lowScoreVsHapstarAverage.data
                                .slice(0, 6)
                                .map((dt, index, array) => (
                                  <span key={dt.id}>
                                    {`${dt?.category} ${
                                      dt?.score ? dt?.score.toFixed(1) : 'N/A'
                                    } vs ${
                                      dt?.overallHapstarScore
                                        ? dt?.overallHapstarScore.toFixed(1)
                                        : 'N/A'
                                    }` || ''}
                                    {index < array.length - 1 && ', '}
                                  </span>
                                ))}
                          </p>
                        )}
                    </div>
                  )}
                {/* ********************** lowScoreVsHapstarAverage ENDS HERE ********************** */}

                {/* ********************** lowScoreVsOrganisationAverage STARTS HERE ********************** */}
                {data?.urgencyIndexData?.lowScoreVsOrganisationAverage &&
                  data?.urgencyIndexData?.lowScoreVsOrganisationAverage?.score && (
                    <div className={styles.indicator}>
                      <Img
                        src={IndicatorCheck}
                        alt="indicator-check"
                        className={styles.indicatorIcon}
                      />

                      {data?.urgencyIndexData?.lowScoreVsOrganisationAverage &&
                        data?.urgencyIndexData?.lowScoreVsOrganisationAverage?.score && (
                          <p>
                            <span className={styles.inventionTitle}>
                              Low score vs Hapstar average
                              {`${
                                data.urgencyIndexData.lowScoreVsOrganisationAverage &&
                                data.urgencyIndexData.lowScoreVsOrganisationAverage?.data
                                  ? ': '
                                  : ''
                              }`}
                            </span>
                            {data.urgencyIndexData.lowScoreVsOrganisationAverage?.data &&
                              data.urgencyIndexData.lowScoreVsOrganisationAverage.data
                                .slice(0, 6)
                                .map((dt, index, array) => (
                                  <span key={dt.id}>
                                    {`${dt?.category} ${
                                      dt?.score ? dt?.score?.toFixed(1) : 'N/A'
                                    } vs ${
                                      dt?.companyAvgScore ? dt?.companyAvgScore.toFixed(1) : 'N/A'
                                    }` || ''}
                                    {index < array.length - 1 && ', '}
                                  </span>
                                ))}
                          </p>
                        )}
                    </div>
                  )}
                {/* ********************** lowScoreVsOrganisationAverage ENDS HERE ********************** */}
              </div>
            </div>
          )}
        </>
      )}
      <PopUpModal
        open={infoDialog.open}
        setOpen={() => setInfoDialog(InitInfoDialog)}
        top={infoDialog.top}
        right={infoDialog.right}
        firstDescription={infoDialog.firstDescription}
        description={infoDialog.description}
        title={infoDialog.title}
        overlay
        htmlDescription={infoDialog.htmlDescription}
      />
    </Card>
  );
};

InventionSection.defaultProps = {
  loading: false,
};

interface SectionProps {
  data: UrgencyIndexInterface | null;
  loading?: boolean;
}

export default InventionSection;
