import { getIpAddress } from '@api/auth/login';
import { addExpertBookingRequest } from '@api/experts';
import { createLog } from '@api/log';
import row1ShapesImage from '@assets/images/whatIsHapstar/Row1.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import MainLayout from '@layouts/MainLayout';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup } from '@mui/material';
import {
  EXPERT_SESSION_REQUEST_AREAS,
  EXPERT_SESSION_REQUEST_PREFERRED_DAYS,
  EXPERT_SESSION_REQUEST_PREFERRED_TIMES,
  LogTypes,
} from 'custom.d';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const RequestExpertBooking: React.FunctionComponent = () => {
  const isMobileApp = useGoNative();
  const [area, setArea] = useState<string>('');
  const [preferredDays, setPreferredDays] = useState<string[]>([]);
  const [preferredTimes, setPreferredTimes] = useState<string[]>([]);
  const [userTimezone, setUserTimezone] = useState<string | null>(null);
  const [sent, setSent] = useState(false);
  const { isMobile } = useWindowDimensions();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    handleCreatePageAccessLog();
  }, []);

  useEffect(() => {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || null;
      setUserTimezone(timezone);
    } catch (err) {
      setUserTimezone(null);
    }
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      if (!area || preferredDays.length === 0 || preferredTimes.length === 0) {
        toast.error('Please fill in all mandatory fields.');

        return;
      }

      const requestData = {
        area,
        preferredDays: JSON.stringify(preferredDays),
        preferredTimes: JSON.stringify(preferredTimes),
        userTimezone,
      };

      const request = await addExpertBookingRequest(
        requestData.area,
        requestData.preferredDays,
        requestData.preferredTimes,
        requestData.userTimezone,
      );

      if (request?.success) {
        setSent(true);
        toast.success('Request submitted successfully! We will contact you ASAP.');
      } else {
        setSent(false);
        toast.error('Failed to submit request. Please try again later.');
      }
    } catch (error: any) {
      toast.error('Failed to submit request. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreatePageAccessLog = async () => {
    const ipAddress = await getIpAddress();
    const payload = {
      type: LogTypes.REQUEST_EXPERT_SESSION_PAGE_VISITED,
      ipAddress,
      os: navigator.userAgentData?.platform || '',
      isNative: isMobileApp,
    };

    await createLog(payload);
  };

  const handleCheckboxChange = (
    value: string,
    selectedItems: string[],
    setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  return (
    <MainLayout title="Request an Expert Session" secondaryNavEnabled>
      {sent ? (
        <div className="container">
          <div className={styles.successContainer}>
            <h2>Thank you for your request!</h2>
            <p>
              Thank you for your request to book a session with an expert. We have received your
              request and we will get back to you asap.
            </p>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className={styles.rowOneImage}>
            <img src={row1ShapesImage} alt="" style={{ height: isMobile ? 300 : undefined }} />
          </div>
          <div className={styles.header}>
            <h1>Request an Expert Session</h1>
            <p>
              Complete this quick form to request a session with one of our experts. We&apos;ll get
              back to you asap to confirm your appointment.
            </p>
          </div>

          <br />
          <div className={styles.hsContactUsPage}>
            <div
              className={styles.hsContactUsContainer}
              style={{
                width: isMobile ? '100%' : undefined,
                padding: isMobile ? '10px 20px 70px 20px' : undefined,
                marginBottom: '120px',
              }}
            >
              <form className={styles.requestForm}>
                {/* Area Selection */}
                <div>
                  <label htmlFor="area">
                    What area would you like help with?*
                    <select value={area} onChange={(e) => setArea(e.target.value)} required>
                      {EXPERT_SESSION_REQUEST_AREAS.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled || false}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>

                {/* Preferred Days */}
                <div className={styles.inputContainer}>
                  <label htmlFor="preferredDays">
                    Select your preferred days* (select all that apply):
                  </label>
                  <FormGroup className={styles.checkboxGrid}>
                    {EXPERT_SESSION_REQUEST_PREFERRED_DAYS.map((day) => (
                      <FormControlLabel
                        key={day}
                        control={
                          <Checkbox
                            checked={preferredDays.includes(day)}
                            onChange={() =>
                              handleCheckboxChange(day, preferredDays, setPreferredDays)
                            }
                            icon={<RadioButtonUncheckedRoundedIcon />}
                            checkedIcon={<CheckCircleOutlineRoundedIcon />}
                            sx={{
                              color: '#019df2',
                              '&.Mui-checked': {
                                color: '#04bc00',
                              },
                            }}
                          />
                        }
                        label={day}
                      />
                    ))}
                  </FormGroup>
                </div>

                {/* Preferred Times */}
                <div className={styles.inputContainer}>
                  <label htmlFor="preferredTimes">Preferred time* (select all that apply):</label>
                  <FormGroup className={styles.checkboxGrid}>
                    {EXPERT_SESSION_REQUEST_PREFERRED_TIMES.map((time) => (
                      <FormControlLabel
                        key={time}
                        control={
                          <Checkbox
                            checked={preferredTimes.includes(time)}
                            onChange={() =>
                              handleCheckboxChange(time, preferredTimes, setPreferredTimes)
                            }
                            icon={<RadioButtonUncheckedRoundedIcon />}
                            checkedIcon={<CheckCircleOutlineRoundedIcon />}
                            sx={{
                              color: '#019df2',
                              '&.Mui-checked': {
                                color: '#04bc00',
                              },
                            }}
                          />
                        }
                        label={time}
                      />
                    ))}
                  </FormGroup>
                </div>

                {/* Submit Button */}
                <div className={styles.submitButtonContainer}>
                  <DefaultButton
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={
                      !preferredDays.length ||
                      !preferredTimes.length ||
                      !area.length ||
                      isSubmitting
                    }
                  >
                    {isSubmitting ? (
                      <>
                        <CircularProgress size={24} color="inherit" /> &nbsp;Sending request...
                      </>
                    ) : (
                      'Submit Request'
                    )}
                  </DefaultButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default RequestExpertBooking;
