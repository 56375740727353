import { getExpertById } from '@api/experts';
import SkyBluePrevious from '@assets/images/icons/sky-blue-previous.svg';
import Img from '@components/Atoms/Img';
import ExpertBookingAvailability from '@components/Organisms/Experts/ExpertBookingAvailability';
import { ExpertInterface } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import { Grid, Skeleton } from '@mui/material';
import { sanitizeHtml } from '@utils/string.util';
import { castResponse } from 'custom.d';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styles from './index.module.scss';

const Expert: FunctionComponent = () => {
  const history = useHistory();
  const { expertId } = useParams<UrlParamsType>();
  const [expertDetails, setExpertDetails] = useState<ExpertInterface | null>(null);
  const [expertLoading, setExpertLoading] = useState<boolean>(true);

  useEffect(() => {
    if (expertId) {
      getExpertDetails();
    }
  }, [expertId]);

  const getExpertDetails = async () => {
    try {
      setExpertLoading(true);
      const response = await getExpertById(Number(expertId));
      if (response?.success) {
        const data = castResponse<ExpertInterface>(response);

        setExpertDetails(data);
      } else {
        setExpertDetails(null);
      }
    } catch {
      setExpertDetails(null);
    } finally {
      setExpertLoading(false);
    }
  };

  return (
    <MainLayout title="Expert" isEmployerPage secondaryNavEnabled>
      <div className={styles.mainContainer}>
        <div className={styles.topBox}>
          <div className="container">
            <div className={styles.content}>
              <h1 className={styles.titleText}>Experts</h1>
              <p className={styles.middleText}>
                We understand that taking charge of your wellbeing is a personal journey, and
                finding the right support makes all the difference. We have curated a diverse team
                of experts, each passionate about helping you thrive.
              </p>
              <p className={styles.middleText}>
                Browse profiles of our experts, each showcasing their areas of specialisation and
                approach. Find someone who resonates with your needs and preferences. When
                you&apos;re ready to take the next step, simply book your appointment directly
                through the platform.
              </p>
            </div>
          </div>
          <div className={styles.abstractImg} />
        </div>

        <div className="container">
          <div className={styles.navigateBack} onClick={() => history.push(`/experts`)}>
            <Img src={SkyBluePrevious} alt="HS Previous" className={styles.previous} />
            <h5>All experts</h5>
          </div>

          <div className={styles.insideContainer}>
            <div>
              <div className={styles.expertProfileMain}>
                {expertLoading ? (
                  <>
                    <div className={styles.loadingItem}>
                      <Skeleton variant="text" width={200} height={60} />
                    </div>
                    <div className={styles.loadingItem}>
                      <Skeleton variant="circular" width={120} height={120} />
                    </div>
                    <div className={styles.loadingItem}>
                      <Skeleton variant="text" width={220} height={30} />
                    </div>
                  </>
                ) : (
                  <>
                    <h4>{`${expertDetails?.firstName} ${expertDetails?.lastName}`}</h4>
                    <Img
                      src={`https://${expertDetails?.expertDetail?.profileImage}`}
                      alt="Expert Profile"
                      className={styles.expertProfileImg}
                    />
                    <p>{`${expertDetails?.expertDetail.experience} years experience`}</p>
                  </>
                )}
              </div>

              <div className={styles.expertProfileSub}>
                {expertDetails?.expertDetail?.fullDescription &&
                  expertDetails?.expertDetail?.fullDescription?.length > 0 && (
                    <div className={styles.expertProfileAbout}>
                      {expertLoading ? (
                        <Skeleton variant="text" width={100} height={60} />
                      ) : (
                        <h4>ABOUT ME</h4>
                      )}

                      {expertLoading ? (
                        <>
                          <Skeleton variant="text" width="100%" height={20} />
                          <Skeleton variant="text" width="100%" height={20} />
                          <Skeleton variant="text" width="100%" height={20} />
                          <Skeleton variant="text" width="100%" height={20} />
                        </>
                      ) : (
                        <p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(
                                expertDetails.expertDetail.fullDescription,
                              ).replace(/\n/g, '<br/>'),
                            }}
                          />
                        </p>
                      )}

                      <p className={styles.qualificationContainer}>
                        <b>Qualifications:</b>
                        <p
                          className={styles.qualification}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(expertDetails.expertDetail.qualification).replace(
                              /\n/g,
                              '<br/>',
                            ),
                          }}
                        />
                      </p>
                    </div>
                  )}

                {expertDetails?.expertsAllocatedTopics &&
                  expertDetails?.expertsAllocatedTopics?.length > 0 && (
                    <div className={styles.expertProfileAreas}>
                      {expertLoading ? (
                        <Skeleton variant="text" width={220} height={60} />
                      ) : (
                        <h4>AREAS OF EXPERTISE</h4>
                      )}

                      <Grid container spacing={0}>
                        {expertLoading
                          ? [0, 1, 2, 3, 4, 5].map((index) => (
                              <Grid item xs={12} md={4} key={index}>
                                <Skeleton variant="text" width={120} height={30} />
                              </Grid>
                            ))
                          : expertDetails.expertsAllocatedTopics.map((topic, index) => (
                              <Grid item xs={12} md={4} key={index}>
                                <p>{topic.expertsTopic.topic}</p>
                              </Grid>
                            ))}
                      </Grid>
                    </div>
                  )}

                {expertDetails?.expertsAllocatedLanguages &&
                  expertDetails?.expertsAllocatedLanguages.length > 0 && (
                    <div className={styles.expertProfileLanguages}>
                      {expertLoading ? (
                        <Skeleton variant="text" width={220} height={60} />
                      ) : (
                        <h4>LANGUAGES</h4>
                      )}

                      <Grid container spacing={0}>
                        {expertLoading
                          ? [0, 1, 2].map((index) => (
                              <Grid item xs={12} md={4} key={index}>
                                <Skeleton variant="text" width={120} height={30} />
                              </Grid>
                            ))
                          : expertDetails.expertsAllocatedLanguages.map((lng, index) => (
                              <Grid item xs={12} md={4} key={index}>
                                <p>{lng.expertsLanguage.language}</p>
                              </Grid>
                            ))}
                      </Grid>
                    </div>
                  )}
              </div>
            </div>

            {expertDetails && (
              <div className={styles.expertProfileAvailability}>
                <ExpertBookingAvailability expertDetails={expertDetails} />
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

type UrlParamsType = {
  expertId: string;
};

export default Expert;
