import blueTray from '@assets/images/icons/blueTray.svg';
import HelpIcon from '@assets/images/icons/help-circle.svg';
import HighTrendIcon from '@assets/images/icons/reporting/high-trend.svg';
import LowTrendIcon from '@assets/images/icons/reporting/low-trend-icon.svg';
import NeutralTrendIcon from '@assets/images/icons/reporting/neutral-trend-icon.svg';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import PopUpModal from '@components/PopUpModal';
import { smoothScrollTo } from '@helpers/general.helpers';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CheckInAndDeepDiveScoreInterface } from '@interfaces/index';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import { formatDateToDateMonthYear } from '@utils/date.util';
import { CHECK_IN_SCORES_INFO, DEEP_DIVE_SCORES_INFO, InitInfoDialog } from 'custom.d';
import React, { useState } from 'react';
import styles from './index.module.scss';

const ScoresSection: React.FunctionComponent<SectionProps> = (props) => {
  const {
    data,
    loading,
    checkInScores,
    checkInScoresLoading,
    deepDiveScores,
    deepDiveScoresLoading,
  } = props;
  const { isDesktop } = useWindowDimensions();
  const [infoDialog, setInfoDialog] = useState<{
    open: boolean;
    top: string;
    right: string;
    firstDescription: string;
    description: string;
    title: string;
    htmlDescription: any;
  }>(InitInfoDialog);

  const getScoreClass = (sc: number) => {
    if (sc >= 8) {
      return styles.highScore;
    }

    if (sc >= 5) {
      return styles.mediumScore;
    }

    if (sc < 5) {
      return styles.lowScore;
    }

    return styles.noScore;
  };

  const getDifferenceClass = (sc: number, averageScore: number) => {
    const difference = sc - averageScore;

    // greater than 1 green
    if (difference > 1) {
      return styles.highDifference;
    }

    // between 1 and -1 amber
    if (difference >= -1 && difference <= 1) {
      return styles.mediumDifference;
    }

    // lower than -1 red
    if (difference < -1) {
      return styles.lowDifference;
    }

    return styles.neutralDifference;
  };

  const getTrendIcon = (sc: number, prevScore: number) => {
    // no need to equal only greater
    if (sc > prevScore) {
      return <Img src={HighTrendIcon} alt="high-trend" className={styles.trendIcon} />;
    }

    if (sc === prevScore) {
      return <Img src={NeutralTrendIcon} alt="neutral-trend" className={styles.trendIcon} />;
    }

    return <Img src={LowTrendIcon} alt="low-trend" className={styles.trendIcon} />;
  };

  return (
    <div className={styles.scoresContainer}>
      <Card className={styles.scoreCard}>
        <div className={styles.scoreCardHeader}>
          <h6>Check in Scores</h6>
          <Tooltip
            title="Check-in scores - Click for more details"
            followCursor
            onClick={() => {
              if (!isDesktop) {
                smoothScrollTo(0, 0);
              }

              setInfoDialog({
                open: true,
                top: '650px',
                right: '43%',
                firstDescription: '',
                description: '',
                title: 'Check-in scores',
                htmlDescription: CHECK_IN_SCORES_INFO,
              });
            }}
          >
            <img
              src={HelpIcon}
              alt="help"
              className={styles.helpIcon}
              style={checkInScores?.lastUpdatedAt ? {} : { marginTop: '-7px' }}
            />
          </Tooltip>
          {checkInScoresLoading ? (
            <Skeleton variant="text" width={120} height={20} />
          ) : (
            <p>
              {checkInScores?.lastUpdatedAt &&
                `Last updated: ${formatDateToDateMonthYear(checkInScores.lastUpdatedAt)}`}
            </p>
          )}
        </div>

        {checkInScoresLoading ? (
          <div className={styles.loadingContainer}>
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
          </div>
        ) : (
          <>
            {(!checkInScores || checkInScores?.scores?.length === 0) && !checkInScoresLoading ? (
              <div className={styles.noDataSection}>
                <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                <p>No score data found.</p>
              </div>
            ) : (
              <table className={styles.scoresTable}>
                <thead>
                  <tr>
                    <th />
                    <th align="right">Trend</th>
                    <th>vs Programme</th>
                    <th>vs Hapstar</th>
                  </tr>
                </thead>
                <tbody>
                  {checkInScores &&
                    checkInScores.scores.map((score) => {
                      const scoreValue = score?.score ? Number(score.score).toFixed(1) : 'N/A';
                      const trendCircleScoreClass = score?.score
                        ? getScoreClass(Number(score.score))
                        : styles.noScore;
                      const vsProgrammeValue = score?.companyAvgScore
                        ? Number(score.companyAvgScore).toFixed(1)
                        : 'N/A';
                      const differenceProgrammeClass =
                        score.score !== null && score.companyAvgScore !== null
                          ? getDifferenceClass(Number(score.score), Number(score.companyAvgScore))
                          : styles.neutralDifference;

                      const vsHapstarValue = score?.overallHapstarScore
                        ? Number(score.overallHapstarScore).toFixed(1)
                        : 'N/A';

                      const differenceHapstarClass =
                        score.score !== null && score.overallHapstarScore !== null
                          ? getDifferenceClass(
                              Number(score.score),
                              Number(score.overallHapstarScore),
                            )
                          : styles.neutralDifference;

                      return (
                        <tr key={score.category}>
                          <td align="left">{score.category}</td>
                          <td className={styles.trend} align="left">
                            <div className={`${styles.circle} ${trendCircleScoreClass}`}>
                              {score?.score ? Math.floor(score.score) : 'N/A'}
                            </div>

                            <Tooltip
                              title={
                                <>
                                  <Typography sx={{ color: 'white' }}>
                                    Latest Score: {score?.score ? Math.floor(score?.score) : 'N/A'}
                                  </Typography>
                                  <Typography sx={{ color: 'white' }}>
                                    Previous Score:{' '}
                                    {score?.previousScore
                                      ? Math.floor(score?.previousScore)
                                      : 'N/A'}
                                  </Typography>
                                  <Typography sx={{ color: 'white' }}>
                                    Score difference:{' '}
                                    {score?.score && score?.previousScore
                                      ? Math.floor(score?.score) - Math.floor(score?.previousScore)
                                      : 'N/A'}
                                  </Typography>
                                </>
                              }
                              followCursor
                            >
                              <div>
                                {score?.score && score?.previousScore ? (
                                  getTrendIcon(
                                    Math.floor(score.score),
                                    Math.floor(score.previousScore),
                                  )
                                ) : (
                                  <Img
                                    src={NeutralTrendIcon}
                                    alt="neutral-trend"
                                    className={styles.trendIcon}
                                  />
                                )}
                              </div>
                            </Tooltip>
                          </td>
                          <td className={styles.roundedRectangle} align="center">
                            <div className={`${differenceProgrammeClass}`}>
                              <p>{`${scoreValue} vs ${vsProgrammeValue}`}</p>
                            </div>
                          </td>

                          <td className={styles.roundedRectangle} align="center">
                            <div className={`${differenceHapstarClass}`}>
                              <p>{`${scoreValue} vs ${vsHapstarValue}`}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </>
        )}
      </Card>

      <Card className={styles.scoreCard}>
        <div className={styles.scoreCardHeader}>
          <h6>Deep Dive Scores</h6>
          <Tooltip
            title="Deep dive scores - Click for more details"
            followCursor
            onClick={() => {
              if (!isDesktop) {
                smoothScrollTo(0, 0);
              }

              setInfoDialog({
                open: true,
                top: '750px',
                right: '8%',
                firstDescription: '',
                description: '',
                title: 'Deep dive scores',
                htmlDescription: DEEP_DIVE_SCORES_INFO,
              });
            }}
          >
            <img src={HelpIcon} alt="help" className={styles.helpIcon2} />
          </Tooltip>
        </div>

        {deepDiveScoresLoading ? (
          <div className={styles.loadingContainer}>
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
            <Skeleton variant="rounded" height={40} width="100%" />
          </div>
        ) : (
          <>
            {(!deepDiveScores || deepDiveScores?.scores?.length === 0) && !deepDiveScoresLoading ? (
              <div className={styles.noDataSection}>
                <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                <p>No score data found.</p>
              </div>
            ) : (
              <table className={styles.scoresTable}>
                <thead>
                  <tr>
                    <th />
                    <th align="right">Trend</th>
                    <th>vs Programme</th>
                    <th>vs Hapstar</th>
                  </tr>
                </thead>
                <tbody>
                  {deepDiveScores &&
                    deepDiveScores.scores.map((score) => {
                      const scoreValue = score?.score ? Number(score.score).toFixed(1) : 'N/A';
                      const trendCircleScoreClass = score?.score
                        ? getScoreClass(Number(score.score))
                        : styles.noScore;
                      const vsProgrammeValue = score?.companyAvgScore
                        ? Number(score.companyAvgScore).toFixed(1)
                        : 'N/A';
                      const differenceProgrammeClass =
                        score.score !== null && score.companyAvgScore !== null
                          ? getDifferenceClass(Number(score.score), Number(score.companyAvgScore))
                          : styles.neutralDifference;

                      const vsHapstarValue = score?.overallHapstarScore
                        ? Number(score.overallHapstarScore).toFixed(1)
                        : 'N/A';
                      const differenceHapstarClass =
                        score.score !== null && score.overallHapstarScore !== null
                          ? getDifferenceClass(
                              Number(score.score),
                              Number(score.overallHapstarScore),
                            )
                          : styles.neutralDifference;

                      return (
                        <tr key={score.category}>
                          <td align="left">
                            {score.category}
                            <br />
                            <span className={styles.catDate}>
                              {score?.date && formatDateToDateMonthYear(score.date)}
                            </span>
                          </td>
                          <td className={styles.trend} align="left">
                            <div className={`${styles.circle} ${trendCircleScoreClass}`}>
                              {score?.score ? score.score.toFixed(1) : 'N/A'}
                            </div>

                            <Tooltip
                              title={
                                <>
                                  <Typography sx={{ color: 'white' }}>
                                    Latest Score: {score?.score ? score?.score.toFixed(1) : 'N/A'}
                                  </Typography>
                                  <Typography sx={{ color: 'white' }}>
                                    Previous Score:{' '}
                                    {score?.previousScore ? score?.previousScore.toFixed(1) : 'N/A'}
                                  </Typography>
                                  <Typography sx={{ color: 'white' }}>
                                    Score difference:{' '}
                                    {score?.score && score?.previousScore
                                      ? (
                                          Number(score?.score.toFixed(1)) -
                                          Number(score?.previousScore.toFixed(1))
                                        ).toFixed(1)
                                      : 'N/A'}
                                  </Typography>
                                </>
                              }
                              followCursor
                            >
                              <div>
                                {score?.score && score?.previousScore ? (
                                  getTrendIcon(
                                    Number(score.score.toFixed(1)),
                                    Number(score.previousScore.toFixed(1)),
                                  )
                                ) : (
                                  <Img
                                    src={NeutralTrendIcon}
                                    alt="neutral-trend"
                                    className={styles.trendIcon}
                                  />
                                )}
                              </div>
                            </Tooltip>
                          </td>
                          <td className={styles.roundedRectangle} align="center">
                            <div className={`${differenceProgrammeClass}`}>
                              <p>{`${scoreValue} vs ${vsProgrammeValue}`}</p>
                            </div>
                          </td>

                          <td className={styles.roundedRectangle} align="center">
                            <div className={`${differenceHapstarClass}`}>
                              <p>{`${scoreValue} vs ${vsHapstarValue}`}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </>
        )}
      </Card>

      <PopUpModal
        open={infoDialog.open}
        setOpen={() => setInfoDialog(InitInfoDialog)}
        top={infoDialog.top}
        right={infoDialog.right}
        firstDescription={infoDialog.firstDescription}
        description={infoDialog.description}
        title={infoDialog.title}
        overlay
        htmlDescription={infoDialog.htmlDescription}
      />
    </div>
  );
};

ScoresSection.defaultProps = {
  data: undefined,
  loading: false,
};

interface SectionProps {
  data?: any;
  loading?: boolean;
  checkInScores: CheckInAndDeepDiveScoreInterface | null;
  checkInScoresLoading: boolean;
  deepDiveScores: CheckInAndDeepDiveScoreInterface | null;
  deepDiveScoresLoading: boolean;
}

export default ScoresSection;
