import { getCompanyDetailsByUrl } from '@api/auth/company';
import CheckYourInbox from '@components/Templates/CheckYourInbox';
import { PublicCompanyDetails, castResponse } from 'custom.d';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const LoginPage: React.FunctionComponent = () => {
  const { companyURL, email } = useParams<UrlParamsType>();
  const [companyDetails, setCompanyDetails] = useState<PublicCompanyDetails | null>(null);
  let organizationLogo: string | undefined;
  let organizationAltText: string | undefined;
  let hideHapstarLogo = false;
  let platformName: string | null = null;
  let organizationId = 0;

  useEffect(() => {
    const getCompanyData = async () => {
      const response = await getCompanyDetailsByUrl(companyURL);
      if (response?.success) {
        const data = castResponse<PublicCompanyDetails>(response);
        setCompanyDetails(data);
      } else {
        setCompanyDetails(null);
      }
    };

    if (companyURL) {
      getCompanyData();
    }
  }, [companyURL]);

  if (companyDetails !== null) {
    organizationLogo = companyDetails.companyLogo
      ? `${process.env.PUBLIC_URL}/images/logos/${companyDetails.companyLogo}`
      : undefined;
    organizationAltText = `${companyDetails.companyName} logo`;
    hideHapstarLogo = companyDetails.hideHapstarLogo;
    platformName =
      companyDetails.platformName &&
      companyDetails.platformName !== 'null' &&
      companyDetails.platformName !== null
        ? companyDetails.platformName
        : null;
    organizationId = companyDetails?.id || 0;
  }

  return (
    <CheckYourInbox
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
      companyURL={companyURL}
      email={email}
      hideHapstarLogo={hideHapstarLogo}
      platformName={platformName}
      organizationId={organizationId}
    />
  );
};

type UrlParamsType = {
  email: string;
  companyURL: string;
};

export default LoginPage;
