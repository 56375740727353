import { createSlice } from '@reduxjs/toolkit';
import { CompanyConfigData } from 'custom';

export interface ConfigurationState {
  loading: boolean;
  isLoaded: boolean;
  error: string | null;
  data: CompanyConfigData;
}

const initialConfigState: ConfigurationState = {
  loading: false,
  isLoaded: false,
  error: null,
  data: {
    showInstantHelp: false,
    instantHelpDescription: '',
    showSpeakUp: false,
    welcomeMessageText: null,
    expertsEnabled: false,
    talkToAdvisor: false,
    talkToAdvisorMessage: null,
    talkToAdvisorCompleteMessage: null,
    hideHapstarLogo: false,
    dashboardBgColour: null,
    dashboardNewsOnTop: false,
    platformName: null,
    shareThoughtOrganizationText: null,
    participantReportUserInsightsEnabled: false,
  },
};

export interface UpdateConfigurationDataAction {
  type: string;
  payload: CompanyConfigData;
}

export interface SetLoadingAction {
  type: string;
  payload: {
    loading: boolean;
  };
}

export interface SetErrorAction {
  type: string;
  payload: {
    error: string;
  };
}

const companyConfigSlice = createSlice({
  name: 'companyconfigs',
  initialState: initialConfigState,
  reducers: {
    setLoading(state: ConfigurationState, action: SetLoadingAction): ConfigurationState {
      return {
        ...state,
        loading: action.payload.loading,
        isLoaded: false,
        error: null,
        data: {
          showInstantHelp: false,
          instantHelpDescription: '',
          showSpeakUp: false,
          welcomeMessageText: null,
          expertsEnabled: false,
          talkToAdvisor: false,
          talkToAdvisorMessage: null,
          talkToAdvisorCompleteMessage: null,
          hideHapstarLogo: false,
          dashboardBgColour: null,
          dashboardNewsOnTop: false,
          platformName: null,
          shareThoughtOrganizationText: null,
          participantReportUserInsightsEnabled: false,
        },
      };
    },
    setError(state: ConfigurationState, action: SetErrorAction): ConfigurationState {
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: action.payload.error,
        data: {
          showInstantHelp: false,
          instantHelpDescription: '',
          showSpeakUp: false,
          welcomeMessageText: null,
          expertsEnabled: false,
          talkToAdvisor: false,
          talkToAdvisorMessage: null,
          talkToAdvisorCompleteMessage: null,
          hideHapstarLogo: false,
          dashboardBgColour: null,
          dashboardNewsOnTop: false,
          platformName: null,
          shareThoughtOrganizationText: null,
          participantReportUserInsightsEnabled: false,
        },
      };
    },
    updateConfigurationData(
      state: ConfigurationState,
      action: UpdateConfigurationDataAction,
    ): ConfigurationState {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        error: null,
        data: action.payload,
      };
    },
  },
});

export const companyConfigActions = companyConfigSlice.actions;

export default companyConfigSlice;
