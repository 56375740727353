import React from 'react';
import Modal from 'react-modal';
import ModalContent from '@components/ModalContent';
import CreateImprovePlan from '@components/ModalContent/CreateImprovePlan';

interface OpenImprovePlanCreateDialog {
  open: boolean;
  data: {
    questionId: number;
    actionContent: string;
    questionName: string;
    actionButton: string | null;
    actionUrl: string | null;
  };
}

interface ImprovePlanModalProps {
  openImprovePlanCreateDialog: OpenImprovePlanCreateDialog;
  setOpenImprovePlanCreateDialog: React.Dispatch<React.SetStateAction<OpenImprovePlanCreateDialog>>;
  isMobile: boolean;
}

const ImprovePlanModal: React.FC<ImprovePlanModalProps> = ({
  openImprovePlanCreateDialog,
  setOpenImprovePlanCreateDialog,
  isMobile,
}) => {
  const handleCloseModal = () => {
    setOpenImprovePlanCreateDialog({
      open: false,
      data: {
        questionId: 0,
        actionContent: '',
        questionName: '',
        actionButton: null,
        actionUrl: null,
      },
    });
  };

  return (
    <Modal
      isOpen={openImprovePlanCreateDialog.open}
      onRequestClose={handleCloseModal}
      contentLabel="Improve Action Modal"
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 9999999,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          width: isMobile ? '90%' : undefined,
        },
      }}
    >
      <ModalContent onClose={handleCloseModal} hasBackground title="Create your action">
        <CreateImprovePlan
          closeModal={handleCloseModal}
          improvePlan={`${openImprovePlanCreateDialog?.data?.questionName} wellbeing`}
          id={0}
          questionId={openImprovePlanCreateDialog?.data?.questionId}
          isLearningArticle
          handleRefetch={() => null}
          actionContentData={openImprovePlanCreateDialog?.data?.actionContent}
          otherData={{
            actionButton: openImprovePlanCreateDialog?.data?.actionButton as string | null,
            actionUrl: openImprovePlanCreateDialog?.data?.actionUrl,
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default ImprovePlanModal;
