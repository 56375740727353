import blueTray from '@assets/images/icons/blueTray.svg';
import HelpIcon from '@assets/images/icons/help-circle.svg';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import TabButton from '@components/Atoms/TabButton';
import ModalContent from '@components/ModalContent';
import CreateImprovePlan from '@components/ModalContent/CreateImprovePlan';
import PopUpModal from '@components/PopUpModal';
import { smoothScrollTo } from '@helpers/general.helpers';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ImprovePlanAction, UserActionData } from '@interfaces/index';
import MobileToggler from '@layouts/MainLayout/SecondaryNavigation/components/MobileToggler';
import { Box, Chip, Grid, Paper, Skeleton, Tooltip, Typography } from '@mui/material';
import { ACTIONS_INFO, InitInfoDialog } from 'custom.d';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styles from './index.module.scss';

const ActionsSection: React.FunctionComponent<SectionProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, refetchActions, loading, participantId } = props;
  const { isMobile, isDesktop } = useWindowDimensions();

  const [actions, setActions] = useState<ImprovePlanAction[]>([]);
  const [selectedTabDetails, setSelectedTabDetails] = useState<TabDetailsInterface>({
    id: 0,
    label: 'Pending user actions',
    labelId: 1,
    actualItemId: 1,
  });
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [visibleActions, setVisibleActions] = useState(20);
  const [infoDialog, setInfoDialog] = useState<{
    open: boolean;
    top: string;
    right: string;
    firstDescription: string;
    description: string;
    title: string;
    htmlDescription: any;
  }>(InitInfoDialog);

  const handleLoadMore = () => {
    setVisibleActions((prevVisible) => prevVisible + 20);
  };

  const handleTabChange = async (tab: {
    id: number;
    label: string;
    labelId: number;
    actualItemId: number;
  }) => {
    await setSelectedTabDetails(tab);
    if (tab.actualItemId === 1) {
      setActions(data?.pendingUserActions || []);
    } else if (tab.actualItemId === 2) {
      setActions(data?.pendingHapstarActions || []);
    } else if (tab.actualItemId === 3) {
      const combinedActions = [
        ...(data?.completedUserActions || []),
        ...(data?.completedHapstarActions || []),
      ];

      setActions(combinedActions);
    }
  };

  useEffect(() => {
    if (selectedTabDetails.actualItemId === 1) {
      setActions(data?.pendingUserActions || []);
    }
  }, [selectedTabDetails.id, data]);

  const tabButtons = [
    {
      id: 1,
      title: 'Pending user actions',
      actualItemId: 1,
    },
    {
      id: 2,
      title: 'Pending Hapstar actions',
      actualItemId: 2,
    },
    {
      id: 3,
      title: 'Completed actions',
      actualItemId: 3,
    },
  ];

  const closeModal = () => {
    setActionModalOpen(false);
    if (refetchActions) {
      refetchActions();
    }
  };

  const getListItemClassName = (tag: string) =>
    tag ===
    (selectedTabDetails.label ? selectedTabDetails.label : tabButtons && tabButtons[0]?.title)
      ? styles.active
      : '';

  return (
    <Card className={styles.actionsCard}>
      <div className={styles.topSection}>
        <div className={styles.leftSection}>
          <h4 className={styles.cardTitle}>Actions</h4>
          <Tooltip
            title="Actions - Click for more details"
            followCursor
            onClick={() => {
              if (!isDesktop) {
                smoothScrollTo(0, 0);
              }

              setInfoDialog({
                open: true,
                top: '3550px',
                right: '43%',
                firstDescription: '',
                description: '',
                title: 'Actions',
                htmlDescription: ACTIONS_INFO,
              });
            }}
          >
            <img src={HelpIcon} alt="help" className={styles.helpIcon} />
          </Tooltip>
        </div>

        <div className={styles.rightSection}>
          <DefaultButton onClick={() => setActionModalOpen(true)}>Add action</DefaultButton>
        </div>
      </div>
      {loading ? (
        <Skeleton variant="rounded" width="100%" height={500} className={styles.loadingSection} />
      ) : (
        <>
          <div className={styles.tabSection}>
            <div className={styles.tabsContainer}>
              <TabButton
                selectedTab={selectedTabDetails}
                handleSelect={(tab: any) => {
                  handleTabChange(tab);
                }}
                tabs={tabButtons}
                isMobileWrapEnabled
              />
            </div>

            <div className={styles.hsSubNavigation}>
              <MobileToggler
                openHandler={setIsOpen}
                isOpen={isOpen}
                name={selectedTabDetails.label}
              />
              {isOpen && (
                <ul>
                  {tabButtons?.map((dt, index) => (
                    <li key={index} className={getListItemClassName(dt.title)}>
                      <a
                        onClick={() => {
                          handleTabChange({
                            id: dt.id,
                            label: dt.title,
                            labelId: dt.id,
                            actualItemId: dt.id,
                          });
                          setIsOpen(false);
                        }}
                      >
                        {dt.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className={styles.tabContentContainer}>
              {actions?.length === 0 ? (
                <div className={styles.noDataSection}>
                  <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                  <p>No {selectedTabDetails?.label?.toLocaleLowerCase() || 'actions'} found.</p>
                </div>
              ) : (
                <Box sx={{ p: 3 }}>
                  <Grid container spacing={3}>
                    {actions.slice(0, visibleActions).map((action, index) => (
                      <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                        <Paper
                          elevation={3}
                          sx={{
                            p: 2,
                            cursor: 'pointer',
                            borderRadius: 2,
                            backgroundColor: 'white',
                            transition: 'transform 0.3s',
                            boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
                            '&:hover': {
                              transform: 'scale(1.02)',
                              boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="h6" className={styles.actionTitle}>
                              {action.actionTitle ? action.actionTitle : `Action ${index + 1}`}
                            </Typography>
                            <Chip
                              label={`Created by: ${action.createdByTag}`}
                              color="secondary"
                              size="small"
                              sx={{
                                minWidth: '150px',
                                maxWidth: '180px',
                                backgroundColor: '#019df2',
                                borderRadius: '8px',
                              }}
                            />
                          </Box>

                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              fontWeight: '600',
                              color: action.improvePlanActionDueDays < 0 ? '#505050' : '#04bc00',
                            }}
                          >
                            {action.improvePlanActionDueDays < 0
                              ? `${Math.abs(action.improvePlanActionDueDays)} days overdue`
                              : action.improvePlanActionDueDays === null
                              ? 'No due date'
                              : `${action.improvePlanActionDueDays} days to go`}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>

                  {visibleActions < actions.length && (
                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                      <DefaultButton variant="darken" onClick={handleLoadMore}>
                        Load More
                      </DefaultButton>
                    </Box>
                  )}
                </Box>
              )}
            </div>
          </div>
        </>
      )}

      <Modal
        isOpen={actionModalOpen}
        onRequestClose={() => closeModal()}
        contentLabel="Improve Action Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '90%' : undefined,
          },
        }}
      >
        <ModalContent onClose={() => closeModal()} hasBackground title="Create user action">
          <CreateImprovePlan
            closeModal={() => closeModal()}
            improvePlan=""
            id={0}
            questionId={0}
            handleRefetch={() => refetchActions && refetchActions()}
            actionContentData={undefined}
            otherData={null}
            isAdvisorCreation
            participantId={participantId}
          />
        </ModalContent>
      </Modal>

      <PopUpModal
        open={infoDialog.open}
        setOpen={() => setInfoDialog(InitInfoDialog)}
        top={infoDialog.top}
        right={infoDialog.right}
        firstDescription={infoDialog.firstDescription}
        description={infoDialog.description}
        title={infoDialog.title}
        overlay
        htmlDescription={infoDialog.htmlDescription}
      />
    </Card>
  );
};

ActionsSection.defaultProps = {
  data: {
    pendingUserActions: [],
    pendingHapstarActions: [],
    completedUserActions: [],
    completedHapstarActions: [],
  },
  refetchActions: () => null,
  loading: false,
};

interface SectionProps {
  data?: UserActionData;
  refetchActions?: () => void;
  loading?: boolean;
  participantId: number;
}

interface TabDetailsInterface {
  id: number;
  label: string;
  labelId: number;
  actualItemId?: number;
}

export default ActionsSection;
