import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const PopUpModal = ({
  description,
  title,
  top,
  right,
  open,
  setOpen,
  firstDescription,
  buttonLabel,
  onActionClick,
  overlay,
  onTapBackgroundClose,
  htmlDescription,
}: PopUpModalProps) => {
  const actionButtonHandler = () => {
    if (onActionClick) {
      return onActionClick();
    }

    return setOpen(false);
  };

  return (
    <>
      {open && overlay && (
        <div
          className={styles.overlay}
          onClick={() => onTapBackgroundClose && setOpen(false)}
          style={{ height: window.innerHeight + 550 }}
        />
      )}

      {open && (
        <div className={styles.root} style={{ top, right }}>
          <button type="button" className={styles.btnClose} onClick={() => setOpen(false)}>
            <div className={styles.btnCloseLabel}>Close</div>
            <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
          </button>

          <div className={styles.title}>{title}</div>
          {firstDescription && (
            <div className={classNames(styles.description, styles.firstDescription)}>
              {firstDescription}
            </div>
          )}
          <div className={classNames(styles.description, styles.secondaryDescription)}>
            {description}
          </div>

          {htmlDescription && (
            <div
              className={styles.htmlDescription}
              dangerouslySetInnerHTML={{ __html: htmlDescription }}
            />
          )}

          <div className={styles.btnActionContainer}>
            <div className={styles.btnAction} onClick={actionButtonHandler}>
              {buttonLabel}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

PopUpModal.defaultProps = {
  firstDescription: '',
  buttonLabel: 'Got it',
  onActionClick: null,
  overlay: false,
  onTapBackgroundClose: false,
  htmlDescription: null,
};

interface PopUpModalProps {
  description: JSX.Element | string;
  title: string;
  top: string;
  right: string;
  open: boolean;
  setOpen: any;
  firstDescription?: string;
  buttonLabel?: string;
  onActionClick?: any;
  overlay?: boolean;
  onTapBackgroundClose?: boolean;
  htmlDescription?: any;
}

export default PopUpModal;
