import { saveEmployerResponses } from '@api/EmployerNew';
import blueTray from '@assets/images/icons/blueTray.svg';
import CommentsActionCard from '@components/ActionsCard/CommentsActionCard';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import { UserThoughtInterface } from '@interfaces/index';
import { Backdrop, CircularProgress, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const CommentsSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, loading, refetchThoughts } = props;
  const [responseSaving, setResponseSaving] = useState<boolean>(false);

  const saveResponse = async ({
    thoughtId,
    thoughtResponseText,
  }: {
    thoughtId: number;
    thoughtResponseText: string;
  }) => {
    try {
      setResponseSaving(true);
      if (thoughtResponseText?.length > 0) {
        await saveEmployerResponses({
          thoughtId,
          thoughtResponseText,
        });
      }

      await refetchThoughts();
      await toast.success('Response send successfully');
    } catch (error) {
      toast.error('Error saving response');
    } finally {
      setResponseSaving(false);
    }
  };

  return (
    <Card className={styles.mainContainer}>
      <h4 className={styles.cardTitle}>Comments</h4>

      {data.filter((o: UserThoughtInterface) => o.responded === false)?.length === 0 &&
        !loading && (
          <div className={styles.noDataSection}>
            <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
            <p>No comments found.</p>
          </div>
        )}

      {loading ? (
        <div className={styles.loadingContainer}>
          <Skeleton variant="rounded" height={170} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={170} width="100%" className={styles.loadingItem} />
          <Skeleton variant="rounded" height={170} width="100%" className={styles.loadingItem} />
        </div>
      ) : (
        <div className={styles.awaitingResContent}>
          {data?.length > 0 &&
            data
              .filter((o: UserThoughtInterface) => o.responded === false)
              .map((i: UserThoughtInterface, key: number) => (
                <div key={key} className={styles.commentCard}>
                  <CommentsActionCard
                    categoryName={i?.question?.category?.name}
                    saveResponseProps={(thoughtResponseText: string) =>
                      saveResponse({ thoughtId: i.id, thoughtResponseText })
                    }
                    key={key}
                    addToCompleted={() => null}
                    focusArea={i.question?.questionText}
                    focusAreaColor={i.question?.category?.color?.toLocaleUpperCase()}
                    action={i?.thoughtsText}
                    score={i.score?.score || 0}
                    createdDate={i.createdAt}
                    completed
                    showResponseButton={i.responded}
                  />
                </div>
              ))}
        </div>
      )}

      <Backdrop
        sx={(theme) => ({
          color: 'white',
          zIndex: theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
        })}
        open={responseSaving}
        onClick={() => setResponseSaving(false)}
      >
        <CircularProgress color="inherit" />
        <p className={styles.backdropText}>Processing response...</p>
      </Backdrop>
    </Card>
  );
};

CommentsSection.defaultProps = { loading: false };

interface SectionProps {
  data: UserThoughtInterface[];
  loading?: boolean;
  refetchThoughts: () => void;
}

export default CommentsSection;
