import blueTray from '@assets/images/icons/blueTray.svg';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import { FocusAndProgressRootInterface } from '@interfaces/index';
import { Skeleton } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import styles from './index.module.scss';

const FocusAreasAndProgressSection: React.FunctionComponent<SectionProps> = (props) => {
  const { data, loading } = props;

  if (!data) return null;

  const categories = Object.keys(data);
  const oldestScores = categories.map((key) => data[key].oldest.score);
  const latestScores = categories.map((key) => data[key].latest.score);
  const oldestDates = categories.map(
    (key) => new Date(data[key].oldest.date).toISOString().split('T')[0],
  ); // Format date as YYYY-MM-DD
  const latestDates = categories.map(
    (key) => new Date(data[key].latest.date).toISOString().split('T')[0],
  ); // Format date as YYYY-MM-DD
  const lengthV1 = categories?.length > 0 && categories?.length <= 3;
  const lengthV2 = categories?.length > 3 && categories?.length <= 6;
  const lengthV3 = categories?.length > 6;
  const chartHeight = lengthV1
    ? categories.length * 120
    : lengthV2
    ? categories.length * 70
    : lengthV3
    ? categories.length * 40
    : 550;

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
        barHeight: '60%',
      },
    },
    colors: ['#6048A1', '#069B7B'],
    dataLabels: {
      enabled: false,
      style: {
        colors: ['#000'],
      },
    },
    xaxis: {
      categories,
      title: {
        // text: 'Score',
      },
      min: 1,
      max: 10,
    },
    legend: {
      position: 'right',
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  const series = [
    {
      name: 'Latest',
      data: latestScores,
    },
    {
      name: 'Oldest',
      data: oldestScores,
    },
  ];

  return (
    <Card className={styles.focusAreasAndProgressCard}>
      <h4 className={styles.cardTitle}>Focus Areas and Progress</h4>

      {loading ? (
        <Skeleton variant="rounded" width="100%" height={550} />
      ) : (
        <>
          {categories?.length === 0 ? (
            <div className={styles.noDataSection}>
              <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
              <p>No focus area data found.</p>
            </div>
          ) : (
            <Chart options={chartOptions} series={series} type="bar" height={chartHeight} />
          )}
        </>
      )}
    </Card>
  );
};

FocusAreasAndProgressSection.defaultProps = {
  data: null,
  loading: false,
};

interface SectionProps {
  data?: FocusAndProgressRootInterface | null;
  loading?: boolean;
}

export default FocusAreasAndProgressSection;
