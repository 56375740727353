import { createImprovePlan, createUserAction, getUserImprovePlans } from '@api/actionPlan';
import { GetFocusAreaSuggestions } from '@api/focusArea';
import { getQuestionnairesNames } from '@api/questionnaires';
import Button from '@components//Button';
import Modal from '@components/Atoms/Modal';
import SelectComponent from '@components/Atoms/SelectComponent';
import StaticDatePickerX from '@components/Atoms/StaticDatePicker';
import { useAppSelector } from '@hooks/useAppSelector';
import classNames from 'classnames';
import { ImprovePlanTypes, SelectableOptionType, UserImprovePlanActions } from 'custom.d';
import { addMonths, addWeeks, format } from 'date-fns';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import styles from './index.module.scss';

const CreateImprovePlan: React.FunctionComponent<CreateImprovePlanProps> = (props) => {
  const {
    improvePlan,
    id,
    closeModal,
    actionToEdit = '',
    handleRefetch,
    focusAreaSuggestionsEnabled,
    actionData,
    questionId,
    isLearningArticle,
    actionContentData,
    otherData,
    isAdvisorCreation,
    participantId,
  } = props;
  const [canCreate, setCanCreate] = useState(true);
  const [action, setAction] = useState(actionToEdit);
  const [selectedValue, setSelectedValue] = useState('');
  const [dueDate, setDueDate] = useState<string>('');
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [pickerDate, setPickerDate] = useState<string>('');
  const [pickerSelectionLoading, setPickerSelectionLoading] = useState<boolean>(false);
  const [focusAreasSuggestions, setFocusAreaSuggestions] = useState([]);
  const [isUserSelectedNoDue, setIsUserSelectedNoDue] = useState<boolean>(false);
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user || '{}');
  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);
  const [improvePlanDetails, setImprovePlanDetails] = useState<UserImprovePlanActions | null>(null);
  const [questionnaireNames, setQuestionnaireNames] = useState<
    { value: number; label: string }[] | []
  >([]);
  const [selectedQuestionnaireName, setSelectedQuestionnaireName] = useState<{
    value: number;
    label: string;
  }>({ value: 0, label: 'No deep dive selected' });

  const isParticipantCreatingPlan = participantId && participantId > 0;

  useEffect(() => {
    improvePlans();
  }, [questionId]);

  useEffect(() => {
    if (actionContentData && actionContentData?.length > 0) {
      setAction(actionContentData);
    }
  }, [actionContentData]);

  useEffect(() => {
    if (isAdvisorCreation) {
      getQueNames();
    }
  }, [isAdvisorCreation]);

  const getQueNames = async () => {
    const res = await getQuestionnairesNames();
    if (res === 401) {
      setQuestionnaireNames([]);
    } else {
      const resData = res.map((item: { id: number; name: string }) => ({
        value: item.id,
        label: item.name,
      }));
      setQuestionnaireNames(resData);
    }
  };

  const styleClasses = classNames([
    styles.hsCreateAction,
    actionData?.color === '#BEAAF3' && styles.calmLilac,
    actionData?.color === '#019DF2' && styles.skyBlue,
    actionData?.color === '#FCD300' && styles.sunYellow,
    actionData?.color === '#8EEA36' && styles.zingyGreen,
    actionData?.color === '#06BF97' && styles.aquaGreen,
    actionData?.color === '#FF8A4A' && styles.warmOrange,
  ]);

  const quoteMap = new Map([
    ['Mental', '“The path to success is to take massive, determined action.” - Tony Robbins'],
    [
      'Physical',
      '“Inaction breeds doubt and fear. Action breeds confidence and courage. If you want to conquer fear, do not sit home and think about it. Go out and get busy.” - Dale Carnegie ',
    ],
    [
      'Social',
      '“You don’t have to be great to start, but you have to start to be great.” - Zig Ziglar',
    ],
    [
      'Career',
      '“Action may not always bring happiness, but there is no happiness without action.” - Benjamin Disraeli',
    ],
    ['Overall', '“Action is the foundational key to all success.” - Pablo Picasso'],
    ['Financial', '“The way to get started is to quit talking and begin doing.” - Walt Disney'],
    ['Default', ''],
  ]);

  async function improvePlans() {
    const res = await getUserImprovePlans(true);

    const modifiedData = res?.userImprovePlans?.filter((dt) => dt.question.id === questionId);
    setImprovePlanDetails(modifiedData[0]);
  }

  const handleActionInput = (e: FormEvent<HTMLTextAreaElement>) => {
    const newAction = e.currentTarget.value;
    if (newAction.length <= 100) {
      setAction(e.currentTarget.value);
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setCanCreate(
      action !== '' &&
        (dueDate?.toString() !== '' || pickerDate?.toString() !== '' || isUserSelectedNoDue),
    );
  }, [action, dueDate, pickerDate, isUserSelectedNoDue]);

  useEffect(() => {
    const getFocusAreaSuggestions = async () => {
      if (actionData?.id) {
        const focusData = await GetFocusAreaSuggestions(actionData?.id);

        setFocusAreaSuggestions(focusData);
      } else {
        setFocusAreaSuggestions([]);
      }
    };

    getFocusAreaSuggestions();
  }, [actionData]);

  const handleDateChange = (
    value: SingleValue<SelectableOptionType> | MultiValue<SelectableOptionType>,
  ) => {
    const selectedOption = value as SingleValue<SelectableOptionType>;
    const newValue = selectedOption ? selectedOption.value : '';

    setSelectedValue(newValue);
    let newDate;

    switch (newValue) {
      case '0':
        setIsUserSelectedNoDue(true);
        break;
      case '01':
        setIsUserSelectedNoDue(false);
        newDate = addWeeks(new Date(), 1);
        setPickerDate('');
        break;
      case '02':
        setIsUserSelectedNoDue(false);
        newDate = addWeeks(new Date(), 2);
        setPickerDate('');
        break;
      case '03':
        setIsUserSelectedNoDue(false);
        newDate = addMonths(new Date(), 1);
        setPickerDate('');
        break;
      case '05':
        setIsUserSelectedNoDue(false);
        newDate = addMonths(new Date(), 3);
        setPickerDate('');
        break;
      case '06':
        setIsUserSelectedNoDue(false);
        setDatePickerOpen(true);
        break;
      default:
        newDate = new Date();
    }

    if (newValue === '06' && pickerDate) {
      setDueDate(pickerDate);
    } else if (newValue && newDate) {
      setDueDate(format(newDate, 'yyyy-MM-dd'));
    }
  };

  const handleDatePickerChange = (date: string | null) => {
    setPickerSelectionLoading(true);
    if (date) {
      setPickerDate(date);
      setTimeout(() => {
        setPickerSelectionLoading(false);
      }, 500);
    }
  };

  const [otherLabel, setOtherLabel] = useState<string>('other');

  useEffect(() => {
    if (pickerDate) {
      setOtherLabel(`other (${pickerDate})`);
    } else {
      setOtherLabel('other');
    }
  }, [pickerDate, dueDate, datePickerOpen]);

  const dueOptions = useMemo(
    () => [
      { value: '0', label: 'No due date' },
      { value: '01', label: '1 week' },
      { value: '02', label: '2 weeks' },
      { value: '03', label: '1 month' },
      { value: '05', label: '3 months' },
      { value: '06', label: otherLabel },
    ],
    [otherLabel, selectedValue, pickerDate],
  );

  const handleCreateUserAction = async () => {
    if (isLearningArticle && questionId) {
      const res = await createImprovePlan(
        ImprovePlanTypes.LEARNING_ARTICLE,
        questionId,
        null,
        null,
        null,
        isParticipantCreatingPlan ? participantId : null,
      );

      if (res?.id) {
        const payload = {
          userId: isParticipantCreatingPlan ? participantId : parsedUser.id,
          improvePlanId: res.id,
          title: action,
          description: null,
          dueDate: isUserSelectedNoDue ? null : pickerDate || dueDate,
          actionButton: otherData?.actionButton,
          url: otherData?.actionUrl,
        };
        await createUserAction(payload);
      }
    } else if (id !== 0 && !isLearningArticle && !isAdvisorCreation) {
      const payload = {
        userId: isParticipantCreatingPlan ? participantId : parsedUser.id,
        improvePlanId: id,
        title: action,
        description: null,
        dueDate: isUserSelectedNoDue ? null : pickerDate || dueDate,
      };
      await createUserAction(payload);
    } else if (isAdvisorCreation) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        selectedQuestionnaireName.value,
        [],
        1,
        isParticipantCreatingPlan ? participantId : null,
      );

      const payload = {
        userId: isParticipantCreatingPlan ? participantId : parsedUser.id,
        improvePlanId: res?.id,
        title: action,
        description: null,
        dueDate: isUserSelectedNoDue ? null : pickerDate || dueDate,
      };
      if (res) {
        await createUserAction(payload);
      }
    } else {
      const payload = {
        userId: isParticipantCreatingPlan ? participantId : parsedUser.id,
        improvePlanId: id,
        title: action,
        description: null,
        dueDate: isUserSelectedNoDue ? null : pickerDate || dueDate,
        actionButton: otherData?.actionButton,
        url: otherData?.actionUrl,
      };
      await createUserAction(payload);
    }

    if (isLearningArticle) {
      if (!improvePlanDetails) {
        await toast.success(
          `We created a new ${improvePlan?.toLowerCase()} plan for you and added this action. Access your plans by going to the Plans section in navigation.`,
        );
      } else {
        await toast.success(
          `Action is added to your ${improvePlanDetails?.question?.questionText?.toLowerCase()} wellbeing plan. Access your plans by going to the Plans section in navigation.`,
        );
      }
    } else {
      await toast.success('Action added!');
    }

    await handleRefetch();
    await setPickerDate('');
    await setDueDate('');
    await setSelectedValue('');
    await setIsUserSelectedNoDue(false);
    await closeModal();
    setSelectedQuestionnaireName({ value: 0, label: 'No deep dive selected' });
  };

  const handleDeepDiveChange = async (value: any) => {
    setSelectedQuestionnaireName(value);
  };

  return (
    <div className={styleClasses}>
      {isAdvisorCreation ? (
        <>
          <h5 className={styles.dueDateSelectHeading}>Select deep dive area for this action</h5>

          <div className={styles.select}>
            <SelectComponent
              id="questionnaireName"
              name="questionnaireName"
              value={questionnaireNames.find(
                (option) => option.value === selectedQuestionnaireName.value,
              )}
              placeholder="Select deep dive area"
              onChange={(value) => handleDeepDiveChange(value)}
              options={questionnaireNames}
              className={styles.selectComponent}
            />
          </div>

          <h5 className={styles.dueDateSelectHeading}>Add action title</h5>

          <div>
            <textarea placeholder="Action title..." onInput={handleActionInput} value={action} />
            <p className={styles.hsCharCounter}>{action.length}/100 characters</p>
          </div>

          <h5 className={styles.dueDateSelectHeading}>
            When would you like to complete this action?
          </h5>

          <div className={styles.select}>
            <SelectComponent
              id="dueDate"
              name="dueDate"
              value={dueOptions.find((option) => option.value === selectedValue)}
              placeholder="Select due date"
              onChange={(value) => handleDateChange(value)}
              options={dueOptions}
              className={styles.selectComponent}
              isLoading={pickerSelectionLoading}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.hsCreateActionLabel}>
            {!focusAreaSuggestionsEnabled ? (
              <>
                <p>{improvePlan} plan</p> <p />
              </>
            ) : (
              <>
                <p />
                <p>
                  Score <span>{actionData?.score}</span>
                </p>
              </>
            )}
          </div>
          {isLearningArticle && !improvePlanDetails && (
            <div className={styles.learningArticleSubTitle}>
              We will create a personalised {improvePlan?.toLowerCase()} plan for you.
            </div>
          )}
          <div>
            <textarea placeholder="Your action..." onInput={handleActionInput} value={action} />
            <p className={styles.hsCharCounter}>{action.length}/100 characters</p>
          </div>

          <h5 className={styles.dueDateSelectHeading}>
            When would you like to complete this action?
          </h5>

          <div className={styles.select}>
            <SelectComponent
              id="dueDate"
              name="dueDate"
              value={dueOptions.find((option) => option.value === selectedValue)}
              placeholder="Select due date"
              onChange={(value) => handleDateChange(value)}
              options={dueOptions}
              className={styles.selectComponent}
              isLoading={pickerSelectionLoading}
            />
          </div>

          {focusAreaSuggestionsEnabled ? (
            <div className={styles.hsCreateActionSuggestions}>
              {focusAreasSuggestions?.length > 0 && (
                <p className={styles.hsCreateActionSuggestionsTitle}>Add a suggested action</p>
              )}

              {focusAreasSuggestions &&
                focusAreasSuggestions.map((suggestion: any, index: number) => (
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      const result = `${suggestion.description}`.substring(0, 100);
                      setAction(result);
                    }}
                    className={styles.hsSuggestion}
                    key={index}
                  >
                    {suggestion.description}
                  </div>
                ))}
            </div>
          ) : (
            <>
              <div className={styles.placeholders}>
                <span>
                  Edit this action or add your own and set a date for the task to be completed.
                </span>
                <span>
                  Setting a deadline for your task gives you a target to aim for, making it easier
                  to stay focused and motivated. It adds urgency, so you’re more likely to get
                  things done, and it holds you accountable, helping you stay on track and achieve
                  your goals effectively. 🚀.
                </span>
                <span>
                  {quoteMap.get(improvePlan) !== undefined
                    ? quoteMap.get(improvePlan)
                    : quoteMap.get('Default')}
                </span>
              </div>
            </>
          )}
        </>
      )}

      <div className={styles.hsButtonContainer}>
        <Button
          isButton
          isDisabled={
            !canCreate ||
            (isAdvisorCreation &&
              (selectedQuestionnaireName.value === null || selectedQuestionnaireName.value === 0))
          }
          type="primary"
          onClick={handleCreateUserAction}
        >
          Add action
        </Button>
      </div>

      {datePickerOpen && (
        <Modal
          open={datePickerOpen}
          setOpen={setDatePickerOpen}
          overlay
          onTapBackgroundClose
          className={styles.datePickerModal}
        >
          <StaticDatePickerX
            selectedDate={pickerDate}
            handleChange={handleDatePickerChange}
            onClose={() => setDatePickerOpen(false)}
            onAccept={() => setDatePickerOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

interface CreateImprovePlanProps {
  actionToEdit?: string;
  improvePlan: string;
  id: number;
  closeModal: () => void;
  handleRefetch: () => void;
  actionData?: any;
  focusAreaSuggestionsEnabled?: boolean;
  questionId?: number;
  isLearningArticle?: boolean;
  actionContentData?: string;
  otherData?: {
    actionButton: string | null;
    actionUrl: string | null;
  } | null;
  isAdvisorCreation?: boolean;
  participantId?: number;
}

CreateImprovePlan.defaultProps = {
  actionToEdit: '',
  focusAreaSuggestionsEnabled: false,
  actionData: undefined,
  questionId: 0,
  isLearningArticle: false,
  actionContentData: '',
  otherData: null,
  isAdvisorCreation: false,
  participantId: 0,
};

export default CreateImprovePlan;
