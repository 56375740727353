import { getUserByUniqueId } from '@api/users';
import DefaultButton from '@components/Atoms/DefaultButton';
import { UserDetailsByUniqueIdInterface } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import { castResponse } from 'custom.d';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AdvisorReporting from '../Reporting/ParticipantReport';
import styles from './index.module.scss';

const ParticipantReportHome: React.FunctionComponent = () => {
  const [participantSearchQuery, setParticipantSearchQuery] = useState('');
  const [participant, setParticipant] = useState<UserDetailsByUniqueIdInterface | null>(null);
  const [loading, setLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const getParticipantDetails = async () => {
    setLoading(true);
    try {
      const response = await getUserByUniqueId(participantSearchQuery);
      if (response.success) {
        const data = castResponse<UserDetailsByUniqueIdInterface>(response);
        setParticipant(data);
        toast.success('Participant found successfully!');
        setShowReport(true);
      } else {
        setParticipant(null);
        toast.error('User cannot be found');
        setShowReport(false);
      }
    } catch (error: any) {
      setParticipant(null);
      toast.error('User cannot be found');
      setShowReport(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onClickSearchParticipant = () => {
    if (!participantSearchQuery.trim()) {
      toast.error('Please enter an ID to search');

      return;
    }

    getParticipantDetails();
  };

  return (
    <>
      {!showReport || !participant ? (
        <MainLayout title="Participant Report" secondaryNavEnabled useAdvisorNav>
          <div className={styles.contentContainer}>
            <h5>Search by Participant ID</h5>
            <div className={styles.searchContainer}>
              <Autocomplete
                freeSolo
                options={[]}
                inputValue={participantSearchQuery}
                onInputChange={(event, newValue) => setParticipantSearchQuery(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Participant ID"
                    placeholder="Enter participant's ID"
                    fullWidth
                  />
                )}
                className={styles.autoComplete}
              />
              <DefaultButton
                variant="primary"
                onClick={onClickSearchParticipant}
                disabled={loading || !participantSearchQuery.trim()}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
              </DefaultButton>
            </div>

            {!participant && !loading && (
              <Typography variant="body2" color="textSecondary" className={styles.noData}>
                Please enter participant ID to continue.
              </Typography>
            )}
          </div>
        </MainLayout>
      ) : (
        // Display participant report
        <AdvisorReporting
          participantIdQuery={participant?.userId}
          participantUniqueIdQuery={participantSearchQuery}
          handleBack={() => {
            setShowReport(false);
            setParticipantSearchQuery('');
            setParticipant(null);
            setLoading(false);
          }}
        />
      )}
    </>
  );
};

export default ParticipantReportHome;
