import { getExpertById } from '@api/experts';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import Modal from '@components/Atoms/Modal';
import { ExpertBookingRequestDetailsInterface, ExpertInterface } from '@interfaces/index';
import { Backdrop, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { castResponse } from 'custom.d';
import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router';
import BookingRequestAvailability from '../BookingRequestAvailability';
import RequestedUserInfo from '../RequestedUserInfo';
import styles from './index.module.scss';

export interface BookingExpertCardProps {
  id: number;
  image: string;
  name: string;
  description: string;
  experience: string;
  requestDetails: ExpertBookingRequestDetailsInterface;
}

const BookingExpertCard: FunctionComponent<BookingExpertCardProps> = (props) => {
  const { id, image, name, description, experience, requestDetails } = props;
  const history = useHistory();
  const [availabilityOpen, setAvailabilityOpen] = useState(false);

  const [expertDetails, setExpertDetails] = useState<ExpertInterface | null>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  useEffect(() => {
    if (id && availabilityOpen) {
      getExpertDetails();
    }
  }, [id, availabilityOpen]);

  const getExpertDetails = async () => {
    try {
      handleOpenDrawer();
      const response = await getExpertById(Number(id));
      if (response.success) {
        const data = castResponse<ExpertInterface>(response);
        setExpertDetails(data);
      } else {
        setExpertDetails(null);
      }
    } catch {
      setExpertDetails(null);
    } finally {
      handleCloseDrawer();
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openDrawer}
        onClick={handleCloseDrawer}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {availabilityOpen &&
        expertDetails &&
        ReactDOM.createPortal(
          <Modal
            open={availabilityOpen}
            setOpen={() => {
              setAvailabilityOpen(false);
              setExpertDetails(null);
            }}
            className={styles.modalRoot}
            overlay
          >
            <div className={styles.btnWrapper}>
              <div className={styles.btnClose}>
                <button
                  type="button"
                  onClick={() => {
                    setAvailabilityOpen(false);
                    setExpertDetails(null);
                  }}
                >
                  Close&nbsp;
                  <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
                </button>
              </div>
            </div>
            <div className={styles.leftSideExpertDetailsDesktop}>
              <Img src={`https://${image}`} alt={name} className={styles.expertProfileImg} />
              <div className={styles.expertDetails}>
                <h5>{`${expertDetails.firstName} ${expertDetails.lastName}`}</h5>

                {expertDetails.expertDetail.fullDescription.split('\\n').map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            </div>

            <div className={styles.leftSideExpertDetailsGamma}>
              <div className={styles.row}>
                <Img src={`https://${image}`} alt={name} className={styles.expertProfileImg} />
                <h5>{`${expertDetails.firstName} ${expertDetails.lastName}`}</h5>
              </div>

              <div className={styles.expertDetails}>
                {expertDetails.expertDetail.fullDescription.split('\\n').map((line, index) => (
                  <p key={index}>{line} </p>
                ))}
              </div>
            </div>

            <RequestedUserInfo requestDetails={requestDetails} />

            <hr />

            <BookingRequestAvailability
              expertDetails={expertDetails}
              requestDetails={requestDetails}
            />
          </Modal>,
          document.body,
        )}

      <div className={styles.mainContainer}>
        <div className={styles.subContainer}>
          <Img src={`https://${image}`} alt={name} className={styles.expertProfileImg} />
          <h5 className={styles.name}>{name}</h5>
          <p className={styles.experience}>{experience}</p>
        </div>

        <div className={styles.bookButtonContainer}>
          <div className={styles.bookButton}>
            <DefaultButton onClick={() => setAvailabilityOpen(true)}>Book a session</DefaultButton>
          </div>
        </div>
      </div>
    </>
  );
};

BookingExpertCard.displayName = 'BookingExpertCard';

export default BookingExpertCard;
