import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import { getCompanyDetails } from '@helpers/auth.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import classNames from 'classnames';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { RootState } from 'store';
import styles from './index.module.scss';

const ShareThoughts: React.FunctionComponent<ShareThoughtsProps> = (props) => {
  const { question, score, color, id, shareThoughts } = props;
  const [thought, setThought] = useState<string>('');
  const [isSharedWith, setIsSharedWith] = useState('mySelf');
  const [companyChecked, setCompanyChecked] = useState(false);
  const [mySelfChecked, setMySelfChecked] = useState(false);
  const { collapseSidebar } = useProSidebar();
  const textareaRef = useRef() as any;
  const [currentCompanyName, setCurrentCompanyName] = useState('');
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig.data);

  useEffect(() => {
    const getCurrentCompanyName = async () => {
      const comDetails = await getCompanyDetails();
      if (comDetails) {
        setCurrentCompanyName(comDetails.company_name);
      }
    };

    getCurrentCompanyName();
  }, []);

  const styleClasses = classNames([
    styles.hsShareThoughts,
    color === '#BEAAF3' && styles.calmLilac,
    color === '#019DF2' && styles.skyBlue,
    color === '#FCD300' && styles.sunYellow,
    color === '#8EEA36' && styles.zingyGreen,
    color === '#06BF97' && styles.aquaGreen,
    color === '#FF8A4A' && styles.warmOrange,
  ]);

  const handleInput = (e: FormEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length <= 280) {
      setThought(e.currentTarget.value);
    }
  };

  const handleMyselfChange = (checked: boolean) => {
    setIsSharedWith(checked ? 'mySelf' : '');
    setMySelfChecked(!mySelfChecked);
    setCompanyChecked(false);
  };

  const handleCompanyChange = (checked: boolean) => {
    setIsSharedWith(checked ? 'company' : '');
    setCompanyChecked(!companyChecked);
    setMySelfChecked(false);
  };

  const handleShareThought = () => {
    shareThoughts({ thoughtsText: thought, shareWith: isSharedWith });
    setThought('');
    setIsSharedWith('mySelf');
    collapseSidebar(true);
  };

  const isButtonsDisabled = () => {
    let isDisabled = true;

    if (thought && (companyChecked || mySelfChecked)) {
      isDisabled = false;
    }

    return isDisabled;
  };

  return (
    <div className={styleClasses}>
      <div className={styles.hsShareThoughtsCloseButtonContainer}>
        <Button isButton type="close" onClick={collapseSidebar}>
          Close
          <i className="icon icon-x" />
        </Button>
      </div>
      <div className={styles.hsShareThoughtsDetailsContainer}>
        <h3>Share your thoughts</h3>
        <div className={styles.hsShareThoughtsLabel}>
          <p>{question}</p>
          <p>
            <span className={styles.scoreSpan}>{score}</span>
          </p>
        </div>
        <div>
          <textarea
            placeholder="What’s on your mind?"
            onChange={handleInput}
            value={thought}
            ref={textareaRef}
          />
          <p className={styles.hsCharCounter}>{thought.length}/280 characters</p>
        </div>
        <div className={styles.hsShareThoughtsShareBox}>
          <span>Share with:</span>
          <Checkbox
            id="myself"
            text="Myself"
            color={color}
            onChange={handleMyselfChange}
            checked={mySelfChecked}
          />
          <Checkbox
            id="company"
            text="My organisation"
            color={color}
            onChange={handleCompanyChange}
            checked={companyChecked}
          />
        </div>
        <div className={styles.emptyBox}>
          {mySelfChecked && (
            <div>
              Sharing thoughts reminds us why we felt a certain way, so when we look back, we
              understand ourselves, allowing us to enjoy the positives and learn from the negatives.
            </div>
          )}
          {companyChecked && (
            <div className={styles.emptyBox}>
              {companyConfigData && companyConfigData?.shareThoughtOrganizationText ? (
                companyConfigData?.shareThoughtOrganizationText
              ) : (
                <div>
                  Sharing thoughts with {currentCompanyName} allows them to learn about your needs,
                  what you’re struggling with, and what support should be offered. They can also
                  respond and show you’ve been heard. Your thought will be anonymous.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.hsShareThoughtsShareButtonContainer}>
        <Button
          type="primary"
          isButton
          onClick={handleShareThought}
          isDisabled={isButtonsDisabled()}
        >
          Share thoughts
        </Button>
      </div>
    </div>
  );
};

interface ShareThoughtsProps {
  question: string;
  score: number;
  color: string;
  id: number;
  shareThoughts: (data: { shareWith: string; thoughtsText: string }) => void;
}

export default ShareThoughts;
