import useWindowDimensions from '@hooks/useWindowDimensions';
import { ExpertBookingRequestDetailsInterface } from '@interfaces/index';
import React, { FunctionComponent } from 'react';
import styles from './index.module.scss';

const RequestedUserInfo: FunctionComponent<any> = (props) => {
  const { requestDetails } = props;

  const { isDesktop, isTablet } = useWindowDimensions();

  return (
    <div className={styles.mainContainer}>
      {requestDetails && (
        <div className={styles.topSection}>
          <div className={styles.userDetailsSection}>
            <h2>Requested user details:</h2>
            <div className={styles.detailsContainer}>
              <div className={styles.detailItem}>
                <strong>Email:</strong>
                <span>{requestDetails?.user?.email || '-'}</span>
              </div>
              <div className={styles.detailItem}>
                <strong>Area:</strong>
                <span className={styles.infoImportant}>{requestDetails?.area || '-'}</span>
              </div>
              <div className={styles.detailItem}>
                <strong>Preferred Days:</strong>
                <span className={styles.infoImportant}>
                  {JSON.parse(requestDetails?.preferredDays).join(' | ')}
                </span>
              </div>
              <div className={styles.detailItem}>
                <strong>Timezone:</strong>
                <span>{requestDetails?.userTimezone}</span>
              </div>
              <div className={styles.detailItem}>
                <strong>Status:</strong>
                <span>{requestDetails?.done ? 'Completed' : 'Pending'}</span>
              </div>
              <div className={styles.detailItem}>
                <strong>Email Sent:</strong>
                <span>{requestDetails?.emailSent ? 'Yes' : 'No'}</span>
              </div>
              <div className={styles.detailItem}>
                <strong>Preferred Times:</strong>
                <span className={styles.infoImportant}>
                  {JSON.parse(requestDetails?.preferredTimes).join(' | ')}
                </span>
              </div>
            </div>
            <hr />
          </div>
        </div>
      )}
    </div>
  );
};

export interface RequestedUserInfoProps {
  requestDetails: ExpertBookingRequestDetailsInterface;
}

export default RequestedUserInfo;
