import { ApiResponse, ErrorDetail, PropertyInfo } from '@interfaces/index';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getAiChatInsights = async (
  userId: number,
  focusArea: string,
  focusAreaData: PropertyInfo | null,
  message: string,
  conversationHistory: {
    role: 'developer' | 'user' | 'assistant';
    content: any;
  }[],
) => {
  const formattedReq = {
    userId,
    focusArea,
    focusAreaData,
    message,
    conversationHistory,
  };

  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-chat-insights`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  const responseData: ApiResponse<{ message: string }> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};
