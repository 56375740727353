declare module '*.scss';
import { DarkColorMap } from '@enums/Colors';
import UserNotFoundImage from './assets/images/user-not-found.jpg';
import { ApiResponse, ErrorDetail } from './interfaces';

export const enum UserRoles {
  USER = 'user',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
  ADVISOR = 'advisor',
}

export type AuthResponse = {
  token: string;
  user: {
    created_at: string;
    email: string;
    company_id: number;
    id: number;
    role: UserRole;
    isAgree: boolean;
    updated_at: string;
  };
  userDetails: {
    forename: string;
    surname: string;
  };
};

export type userStatusResponse = {
  user_id: number;
  is_registered: boolean;
  is_joined: boolean;
  is_first_short_star_completed: boolean;
};

export type updateUserStatusRequest = {
  is_registered?: boolean;
  is_joined?: boolean;
  is_first_short_star_completed?: boolean;
};

export type ImportResults = {
  failed: ImportedRowType[];
  successful: ImportedRowType[];
};

export type ImportedRowType = {
  email: string;
  forename: string;
  surname: string;
  reason: string;
};

export type TwitterCardType = 'summary' | 'summary_large_image' | 'app' | 'player';

export type Coordinate = {
  x: number;
  y: number;
};

export type CoordinatePosition = {
  p1: Coordinate;
  p2: Coordinate;
  p3: Coordinate;
  p4: Coordinate;
};

export type RhombusCoordinates = {
  p1: Coordinate;
  p2: Coordinate;
  p3: Coordinate;
  p4: Coordinate;
  position: RhombusPosition;
  inner: CoordinatePosition;
};

export type StarConfig = {
  name: string;
  color: string;
  value: number[];
};

export type LocationItemType = {
  name: string;
  id: number;
};

export type SelectableOptionType = {
  label: string;
  value: any;
};

export interface UserFieldValues {
  email: string;
  surname: string;
  forename: string;
  seniority: Seniority;
  sex: Genders;
  payLevel: number;
  jobType: SelectableOptionType | null;
  jobTitle: SelectableOptionType | null;
  team: SelectableOptionType | null;
  location: SelectableOptionType | null;
  dob: string | null;
  joined: string | null;
  peopleLead: boolean;
  isAdmin: boolean;
  userRole: 'admin' | 'user' | 'advisor' | 'superAdmin';
}

export type JobTypeType = {
  name: string;
  id: number;
};

export type SeniorityType = {
  name: string;
  id: number;
};

export type JobTitleType = {
  name: string;
  id: number;
};

export type TeamItemType = {
  name: string;
  id: number;
};

export const enum RhombusPosition {
  Top = 'top',
  RightTop = 'rightTop',
  RightBottom = 'rightBottom',
  Bottom = 'bottom',
  LeftTop = 'leftTop',
  LeftBottom = 'leftBottom',
}

export type SelectedUser = User | undefined;

export interface User {
  row: number;
  id: number;
  email: string;
  role: string;
  company: string;
  surname: string;
  forename: string;
  jobType: string;
  jobTitle: string;
  team: string;
  seniority: string;
  sex: string;
  payLevel: number | string;
  location: string;
  dob: string;
  joined: string;
  peopleLead: string;
  speakupAlertEligibility: boolean | string;
}

type Seniority = 'non_specified' | 'junior' | 'mid_level' | 'senior' | 'c_level';

type Genders = 'male' | 'female' | 'non_binary' | 'non_specified';

type PeopleLead = '' | 'yes' | 'no';

export interface UsersFilterData {
  location: string;
  team: string;
  gender: Genders;
  seniority: 'string';
  jobType: 'string';
  jobTitle: 'string';
  payLevel: number;
  peopleLead: PeopleLead;
  speakupAlertEligibility?: '' | 'yes' | 'no';
}

export interface CompanyDetails {
  company_logo: string;
  company_name: string;
  company_url: string;
  id: number;
  sign_up_text: string;
  login_text: string;
  share_thoughts: boolean;
  company_type: string | null;
}

export interface PublicCompanyDetails {
  companyLogo: string;
  companyName: string;
  companyUrl: string;
  hideHapstarLogo: boolean;
  id: number;
  signUpText: string;
  loginText: string | null;
  shareThoughts: boolean;
  platformName: string | null;
}

export interface CompanyConfigData {
  showInstantHelp: boolean;
  instantHelpDescription: string;
  showSpeakUp: boolean;
  welcomeMessageText: string | null;
  expertsEnabled: boolean;
  talkToAdvisor: boolean;
  talkToAdvisorMessage: string | null;
  talkToAdvisorCompleteMessage: string | null;
  hideHapstarLogo: boolean;
  dashboardBgColour: string | null;
  dashboardNewsOnTop: boolean;
  platformName: string | null;
  shareThoughtOrganizationText: string | null;
  participantReportUserInsightsEnabled: boolean;
}

export interface InstantHelpData {
  id: number;
  tag: string;
  sections: InstantHelpSection[];
}

export interface InstantHelpSection {
  id: number;
  instant_help_sections_id: number;
  order_id: number;
  title: string;
  description_text: string;
  instantHelpActions: InstantHelpAction[];
}

export interface InstantHelpAction {
  id: number;
  label: string;
  type: 'phone' | 'email' | 'url' | 'text';
  value: string;
  icon: string;
}

export interface HelpLimitData {
  id: number;
  question_id: number;
  limit: number;
}

export const enum LogTypes {
  INSTANT_HELP_DISPLAYED = 'starInstantHelpDisplayed',
  INSTANT_HELP_CLICKED = 'starInstantHelpClicked',
  SPEAKUP_DISPLAYED = 'starSpeakUpDisplayed',
  INSTANT_HELP_NAVIGATED = 'instantHelpNavigated',
  ACTION_ALERT_EMAIL_CLICKED = 'actionAlertEmailClicked',
  LEARNING_ITEM_CLICKED = 'learningItemClicked',
  INSTANT_HELP_NAVIGATED = 'instantHelpNavigated',
  AUDIO_PLAYED = 'audioPlayed',
  IMPROVE_PLAN_CREATED = 'improvePlanCreated',
  ACTION_ALERT_EMAIL_CLICKED = 'actionAlertEmailClicked',
  EXPERTS_PAGE_VISITED = 'expertsPageVisited',
  EXPERT_PROFILE_CLICKED = 'expertProfileClicked',
  EXPERT_BOOKING_EMAIL_SENT = 'expertsBookingEmailSent',
  EXPERT_SESSION_REMINDER = 'expertSessionReminder',
  EMPLOYER_DASHBOARD_USER_IMPORT = 'employerDashboardUserImport',
  EMPLOYER_DASHBOARD_USER_EXPORT = 'employerDashboardUserExport',
  SSO = 'sso',
  DIRECT_LINK = 'directLink',
  NOT_AGREED_TO_TERMS_EMAIL_SENT = 'notAgreedToTermsEmailSent',
  TERMS_ACCEPTED_BUT_NOT_CHECKED_IN_EMAIL_SENT = 'termsAcceptedButNotCheckedInEmailSent',
  NO_DEEP_DIVE_TAKEN_EMAIL_SENT = 'noDeepDiveTakenEmailSent',
  NOT_AGREED_TO_TERMS_EMAIL_CLICKED = 'notAgreedToTermsEmailClicked',
  TERMS_ACCEPTED_BUT_NOT_CHECKED_IN_EMAIL_CLICKED = 'termsAcceptedButNotCheckedInEmailClicked',
  NO_DEEP_DIVE_TAKEN_EMAIL_CLICKED = 'noDeepDiveTakenEmailClicked',
  REQUEST_EXPERT_SESSION_PAGE_VISITED = 'requestExpertSessionPageVisited',
}

export interface SpeakUpResponseInterface {
  questionnaireId: number;
  questionnaire: string;
  speakUpId: number;
  speakUpMessage: string;
  shareIdentity: boolean;
  speakUpCreatedDate: string;
  speakUpUpdatedDate: string;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  responded: boolean;
  response?: {
    responseId: number;
    responseMessage: string;
    responseSeen: boolean;
    responseCreated: string;
    responderFirstName: string;
    responderLastName: string;
  };
  isSpeakup?: boolean;
}

export interface ThoughtResponseInterface {
  thought_response_id: number;
  thought_id: number;
  questionnaire_name: string;
  category_name: string;
  title: string;
  score: number;
  thoughts_response_text: string;
  created_at: string;
  updated_at: string;
  color: string;
  questionnaire_id: number;
  shareWith: string;
  thoughtsText: string;
  responded: boolean;
  isSpeakup?: boolean;
  response?: ResponsesForThoughtsInterface;
}

export interface ResponsesForThoughtsInterface {
  category_name: string;
  color: string;
  created_at: string;
  questionnaire_name: string;
  score: number;
  thought_id: number;
  thought_response_id: number;
  thoughts_response_text: string;
  title: string;
  updated_at: string;
}

export interface ThoughtDataInterface {
  questionnaireId: number;
  questionnaireName: string;
  categoryName: string;
  thoughtId: number;
  title: string;
  score: number;
  shareWith: string;
  thoughtsText: string;
  responded: boolean;
  color: string;
  createdAt: string;
  updatedAt: string;
  response?: {
    responseId: number;
    responseMessage: string;
    responseCreated: string;
  };
  responsesSeen?: boolean;
  isSpeakup?: boolean;
}

export interface SpeakUpDataInterface {
  questionnaireId: number;
  questionnaire: string;
  speakUpId: number;
  speakUpMessage: string;
  shareIdentity: false;
  speakUpCreatedDate: string;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  scores: {
    instantHelpTriggerId: number;
    assessment_id: number;
    questionId: number;
    questionText: string;
    score: number;
  }[];
  response?: {
    responderFirstName: string;
    responderLastName: string;
    responseCreated: string;
    responseId: number;
    responseMessage: string;
    responseSeen: boolean;
  };
}

export interface PaginationInterface {
  total?: number;
  lastPage?: number;
  prevPage?: number;
  nextPage?: number;
  perPage?: string;
  currentPage?: number;
  from?: number;
  to?: number;
}

export interface ShortStarQuestionsInterface {
  categoryId: number;
  generalHappinessQuestion: boolean;
  isActive: boolean;
  isShortQuestionnaire: boolean;
  questionId: number;
  questionInfo: string;
  questionText: string;
  questionnaireColorScheme: 'standard' | 'dark' | 'light';
  questionnaireId: number;
  specialWeighting: boolean;
  colorTheme: string;
  icon: string;
}

export type ShortStatContentTypes =
  | 'WELCOME_SECTION'
  | 'GENERAL_HAPPINESS_QUESTION_SECTION'
  | 'QUESTIONS_SECTION'
  | 'SCORE_DIFFERENCE_SECTION'
  | 'REFLECTION_SECTION'
  | 'FOCUS_SECTION'
  | 'IMPROVE_SECTION'
  | 'COMPLETE_SHORT_STAR';

export const shortStarContent = {
  WELCOME_SECTION: 'WELCOME_SECTION',
  GENERAL_HAPPINESS_QUESTION_SECTION: 'GENERAL_HAPPINESS_QUESTION_SECTION',
  QUESTIONS_SECTION: 'QUESTIONS_SECTION',
  SCORE_DIFFERENCE_SECTION: 'SCORE_DIFFERENCE_SECTION',
  REFLECTION_SECTION: 'REFLECTION_SECTION',
  FOCUS_SECTION: 'FOCUS_SECTION',
  IMPROVE_SECTION: 'IMPROVE_SECTION',
  COMPLETE_SHORT_STAR: 'COMPLETE_SHORT_STAR',
};

export interface UserImprovePlanActions {
  completeAt: string | null;
  createdAt: string | null;
  done: boolean | null;
  id: number;
  progress: number;
  question: QuestionInterface;
  questionId: number;
  updatedAt: string;
  userId: number;
  actionCount: number;
  improvePlansFocusAreas?: PlanFocusAreaInterface[];
}

export interface QuestionInterface {
  categoryId: number;
  color: string;
  createdAt: string;
  generalHappinessQuestion: boolean;
  icon: string;
  id: number;
  questionInfo: string;
  questionText: string;
  specialWeighting: boolean;
  updatedAt: string;
}

export interface ImprovePlanAction {
  improvePlanId: number;
  improvePlanActionId: number;
  improvePlanActionDone: boolean;
  actionTitle: string;
  actionDescription: string | null;
  improvePlanActionDueDate: string;
  improvePlanActionDueDays: number;
  improvePlanActionRemoved: boolean;
  improvePlanActionRemovedAt: string | null;
  improvePlanActionStartAt: string | null;
  improvePlanActionCompletedAt: string | null;
  improvePlanActionCreatedAt: string;
  improvePlanActionUpdatedAt: string;
  actionImage: string | null;
  actionId: number | null;
  actionType: string;
  actionURL: string | null;
  actionButton: string | null;
}

export interface ImprovePlan {
  id: number;
  userId: number;
  questionId: number;
  done: boolean;
  completeAt: string | null;
  progress: number;
  createdAt: string;
  updatedAt: string;
  improvePlanActions: ImprovePlanAction[];
}

export interface DeepDiveQuestionnairePlan {
  id: number;
  shortQuestionId: number;
  deepDiveId: number;
  createdAt: string;
  updatedAt: string;
  question: {
    id: number;
    categoryId: number;
    questionText: string;
    questionInfo: string;
    specialWeighting: boolean;
    generalHappinessQuestion: boolean;
    color: string;
    icon: string;
    createdAt: string;
    updatedAt: string;
    improvePlans?: DeepDiveQuestionnaireImprovePlan[];
  };
}

interface DeepDiveQuestionnaireImprovePlan {
  id: number;
  userId: number;
  improvePlansFocusAreas: ImprovePlanFocusAreas[];
  questionId: number;
  done: boolean;
  completeAt: string | null;
  progress: number;
  createdAt: string;
  updatedAt: string;
}

interface ImprovePlanFocusAreas {
  id: number;
  improvePlanId: number;
  questionId: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface FocusAreaQuestionInterface {
  color?: string;
  hint?: string;
  id: number;
  isSelected: boolean;
  name: string;
  score?: number;
  scoreDifference?: number;
  shareWith?: string;
  specialWeighting?: boolean;
  thoughtsText?: string;
  improvePlanId?: number;
  questionId?: number;
  active?: boolean;
}

interface PlanFocusAreaInterface {
  id: number;
  improvePlanId: number;
  questionId: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  question: {
    id: number;
    categoryId: number;
    questionText: string;
    questionInfo: string;
    specialWeighting: boolean;
    generalHappinessQuestion: boolean;
    color: string;
    icon: string;
    createdAt: string;
    updatedAt: string;
  };
}

export const ImprovePlanTypes = {
  SHORT_STAR: 'SHORT_STAR',
  DEEP_DIVE: 'DEEP_DIVE',
  LEARNING_ARTICLE: 'LEARNING_ARTICLE',
};

function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export const isIOS = iOS();

export enum ExpertBookingTypes {
  VIDEO = 1,
  AUDIO = 2,
  IM = 4,
  FACE_TO_FACE = 8,
}

export enum SessionDurations {
  DURATION_25_MINS = 1,
  DURATION_50_MINS = 2,
  DURATION_80_MINS = 4,
}

export enum ExpertLogTypes {
  EXPERTS_PAGE_VISITED = 'expertsPageVisited',
  EXPERT_PROFILE_CLICKED = 'expertProfileClicked',
}

export const USER_NOT_FOUND_IMAGE = UserNotFoundImage;

export const castResponse = <T>(response: ApiResponse<any> | ApiResponse<ErrorDetail>) =>
  response.responseObject as unknown as T;

export const ReminderTypes = {
  NOT_AGREED_TO_TERMS: 'notAgreedToTerms',
  TERMS_ACCEPTED_BUT_NOT_CHECKED_IN: 'termsAcceptedButNotCheckedIn',
  NO_DEEP_DIVE_TAKEN: 'noDeepDiveTaken',
};

export enum ExpertsBookingStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export const CURRENT_TIMEZONE_NAME = Intl.DateTimeFormat().resolvedOptions().timeZone;

export enum AvailabilityTimeNames {
  AVAILABILITY = 'Availability',
  EARLY_MORNING = 'Early Morning (05.00 - 09.00)',
  MORNING = 'Morning (09.00 - 13.00)',
  AFTERNOON = 'Afternoon (13.00 - 17.00)',
  EVENING = 'Evening (17.00 - 21.00)',
  LATE_EVENING = 'Late Evening (21.00 - 01.00)',
  LATE_NIGHT = 'Late Night (01:00 - 05:00)',
}

export const EXPERTS_TIME_FILTERS = [
  {
    id: -9999,
    name: AvailabilityTimeNames.AVAILABILITY,
    value: null,
  },
  {
    id: 1,
    name: AvailabilityTimeNames.EARLY_MORNING,
    value: {
      start: '05:00:00',
      end: '09:00:00',
    },
  },
  {
    id: 2,
    name: AvailabilityTimeNames.MORNING,
    value: {
      start: '09:00:00',
      end: '13:00:00',
    },
  },
  {
    id: 3,
    name: AvailabilityTimeNames.AFTERNOON,
    value: {
      start: '13:00:00',
      end: '17:00:00',
    },
  },
  {
    id: 4,
    name: AvailabilityTimeNames.EVENING,
    value: {
      start: '17:00:00',
      end: '21:00:00',
    },
  },
  {
    id: 5,
    name: AvailabilityTimeNames.LATE_EVENING,
    value: {
      start: '21:00:00',
      end: '01:00:00',
    },
  },
  {
    id: 6,
    name: AvailabilityTimeNames.LATE_NIGHT,
    value: {
      start: '01:00:00',
      end: '05:00:00',
    },
  },
];

export const HourBuffCategories = {
  1: '0-3',
  2: '3-6',
  3: '6-9',
  4: '9-12',
  5: '12-15',
  6: '15-18',
  7: '18-21',
  8: '21-24',
  9: '24-28',
};

export const TALK_TO_ADVISOR_DEFAULT_MESSAGE = `<h4>Would you like to discuss any of these focus areas with someone?</h4>
<h5>
  Getting expert guidance can help you develop practical strategies and create a clear
  action plan for improvement.
</h5>
<br />
<b>
  Your advisor will be notified of your interests and can connect you with the right
  support.
</b>`;

export const TALK_TO_ADVISOR_AREAS_DISCUSS_MESSAGE = `<h4>Which areas would you like to discuss?</h4>
<h5>Select all that apply</h5>`;

export const TALK_TO_ADVISOR_COMPLETE_DEFAULT_MESSAGE = `<h4>Thank you for sharing</h4>
<h5>Your advisor will be in touch to discuss the support available for your chosen areas.</h5>`;

const defaultDarkColor = '#0072b0'; //sky-blue-dark

export const getDarkColor = (color: string): string => {
  const darkColor = DarkColorMap[color];

  return darkColor;
};

export const DEFAULT_HS_BLUE_COLOR = '#079ef1';

/**
 * Darkens a hex color by a specified percentage.
 * @param color - The hex color code (e.g., '#780245').
 * @param amount - Percentage to darken (0-100).
 * @returns A darker hex color.
 */
export const darkenColor = (color: string, amount: number): string => {
  if (!/^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(color)) {
    return color;
  }

  // Convert hex to RGB
  const hexToRgb = (hex: string) => {
    const normalizedHex =
      hex.length === 4 ? `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}` : hex;

    const bigint = parseInt(normalizedHex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  const rgbToHex = (r: number, g: number, b: number) =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

  const rgbToHsl = (r: number, g: number, b: number) => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h = 0,
      s = 0,
      l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return { h, s, l };
  };

  const hslToRgb = (h: number, s: number, l: number) => {
    const hueToRgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let r: number, g: number, b: number;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  };

  const { r, g, b } = hexToRgb(color);
  const { h, s, l } = rgbToHsl(r, g, b);

  // Decrease lightness to make it darker
  const newL = Math.max(0, l - amount / 100);

  const { r: newR, g: newG, b: newB } = hslToRgb(h, s, newL);

  return rgbToHex(newR, newG, newB);
};

export const getBackgroundStyle = (
  color,
  defaultColor,
  darkenAmountForDefault,
  darkenAmountForCustom,
) => ({
  backgroundColor: darkenColor(
    color || defaultColor,
    color === defaultColor ? darkenAmountForDefault : darkenAmountForCustom,
  ),
});

export const InitInfoDialog = {
  open: false,
  top: '',
  right: '',
  firstDescription: '',
  description: '',
  title: '',
  htmlDescription: null,
};

export const UserRolesList = [
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Advisor',
    value: 'advisor',
  },
  {
    label: 'Super Admin',
    value: 'superAdmin',
  },
];

export const CompanyTypes = {
  EMPLOYABILITY: 'employability',
};

export const SYSTEM_DEFAULT_QUESTIONNAIRES = [1, 2, 3, 5, 6, 7, 4];

export const EXPERT_SESSION_REQUEST_AREAS = [
  { value: '', label: 'Select an area', disabled: true },
  { value: 'Work Related Issues', label: 'Work Related Issues' },
  { value: 'Personal & Family Life', label: 'Personal & Family Life' },
  { value: 'Mental Health Support', label: 'Mental Health Support' },
  { value: 'Traumatic Event', label: 'Traumatic Event' },
  { value: 'Prefer not to specify', label: 'Prefer not to specify' },
];

export const EXPERT_SESSION_REQUEST_PREFERRED_DAYS = [
  'ASAP - No preference',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const EXPERT_SESSION_REQUEST_PREFERRED_TIMES = [
  'Early Hours (12am - 6am)',
  'Morning (6am - 12pm)',
  'Afternoon (12pm - 6pm)',
  'Evening (6pm - 12am)',
];

export const URGENCY_INDEX_INFO = `
The Intervention Urgency Index is a 7-point scale (1-7) that measures how urgently a participant may need support.
The score is calculated based on several factors from the last 90 days including:
<br/><br/>

<ul>
  <li>Below-threshold scores on key wellbeing indicators</li>
  <li>Selection of focus areas</li>
  <li>Requests to speak with advisor</li>
  <li>Use of emergency resources</li>
  <li>Engagement with support services</li>
  <li>Comparison with programme and Hapstar averages</li>
</ul>

The higher the score on the 7-point scale, the greater the combination of concerning indicators we've detected.
A higher score indicates multiple high-risk factors are present, suggesting this participant should be prioritised for immediate support and intervention.
`;

export const CHECK_IN_SCORES_INFO = `
Check-in scores reflect the participant's self-reported ratings (1-10) across six core wellbeing pillars:
<br/><br/>

<ul>
  <li>Overall wellbeing</li>
  <li>Mental health</li>
  <li>Physical health</li>
  <li>Financial wellbeing</li>
  <li>Social connections</li>
  <li>Career satisfaction</li>
</ul>

Trends show score changes since last check-in:
<br/>
↑ Improvement 
<br/>
↓ Decline 
<br/>
→ No significant change
<br/><br/>

Comparisons show how scores relate to programme averages and average Hapstar benchmarks.
`;

export const DEEP_DIVE_SCORES_INFO = `
Deep dive scores provide detailed insights from 24 specific questions in each wellbeing pillar. These comprehensive assessments:
<br/><br/>

<ul>
  <li>Give granular view of specific challenges</li>
  <li>Help identify focus areas for improvement</li>
  <li>Track progress over time</li>
  <li>Compare against programme average and Hapstar average benchmarks</li>
</ul>

Scores are dated to show when the deep dive was completed. Consider requesting a new deep dive if scores are more than 3 months old.
`;

export const ADVISOR_RECOMMENDATIONS_INFO = `
These recommendations are automatically generated by using AI insights based on:
<br/><br/>

<ul>
  <li>Significant changes in scores across check-ins</li>
  <li>Patterns in deep dive responses</li>
  <li>Correlations between drivers</li>
  <li>User engagement with platform resources</li>
  <li>Comparison with programme and Hapstar averages</li>
  <li>Selection of focus areas</li>
  <li>Use of support services</li>
</ul>
`;

export const ACTIONS_INFO = `
Actions are tasks to help improve wellbeing, organised in three categories:
<br/><br/>

<b>Pending User Actions</b>
<p>Actions created by users themselves or by their advisor</p>

<b>Pending Hapstar Actions</b>
<p>Actions created by Hapstar automatically aligned with participant's focus areas</p>

<b>Completed Actions</b>
<p>Actions marked as completed by the participant. </p>
<br/>

<b>As an advisor, you can create actions for the participant by using Add Action button.</b>
`;
