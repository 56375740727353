import { getAiChatInsights } from '@api/chatAssistant';
import { getFocusAndProgressData } from '@api/participantReporting';
import LogoIcon from '@assets/images/icons/logo-icons.svg';
import Img from '@components/Atoms/Img';
import Modal from '@components/Atoms/Modal';
import ImprovePlanModal from '@components/Molecules/ImprovePlanModal';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { PropertyInfo } from '@interfaces/index';
import { Mic, Person, VolumeUp } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
} from '@mui/material';
import { sanitizeHtml } from '@utils/string.util';
import { castResponse } from 'custom.d';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';

interface Message {
  role: 'developer' | 'user' | 'assistant';
  content: any;
}

export interface FocusAndProgressRootInterface {
  [key: string]: PropertyInfo | null;
}

export const ChatBotPortal = ({ children }: { children: React.ReactNode }) => {
  const portalRoot = document.getElementById('chat-bot-modal') as Element;

  return ReactDOM.createPortal(children, portalRoot);
};

const Chatbot = ({ modalOpen, onClose }: ChatbotProps) => {
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user || '{}');
  const { isMobile } = useWindowDimensions();
  const [message, setMessage] = useState<string>('');
  const [conversationHistory, setConversationHistory] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [focusArea, setFocusArea] = useState<string>('');
  const [focusAreaData, setFocusAreaData] = useState<PropertyInfo | null>(null);
  const [isListening, setIsListening] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeIndexPlay, setActiveIndexPlay] = useState<number | null>(null);
  const [recognitionInstance, setRecognitionInstance] = useState<any>(null);
  const [focusAreaOptions, setFocusAreaOptions] = useState<FocusAndProgressRootInterface | null>(
    null,
  );
  const [focusAreaDataLoading, setFocusAreaDataLoading] = useState<boolean>(true);
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const [openImprovePlanCreateDialog, setOpenImprovePlanCreateDialog] = useState<{
    open: boolean;
    data: {
      questionId: number;
      actionContent: string;
      questionName: string;
      actionButton: string | null;
      actionUrl: string | null;
    };
  }>({
    open: false,
    data: {
      questionId: 0,
      actionContent: '',
      questionName: '',
      actionButton: null,
      actionUrl: null,
    },
  });

  useEffect(() => {
    if (parsedUser?.id) {
      getFocusAreaAndProgress();
    }
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [conversationHistory]);

  const getFocusAreaAndProgress = async () => {
    try {
      const response = await getFocusAndProgressData(parsedUser?.id, null, null);

      if (response?.success) {
        const data = castResponse<FocusAndProgressRootInterface>(response);
        data.Other = null;

        setFocusAreaOptions(data);
      } else {
        setFocusAreaOptions(null);
      }
    } catch (error) {
      setFocusAreaDataLoading(false);
      setFocusAreaOptions(null);
    } finally {
      setFocusAreaDataLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim()) {
      const newMessage: Message = { role: 'user', content: message };
      setConversationHistory((prevHistory) => [...prevHistory, newMessage]);
      setMessage('');
      setIsLoading(true);

      try {
        const responseData = await getAiChatInsights(
          parsedUser?.id,
          focusArea.toLowerCase() === 'other'
            ? conversationHistory?.length > 1
              ? message
              : `Other area - ${message}`
            : focusArea,
          focusAreaData || null,
          message,
          conversationHistory,
        );

        if (responseData.success) {
          const dt = castResponse<{ message: string }>(responseData);

          const assistantMessage: Message = { role: 'assistant', content: dt.message || '' };
          setConversationHistory((prevHistory) => [...prevHistory, assistantMessage]);
        } else {
          setConversationHistory((prevHistory) => [
            ...prevHistory,
            {
              role: 'assistant',
              content: 'Sorry, I am unable to provide a response at the moment.',
            },
          ]);
        }
      } catch (error) {
        setConversationHistory((prevHistory) => [
          ...prevHistory,
          { role: 'assistant', content: 'Sorry, I am unable to provide a response at the moment.' },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleClick = (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.id === 'changeFocusArea') {
        setFocusArea('');
        setFocusAreaData(null);
        setIsLoading(false);
        setConversationHistory([]);
      }
    };

    const parentElement = document.querySelector(`.${styles.chatBox}`);
    if (parentElement) {
      parentElement.addEventListener('click', handleClick);
    }

    return () => {
      if (parentElement) {
        parentElement.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const handleFocusAreaChange = async (selectedFocusArea: string) => {
    if (selectedFocusArea === 'Other') {
      setFocusArea('Other');
      setFocusAreaData(null);
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        {
          role: 'assistant',
          content: 'What other area would you like to discuss?',
        },
      ]);

      return;
    }

    setFocusArea(selectedFocusArea);
    const data = focusAreaOptions && focusAreaOptions[selectedFocusArea];
    setFocusAreaData(data);

    setIsLoading(true);
    const focusAreaChangeMessage: Message = {
      role: 'assistant',
      content: `You are now focusing on ${selectedFocusArea}. <span id="changeFocusArea" class="${styles.changeFocusArea}">Change</span>`,
    };
    setConversationHistory([focusAreaChangeMessage]);

    try {
      const responseData: any = await getAiChatInsights(
        parsedUser?.id,
        selectedFocusArea,
        data,
        '',
        [],
      );

      if (responseData.success) {
        const dt = castResponse<{ message: string }>(responseData);

        const assistantMessage: Message = { role: 'assistant', content: dt.message || '' };
        setConversationHistory((prevHistory) => [...prevHistory, assistantMessage]);
      } else {
        setConversationHistory((prevHistory) => [
          ...prevHistory,
          { role: 'assistant', content: 'Sorry, I am unable to provide a response at the moment.' },
        ]);
      }
    } catch (error) {
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { role: 'assistant', content: 'Sorry, I am unable to provide a response at the moment.' },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    onClose();
    setFocusArea('');
    setFocusAreaData(null);
    setIsLoading(false);
    setConversationHistory([]);
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [modalOpen]);

  const handleTextToSpeech = (text: string) => {
    if (!('speechSynthesis' in window)) {
      return;
    }

    const ensureVoice = () =>
      new Promise<SpeechSynthesisVoice>((resolve) => {
        const loadVoices = () => {
          const voices = window.speechSynthesis.getVoices();
          const femaleVoice = voices.find((voice) =>
            voice.name.toLowerCase().includes('google us english'),
          );

          if (femaleVoice) {
            resolve(femaleVoice);
          } else {
            resolve(voices[0] || null);
          }
        };

        if (window.speechSynthesis.getVoices().length > 0) {
          loadVoices();
        } else {
          window.speechSynthesis.addEventListener('voiceschanged', loadVoices);
        }
      });

    const speakChunks = async (inputText: string) => {
      const CHUNK_SIZE = 200;
      const chunks = inputText.match(new RegExp(`.{1,${CHUNK_SIZE}}(\\s|$)`, 'g')) || [];
      const voice = await ensureVoice();

      const speakChunk = (chunk: string) =>
        new Promise<void>((resolve, reject) => {
          const utterance = new SpeechSynthesisUtterance(chunk);

          if (voice) {
            utterance.voice = voice;
          }

          utterance.onstart = () => setIsPlaying(true);
          utterance.onend = () => {
            setIsPlaying(false);
            resolve();
          };

          utterance.onerror = (e) => {
            setIsPlaying(false);
            reject(e);
          };

          window.speechSynthesis.speak(utterance);
        });

      for (let i = 0; i < chunks.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await speakChunk(chunks[i]);
      }
    };

    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
    } else {
      speakChunks(text);
    }
  };

  const startVoiceRecognition = () => {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (recognitionInstance) {
      recognitionInstance.stop();
      setIsListening(false);
      setRecognitionInstance(null);

      return;
    }

    const recognition = new SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = false;

    recognition.onstart = () => setIsListening(true);

    recognition.onresult = (event: any) => {
      const voiceMessage = event.results[0][0].transcript;
      setMessage(voiceMessage);

      //   if ('speechSynthesis' in window) {
      //     const utterance = new SpeechSynthesisUtterance(voiceMessage);

      //     const voices = window.speechSynthesis.getVoices();
      //     const femaleVoice = voices.find((voice) =>
      //       voice.name.toLowerCase().includes('google us english'),
      //     );
      //     if (femaleVoice) {
      //       utterance.voice = femaleVoice;
      //     }

      //     window.speechSynthesis.speak(utterance);
      //   } else {
      //     console.error('SpeechSynthesis API not supported in this browser.');
      //   }
    };

    recognition.onerror = (event: any) => {
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
      setRecognitionInstance(null);
    };

    recognition.start();
    setRecognitionInstance(recognition);
  };

  const handleClickMessage = (event: any) => {
    event.preventDefault();

    // Traverse up the DOM to find the closest 'a' tag
    let targetElement = event.target;
    while (targetElement && targetElement.tagName !== 'A') {
      targetElement = targetElement.parentElement;
    }

    // If no 'a' tag is found, exit
    if (!targetElement) {
      return;
    }

    const href = targetElement.getAttribute('href');

    // If no href, exit
    if (!href) {
      return;
    }

    // If the link has the 'ai-learning' class, open it in a new tab
    if (targetElement.classList.contains('ai-learning')) {
      window.open(href, '_blank', 'noopener,noreferrer');

      return;
    }

    // Handle non-'ai-learning' links
    try {
      const url = new URL(href, window.location.origin);

      const questionId = url.searchParams.get('questionId');
      const actionContent = url.searchParams.get('actionContent');
      const queName = url.searchParams.get('focusArea');
      const actionBtn = url.searchParams.get('actionButton');
      const actionUrl = url.searchParams.get('url');

      const qId = Number(questionId);

      // Open the link directly if no questionId
      if (!questionId) {
        window.location.href = href;

        return;
      }

      // Open the improve plan dialog if questionId exists
      if (qId) {
        setOpenImprovePlanCreateDialog({
          open: true,
          data: {
            questionId: qId,
            actionContent: actionContent || '',
            questionName: queName || '',
            actionButton: actionBtn || null,
            actionUrl: actionUrl || null,
          },
        });
      }
    } catch (error) {
      setOpenImprovePlanCreateDialog({
        open: false,
        data: {
          questionId: 0,
          actionContent: '',
          questionName: '',
          actionButton: null,
          actionUrl: null,
        },
      });
    }
  };

  const stripHTML = (html: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    return tempDiv.textContent || tempDiv.innerText || '';
  };

  return (
    <ChatBotPortal>
      <Modal open={modalOpen} setOpen={closeModal} className={styles.chatBotModal} overlay>
        <Box className={styles.chatContainer}>
          <Box className={styles.header}>
            <h1 className={styles.title}>Hapstar Wellbeing Assistant (v1)</h1>
            <Box className={styles.icons}>
              {focusArea && (
                <IconButton
                  onClick={() => {
                    setFocusArea('');
                    setFocusAreaData(null);
                    setIsLoading(false);
                    setConversationHistory([]);
                  }}
                >
                  <RefreshIcon style={{ color: '#fff' }} />
                </IconButton>
              )}

              <IconButton onClick={closeModal}>
                <CloseIcon style={{ color: '#fff' }} />
              </IconButton>
            </Box>
          </Box>

          {focusAreaDataLoading && (
            <Box className={styles.focusLoaderContainer}>
              <Skeleton variant="text" width="50%" height={40} />
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="90%" height={30} />
              <Skeleton variant="text" width="90%" height={30} />
              <Skeleton variant="text" width="90%" height={30} />
            </Box>
          )}

          {!focusArea && !focusAreaDataLoading && (
            <>
              <h6 className={styles.focusTitle}>Select an area you need to talk about</h6>
              <RadioGroup
                value={focusArea}
                onChange={(e) => handleFocusAreaChange(e.target.value)}
                className={styles.radioGroup}
                row
              >
                {focusAreaOptions &&
                  Object.keys(focusAreaOptions).map((area) => (
                    <FormControlLabel
                      key={area}
                      value={area}
                      control={<Radio />}
                      label={area}
                      className={styles.radioOption}
                    />
                  ))}
              </RadioGroup>
            </>
          )}

          <Box className={styles.chatBox} ref={chatBoxRef}>
            {conversationHistory.map((msg, index) => (
              <Box key={index} className={`${styles.message} ${styles[msg.role]}`}>
                <strong>
                  {/* {msg.role === 'user' ? <Person /> : <SmartToy />} {msg.role}: */}
                  {msg.role === 'user' ? (
                    <Person className={styles.roleIcon} />
                  ) : (
                    <Img src={LogoIcon} alt="Logo" className={styles.roleIcon} />
                  )}{' '}
                </strong>
                <div
                  className={styles.messageContent}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(msg.content),
                  }}
                  onClick={handleClickMessage}
                />
                {msg.role !== 'user' && (
                  <VolumeUp
                    className={`${styles.speakerIcon} ${
                      activeIndexPlay === index && isPlaying ? styles.active : ''
                    }`}
                    onClick={() => {
                      setActiveIndexPlay(index);
                      handleTextToSpeech(stripHTML(msg.content));
                    }}
                  />
                )}
              </Box>
            ))}

            {isLoading && (
              <Box className={`${styles.message} ${styles.assistant}`}>
                {' '}
                <strong>
                  <Img src={LogoIcon} alt="Logo" className={styles.roleIcon} />
                </strong>
                <div className={styles.loadingContainer}>
                  <Skeleton variant="text" width="100%" height={20} className={styles.skeleton} />
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="100%" height={20} />
                </div>
              </Box>
            )}
          </Box>

          {focusArea && (
            <div className={styles.inputContainer}>
              <TextField
                fullWidth
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message..."
                disabled={isLoading}
                className={styles.messageInput}
                autoComplete="off"
              />
              <IconButton onClick={startVoiceRecognition} disabled={isLoading || isPlaying}>
                <Mic className={`${styles.voiceIcon} ${isListening ? styles.active : ''}`} />
              </IconButton>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                disabled={isLoading || !message.trim()}
                className={styles.sendButton}
              >
                {isLoading ? '....' : 'Send'}
              </Button>
            </div>
          )}
        </Box>
      </Modal>

      <ImprovePlanModal
        openImprovePlanCreateDialog={openImprovePlanCreateDialog}
        setOpenImprovePlanCreateDialog={setOpenImprovePlanCreateDialog}
        isMobile={isMobile}
      />
    </ChatBotPortal>
  );
};

interface ChatbotProps {
  modalOpen: boolean;
  onClose: () => void;
}

export default Chatbot;
