import React, { Reducer, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import LinkText from '@components/Atoms/Link';
import useGoNative from '@hooks/useGoNative';
import { LoginTypes } from '@api/auth/login';
import { toast } from 'react-toastify';
import { getCompanyDataByDomain, getCompanyDetailsByCompanyCode } from '@api/auth/company';
import { registerUser } from '@api/users';
import RegistrationForm from '../RegistrationForm';
import GetCompanyCodeForm from '../GetCompanyCodeForm';
import styles from './index.module.scss';

interface FormInitialState {
  email: string | undefined;
  isAgree: boolean | undefined;
}

enum DispatchTypes {
  'REGISTER_INPUT',
}

interface Action {
  type: DispatchTypes;
  values: { email?: string; isAgree?: boolean };
}

const formInitialState: FormInitialState = {
  email: '',
  isAgree: false,
};

const formReducer: Reducer<FormInitialState, Action> = (state, action) => {
  if (action.type === DispatchTypes.REGISTER_INPUT) {
    return {
      ...state,
      email: action.values.email,
      isAgree: action.values.isAgree,
    };
  }

  return formInitialState;
};

const SignUp: React.FunctionComponent<SignUpProps> = (props) => {
  const { content } = props;
  let { companyURL, organizationId } = props;
  const history = useHistory();
  const isMobileApp = useGoNative();
  const [getCompanyCode, setGetCompanyCode] = useState(false);
  const [codeRetryCount, setCodeRetryCount] = useState(0);
  const [formState, dispatchForm] = useReducer(formReducer, formInitialState);
  const [isLoading, setIsLoading] = useState(false);

  const getCompanyDataByEmailDomain = async (email: string) => {
    if (email) {
      const domain = email.split('@')[1];
      const companyDetails = await getCompanyDataByDomain(domain);

      return companyDetails;
    }

    return null;
  };

  const getCompanyDataByCompanyCode = async (companyCode: string) => {
    if (companyCode) {
      const companyDetails = await getCompanyDetailsByCompanyCode(companyCode);

      return companyDetails;
    }

    return null;
  };

  const handleCreateUser = async (emailValue: string, isAgreeValue: boolean) => {
    dispatchForm({
      type: DispatchTypes.REGISTER_INPUT,
      values: {
        email: emailValue,
        isAgree: isAgreeValue,
      },
    });

    if (organizationId) {
      createUser(emailValue, isAgreeValue, organizationId);
    } else {
      const companyData = await getCompanyDataByEmailDomain(emailValue);

      if (companyData) {
        companyURL = companyData.company_url;
        organizationId = companyData.id;
        createUser(emailValue, isAgreeValue, organizationId);
      } else {
        setGetCompanyCode(true);
      }
    }
  };

  const handleValidateCompanyCodeCreateUser = async (companyCodeValue: string) => {
    const companyData = await getCompanyDataByCompanyCode(companyCodeValue);

    if (companyData) {
      companyURL = companyData.company_url;
      organizationId = companyData.id;
      if (formState.email && formState.isAgree && organizationId) {
        createUser(formState.email, formState.isAgree, organizationId, companyCodeValue);
      } else {
        dispatchForm({
          type: DispatchTypes.REGISTER_INPUT,
          values: {
            email: '',
            isAgree: false,
          },
        });
        setGetCompanyCode(false);
        toast('Please enter a valid email, company code and agree with the terms & conditions');
      }
    } else {
      if (codeRetryCount < 3) {
        toast('No organisation is available for this company code');
      } else {
        toast(
          `Sorry, the email you entered (${formState.email}) cannot be used to sign up for ${companyCodeValue}.`,
        );
        dispatchForm({
          type: DispatchTypes.REGISTER_INPUT,
          values: {
            email: '',
            isAgree: false,
          },
        });
        setGetCompanyCode(false);
      }

      setCodeRetryCount((preState) => preState + 1);
    }
  };

  const createUser = async (
    email: string,
    isAgree: boolean,
    companyId: number,
    companyCode?: string,
  ) => {
    setIsLoading(true);
    let type = LoginTypes.LINK;
    if (isMobileApp) {
      type = LoginTypes.TOKEN;
    }

    if (email && isAgree && type && companyId) {
      const response = await registerUser({
        email,
        isAgree,
        type,
        companyId,
      });

      if (response.ok) {
        setIsLoading(false);
        history.push(
          `/login/check-your-inbox/${email}${companyURL ? `/${companyURL}` : ''}${
            isMobileApp ? '/?isMobileApp=true' : ''
          }`,
          {
            from: 'signUp',
          },
        );
        dispatchForm({
          type: DispatchTypes.REGISTER_INPUT,
          values: {
            email: '',
            isAgree: false,
          },
        });
      } else if (response.status === 409) {
        setIsLoading(false);
        dispatchForm({
          type: DispatchTypes.REGISTER_INPUT,
          values: {
            email: '',
            isAgree: false,
          },
        });
        toast('Email address is already registered');
        setGetCompanyCode(false);
      } else if (response.status === 403) {
        setIsLoading(false);
        toast(
          `Sorry, the email you entered (${email}) cannot be used to sign up for ${
            companyCode !== undefined ? companyCode : 'this company'
          }.`,
        );
        if (codeRetryCount > 3) {
          setIsLoading(false);
          dispatchForm({
            type: DispatchTypes.REGISTER_INPUT,
            values: {
              email: '',
              isAgree: false,
            },
          });
          setGetCompanyCode(false);
        }

        setCodeRetryCount((preState) => preState + 1);
      } else {
        setIsLoading(false);
        toast('Add user unsuccessful. Please contact admin team');
      }
    } else {
      setIsLoading(false);
      toast('Please enter a valid email, company code and agree with the terms & conditions');
    }
  };

  return (
    <>
      {getCompanyCode
        ? 'Enter your unique code that is provided to you by your organisation so we can connect you. If you can’t find your unique organisation code, check with your organisation or let us know the name of your organisation by emailing hello@hapstar.app so we can help.'
        : content}
      {getCompanyCode ? (
        <>
          <GetCompanyCodeForm onClick={handleValidateCompanyCodeCreateUser} />
          <div className={styles.linkHolder}>
            <p>
              <LinkText onClick={() => setGetCompanyCode(false)}>Back</LinkText>
            </p>
          </div>
        </>
      ) : (
        <>
          <RegistrationForm onClick={handleCreateUser} isUserCreating={isLoading} />
          <div className={styles.linkHolder}>
            <p>
              Already got an account?{' '}
              <LinkText
                path={`/login${companyURL ? `/${companyURL}` : ''}${
                  isMobileApp ? '?isMobileApp=true' : ''
                }`}
              >
                Login here
              </LinkText>
            </p>
          </div>
        </>
      )}
    </>
  );
};

SignUp.displayName = 'SignUp';

SignUp.defaultProps = {
  organizationId: undefined,
  companyURL: undefined,
};

interface SignUpProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  content: JSX.Element | string | undefined;
  organizationId?: number;
  companyURL?: string;
}

export default SignUp;
